<!--
 * @Author: 白一桐
 * @Date: 2024-07-04 10:23:38
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-08-02 15:06:48
-->
<template>
  <div id="projectbox" >
    <div  v-if="dataArr.length != 0" id="page">
      <div>
        <div style="text-align: center;width: 210mm;height: 50px;border: 1px solid silver;line-height: 50px;">检测项目情况汇总表</div> 
      </div>  
      <el-table3 border :data="dataArr" :header-cell-style="{ 'text-align': 'center'}" :cell-style="{ 'text-align': 'center'}" class="custom-row-height" :span-method="objectSpanMethod">
       
        <el-table-column prop="siteNumber" label="编号" className="my-cell" width="55">
        </el-table-column>
        <el-table-column prop="siteName" label="建筑物" className="my-cell" width="155">
        </el-table-column>
        <el-table-column prop="testitem" label="检测项目" className="my-cell" width="155">
        </el-table-column>
        <el-table-column prop="verdict" label="检测结论" className="my-cell" width="430">
        </el-table-column> 
        <el-table-column label="批注">
          <template slot-scope="scope">
              <el-input  v-model="scope.row.mark" @blur="inputContent(scope.row)"
                  @focus="focusInputContent(scope.sort, scope.row)">
              </el-input>
          </template>
        </el-table-column>  
      </el-table3>
    </div>
    <div id="print" style="page-break-before: always;">
      <div>
        <div style="text-align: center;width: 209.1mm;height: 50px;border: 1px solid black;line-height: 50px;border-bottom: none;">检测项目情况汇总表</div> 
      </div>  
      <el-table3  :data="dataArr" :header-cell-style="{ 'text-align': 'center' }"  :span-method="objectSpanMethod" style="width: 210mm;">
        <el-table-column prop="siteNumber" label="编号" className="my-cell" width="55">
        </el-table-column>
        <el-table-column prop="siteName" label="建筑物"  width="155">
        </el-table-column>
        <el-table-column prop="testitem" label="检测项目"  width="155">
        </el-table-column>
        <el-table-column prop="verdict" label="检测结论"  width="427.8">
        </el-table-column>  
      </el-table3>
    </div>
  </div>  
</template>

<script>
export default {
  name:'projectSummary',
  props: ['reportingData', "examinecontents"],
  data(){
    return{
      projectData:'',
      dataArr:[],
      type:[
        '接闪器','引下线','接地装置','磁屏蔽','防雷等电位连接','电涌保护器(SPD)'
      ],
      spanArr: [],
      pos: null,
      focusData:'',
    }
  },
  mounted(){ 
    this.editdata()
  },
  created(){
    this.$bus.$on('projectSummaryContents', this.projectSummaryContents)
  },
  methods:{
    getprojectpage() {
      this.projectData = document.getElementById("projectbox").innerHTML
    },
    editdata(){   
      if(this.reportingData.length!=0){ 
        let siteList = this.reportingData.siteList
        for(let s in siteList){
          for(let i in this.type){
            let obj = {} 
            obj.index = s
            obj.siteNumber = siteList[s].siteNumber
            obj.siteName = siteList[s].siteName
            obj.testitem = this.type[i]
            obj.verdict = siteList[s].verdict
            this.dataArr.push(obj)
          }
        }
        this.getSpanArr(this.dataArr)  

      }
    },
     //单元格合并
    getSpanArr(data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
        } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i].sort === data[i - 1].sort && data[i].siteName === data[i - 1].siteName) {
                this.spanArr[this.pos] += 1
                this.spanArr.push(0)
            } else {
                this.spanArr.push(1)
                this.pos = i
            }
        }
          // console.log(this.spanArr)
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 3|| columnIndex === 4) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        // console.log(`rowspan:${_row} colspan:${_col}`)
        return {
            // [0,0] 表示这一行不显示,被合并了  [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col
        }
       }
    },
    focusInputContent(index, row) {
      this.focusData = row.mark
    },
    //获取表格批注信息
    inputContent(row) {
      // console.log(row,row.index);
      let index = row.index
      let inputContent = row.mark
      if (this.focusData != inputContent) { 
          this.$emit('change', `siteListTotal.` + index, inputContent) 
          this.$emit('auditChange', row.siteName +  '：', inputContent)
      }
    },
    projectSummaryContents(examinecontents) { 
      // console.log('examinecontents')
      for (let i in examinecontents) {
        if(i.indexOf("Total") != -1){ 
          var items = i.split(".") 
          const keys = Object.keys(this.dataArr);
          keys.forEach(key => { 
            if(items[1] == this.dataArr[key].index){  
              this.$set(this.dataArr[key], 'mark', examinecontents[i]) 
            }
          }) 
        } 
      } 
    },
  }
}
</script>

<style scoped>
@media print {
  #marks,#page{
    display: none;
  } 
  .el-table {
    border-bottom: 0.5px solid black !important; 
  }

  .el-table>>>.my-cell {
      text-align: center;
      border-left: 0.5px solid rgb(32, 32, 32) !important;
  }

  .el-table>>>.el-table__cell {
      border-right: 0.5px solid rgb(32, 32, 32) !important;
      border-bottom: 0.5px solid rgb(32, 32, 32) !important;
  }

  ::v-deep .el-table th {
      border-top: 1px solid rgb(32, 32, 32) !important;
  }
/* 
  .custom-row-height .el-table__row {
    height: 10px !important;  
  }  */
}
@media screen {
  #print{
    display: none;
  }  
  #marks{
    width: 70mm;
    height: 297mm;
    box-sizing:content-box; 
  }
  .inputs{
    padding: 5px;
    height: 55px; 
  }   
  .inputs1{
    padding: 5px;
    height: 190px; 
  }  
}

#homebox {
  margin: 0;
  padding: 0;
  /* min-height: 40px; */
  width: 100%;
  border-bottom: 1px solid rgb(76, 76, 76);
  display: inline-block;
  box-sizing:border-box;
} 


</style>
