<!--
 * @Author: 白一桐
 * @Date: 2023-02-24 15:22:58
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-08-02 17:41:15
-->
<template>
  <div>
    <div id="printcoverbody">
      <div id="printborder" ref="table1" style=" width: 210mm;height: 297mm;margin: 5mm;page-break-after: always;">
        <!-- 二维码部分 -->
        <div id="ewm" style="width: 200mm;height: 30mm;padding-bottom: 50px;padding-top: 50px; ">
          <div class="ewmImg">
            <slot name="ircodede"></slot>
          </div>
        </div>
        <!-- 内容部分 -->
        <div id="cont">
          <div style="margin: 0 auto;padding-bottom: 60px;font-size: 55px;margin-left: 180px;">
            <h4>
              <slot name="title"></slot>
            </h4>
          </div>
          <!-- 检测报告编号 -->
          <div id="message" style="font-size: 22px;">
            <p>编号：{{ reportingData.trptNo }}</p>
          </div>
          <!-- 信息 -->
          <div id="message">
            <div id="detailedMsg">
              <div class="msgleft">委托单位：</div>
              <div class="msgright">
                {{ reportingData.client }}
              </div>
            </div><!-- 委托单位名称 -->
            <div id="detailedMsg">
              <div class="msgleft">受检单位：</div>
              <div class="msgright">
                {{ reportingData.insunit }}
              </div>
            </div><!-- 受检单位名称 -->
            <div id="detailedMsg">
              <div class="msgleft">检测场所：</div>
              <div class="msgright">
                {{ reportingData.sites }}
              </div>
            </div><!-- 检测场所名称 -->
            <div id="detailedMsg">
              <div class="msgleft">检测类别：</div>
              <div class="msgright">
                {{ reportingData.testType }}
              </div>
            </div><!-- 检测类别 -->
            <div id="detailedMsg">
              <div class="msgleft">所在地址：</div>
              <div class="msgright">
                {{ reportingData.sitesAddreess }}
              </div>
            </div><!-- 所在地址 -->
            <div id="detailedMsg">
              <div class="msgleft">检测日期：</div>
              <div class="msgright" v-if="reportingData.workStart != this.reportingData.workEnd">
                {{ reportingData.workStart }}{{ " ～ " + this.reportingData.workEnd }}
              </div>
              <div class="msgright" v-else>
                {{ reportingData.workStart }}
              </div>
            </div><!-- 检测日期 -->
          </div>
        </div>
        <div id="logo">
          <img src="../assets/logo.png" alt="logo" style="padding-top: 135px;padding-left: 330px;">
          <div style="width:600px;padding-top: 45px;font-size: 32px; padding-left: 180px;">
            <p>河北德创检测服务有限公司</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['reportingData'],

  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    getcover() {
      this.covers = document.getElementById("printcoverbody").innerHTML
    },
  }
}
</script>

<style scoped>
#printcoverbody {
  width: 210mm;
  height: 297mm;
}

#printborder {
  width: 180mm;
  height: 287mm;
  margin: 0 auto;
  padding-bottom: 150px;
  /* padding-bottom: 50px; */
  font-family: 'song';
}

#ewm {
  width: 630px;
  height: 150px;
}

.ewmImg {
  width: 150px;
  height: 150px;
  float: right;
}

#file {
  color: darkgrey;
}

#cont {
  width: 630px;
  height: 550px;
}

#logo {
  width: 630px;
  height: 230px;
}

#logo img {
  width: 100px;
  height: 100px;
  padding-left: 275px;
}

#logo h1 {
  margin-left: 10px;
}

#message {
  width: 550px;
  height: auto;
  font-size: 21.5px;
  /* border: 1px solid aquamarine; */
  padding-left: 180px;
  padding-bottom: 70px;
}

#detailedMsg {
  width: 500px;
  min-height: 40px;
  margin-bottom: 10px;
  /* border: 1px solid aquamarine; */
}

#detailedMsg .msgleft {
  width: 120px;
  float: left;
}

#detailedMsg .msgright {
  width: 360px;
  float: left;
}
</style>
