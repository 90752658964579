/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:37:27 
 * @Last Modified by:   王翎爆 
 * @Last Modified time: 2023-03-30 09:37:27 
 */
<template>
    <div>
        <div id="fimg">
            <div id="rimg">
                <el-image style="width: 80mm; height: 80mm"
                v-for="(src,index) in urlList"
                :key="index"
                :src="src"
                :preview-src-list="urlList">
                </el-image>
            </div>
            <div id="imgesdorse" contenteditable="true" ref="imgesdorse" @blur="addDefaultContent">
                {{ backesdorse }}
            </div>
        </div>
        <!-- <el-upload class="upload-demo" :action="target" :data="updata">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,且不超过500kb</div>
        </el-upload> -->
    </div>
</template>
<script>
export default {
    name: 'ReportImg',
    props:['photo','endorseMessage','auditMessage'],
    data() {
        return {
            url: '',
            surl: '',
            objname: '',
            target: '',
            updata: {
                AccessKeyId: '',
                signature: '',
                policy: '',
                key: '',
            },
            urlList: [],
            backesdorse: ''
        };
    },
    components: {
    },
    created(){
        this.$bus.$on('Imgchangeexaminecontents',this.changeexaminecontents)
    },
    mounted() {
        // console.log(this.photo.split('|'));
        // this.$axios({
        //     method: 'get',
        //     url: '/lps/hwcloud/obs/upurl',
        //     params: {
        //         filetype: 'jpg'
        //     }
        // }).then(res => {
        //     if (res.data != null) {
        //         // console.log('qurl:', res.data);
        //         this.updata.AccessKeyId = res.data.ak
        //         this.updata.signature = res.data.signature
        //         this.updata.policy = res.data.policy
        //         this.target = res.data.target
        //         this.updata.key = res.data.objpath + res.data.objname
        //         //console.log(this.updata.target);
        //     }
        // })
       this.initImagesSrc()
    //    console.log(this.auditMessage);
    },
    methods: {
        addDefaultContent(event) {
            let textareaContent = event.target.innerText.trim()
            if (textareaContent.length != 0) {
                console.log(textareaContent);
                this.$emit('change', this.endorseMessage+`.photo`,textareaContent)
                this.$emit('auditChange', this.auditMessage + `-现场图片：`,textareaContent)
            }
        },
        initImagesSrc(){
            let imgsrcs = this.photo.split('|')
            for(let i in imgsrcs){
                if(imgsrcs[i] != null && imgsrcs[i] != ''){
                this.$axios({
                    method: 'get',
                    url: '/lps/hwcloud/obs/downurl',
                    params: {
                        objname: imgsrcs[i]
                    }
                }).then(res => {
                    if (res.status == 200) {
                        // console.log("url:", res.data);
                        this.urlList.push(res.data.url)
                    }
                })
                }
            }
        },
        //批注信息回显
        changeexaminecontents(examinecontents) {
                let endorses = this.endorseMessage.match(/\d+(\.\d+)?/g)
                for(let item in examinecontents) {
                    let items = item.match(/\d+(\.\d+)?/g)
                if(item.includes('photo')){
                    if(endorses[0] == items[0]){
                        this.backesdorse = examinecontents[item]
                        // console.log('场所数据：',this.backesdorse);
                    }
                }
            }
        },
    },
};
</script>
<style  scoped>
#fimg {
    width: 100%;
    min-height: 106px;
    position: relative;
}

#rimg {
    border: 1px solid gainsboro;
    width: 78.5%;
    display: inline-block;
    text-align: center;
    padding-left: 15px;
}
.el-image {
    margin-left: 10px;
}
#imgesdorse {
    border: 1px solid gainsboro;
    width: 19.5%;
    height: 307px;
    display: inline-block;
    position: absolute;
    padding-left: 2px;
    /* box-sizing: border-box; */
}
</style>