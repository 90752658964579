<template>
    <div>
        <div>
            <div style="width: 190mm;height: 50px;margin: 0 auto;font-family:'song';">
                <div style="width:190mm;height: 20px;padding-bottom: 3px;">
                    <div style="float: left;width: 1px;padding-left: 60px;margin-right: 30px;height: 20px;">
                        <img src="../../assets/logo.png" alt="" width="20px">
                    </div>
                    <div style="text-align: center;float: left;font-size: 22px;">
                        <p>河北德创检测服务有限公司防雷防静电装置检测报告</p>
                    </div>
                </div>
                <div>
                    <div style="width: 380px;float:left ;padding-left:20px ; margin-top: 5px;">
                        编号：德雷检字[2022]第(311H99999)号
                    </div>
                    <div style="width: 180px;float: right;margin-top: 5px;">第 1 页 共 5 页</div>
                </div>
            </div>
            <div v-for="(item, index) in siteList">
                <div id="fthree" style="page-break-before: always;">
                    <div id="rthree">
                        <div style="margin: 5px 0;">
                            <h2>{{ item.siteName }}</h2>
                        </div>
                        <div id="rdetails">
                            <div class="xscenter">
                                <p>防雷类别：{{ item.protectionType }}</p>
                            </div>
                            <div class="xscenter">
                                <p>判定依据：{{ item.according }}</p>
                            </div>
                            <div class="xscenter" v-if="item.buildingLength">
                                <p>建筑物年预计雷击次数：</p>
                                <el-table :data="lightningData" border
                                    style="width:190mm; height: 26mm; margin-bottom: 5px; color: black;">
                                    <el-table-column label="长度(m)" prop="buildingLength" width="100mm"></el-table-column>
                                    <el-table-column label="宽度(m)" prop="buildingWidth" width="100mm"></el-table-column>
                                    <el-table-column label="高度(m)" prop="buildingHeight" width="110mm"></el-table-column>
                                    <el-table-column label="校正系数K" prop="coefficient" width="120mm"></el-table-column>
                                    <el-table-column label="年雷暴日(Td)" prop="thunderstormDaysPerYear"
                                        width="120mm"></el-table-column>
                                </el-table>
                            </div>
                            <div class="xscenter" v-if="item.dxt">
                                <p>电源系统：{{ item.dxt }}</p>
                            </div>
                            <div class="xscenter" v-if="item.xl">
                                <p>信号线路：{{ item.xl }}</p>
                            </div>
                            <div class="xscenter" v-if="item.xs">
                                <p>接地形式：{{ item.xs }}</p>
                            </div>
                            <div class="xscenter" v-if="item.sx">
                                <p>检测顺序：{{ item.sx }}</p>
                            </div>
                            <div class="xscenter" v-if="item.sm">
                                <p>基准点说明：{{ item.sm }}</p>
                            </div>
                            <div class="xscenter" v-if="item.qsm">
                                <p>其他说明：{{ item.qsm }}</p>
                            </div>
                            <div class="xscenter" v-if="downleadMeasure">
                                <p>引下线防接触电压措施：{{ downleadMeasure }}</p>
                            </div>
                            <div class="xscenter" v-if="downConductor">
                                <p>接地装置防跨步电压措施: {{ downConductor }}</p>
                            </div>
                            <div class="xscenter" v-if="downPressure">
                                <p>防侧击雷装置: {{ downPressure }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'paperModule',
    props: ['siteList'],
    data() {
        return {
            downleadMeasure: '',
            downConductor: '',
            downPressure: '',
            backesdorse: '',
            changeEqual: '',
            lightning: {},
            lightningData: []
        };
    },
    components: {
    },
    mounted() {
        // console.log(11111);
        // console.log('纸组件',this.siteList);
        this.changeLightning()
    },
    methods: {
        //修约(四舍六入五成双)
        evenRound(num, decimalPlaces) {
            var d = decimalPlaces || 0;
            var m = Math.pow(10, d);
            var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
            var i = Math.floor(n), f = n - i;
            var e = 1e-8; // Allow for rounding errors in f
            var r = (f > 0.5 - e && f < 0.5 + e) ?
                ((i % 2 == 0) ? i : i + 1) : Math.round(n);
            var result = d ? r / m : r;
            if (decimalPlaces > 0) {
                var s_x = result.toString();
                var pos_decimal = s_x.indexOf(".");
                if (pos_decimal < 0) {
                    pos_decimal = s_x.length;
                    s_x += ".";
                }
                while (s_x.length <= pos_decimal + decimalPlaces) {
                    s_x += "0";
                }
                return s_x;
            } else {
                return result;
            }
        },
        //回调函数得到修约后数据(30m起均压环（水平接闪带）间距)
        changepressureEqualizingRingSpacing(data) {
            this.changeEqual = data
        },
        //修约场所中雷击次数数据
        changeLightning() {
            for (let item in this.siteList) {
                console.log(item);
                if (this.siteList[item].buildingLength) {
                    this.lightning.buildingLength = this.evenRound(this.siteList[item].buildingLength, 1)
                }
                if (this.siteList[item].buildingWidth) {
                    this.lightning.buildingWidth = this.evenRound(this.siteList[item].buildingWidth, 1)
                }
                if (this.siteList[item].buildingHeight) {
                    this.lightning.buildingHeight = this.evenRound(this.siteList[item].buildingHeight, 1)
                }
                if (this.siteList[item].coefficient) {
                    this.lightning.coefficient = this.siteList[item].coefficient
                }
                if (this.siteList[item].thunderstormDaysPerYear) {
                    this.lightning.thunderstormDaysPerYear = this.siteList[item].thunderstormDaysPerYear
                }
                if (this.siteList[item].thunderstormStrokePerYear) {
                    this.lightning.thunderstormStrokePerYear = this.evenRound(this.siteList[item].thunderstormStrokePerYear, 2)
                }
                this.lightningData.push(this.lightning)
            }



        },
        //引下线防接触电压措施项目数据展示
        downleadMeasureMeans() {
            for (let i = 0; i < this.SummaryName.areaList.length; i++) {
                for (let j = 0; j < this.SummaryName.areaList[i].sourceDataList.length; j++) {
                    var downlead = this.SummaryName.areaList[i].sourceDataList[j]
                    if (downlead.testProject == '引下线防接触电压措施') {
                        if (downlead.numberOfDownConductor) {
                            this.downleadMeasure = `自然引下线数量，${downlead.numberOfDownConductor}`
                        } else if (downlead.surfaceResistivity) {
                            this.downleadMeasure = `引下线3m内地表层电阻率，${downlead.surfaceResistivity}`
                        } else if (downlead.groundMaterial) {
                            this.downleadMeasure = `引下线3m内地表材质，${downlead.groundMaterial}`
                        } else if (downlead.protectiveMeasures) {
                            this.downleadMeasure = `外露引下线保护措施，${downlead.protectiveMeasures}`
                        } else {
                            this.downleadMeasure = `有无护栏、警示牌，${downlead.withOrWithoutWarning}`
                        }
                    }
                    if (downlead.testProject == '接地装置防跨步电压措施') {
                        if (downlead.numberOfDownConductor) {
                            this.downConductor = `自然引下线数量，${downlead.numberOfDownConductor}`
                        } else if (downlead.surfaceResistivity) {
                            this.downConductor = `引下线3m内地表层电阻率，${downlead.surfaceResistivity}`
                        } else if (downlead.groundMaterial) {
                            this.downConductor = `引下线3m内地表材质，${downlead.groundMaterial}`
                        } else if (downlead.pressureEqualizingMeasures) {
                            this.downConductor = `地面均衡电位措施，${downlead.pressureEqualizingMeasures}`
                        } else {
                            this.downConductor = `有无护栏、警示牌，${downlead.withOrWithoutWarning}`
                        }
                    }
                    if (downlead.testProject == '防侧击雷装置') {
                        if (downlead.pressureEqualizingRingSpacing) {
                            this.$emit('evenRound', downlead.pressureEqualizingRingSpacing, 1)
                            this.downPressure += `30m起均压环（水平接闪带）间距，${this.changeEqual}m。`
                        }
                        if (downlead.externalWallMetal) {
                            this.downPressure += `30m起外墙金属物与防雷装置连接，${downlead.externalWallMetal}。`
                        }
                        if (downlead.exteriorWallProtrusions) {
                            this.downPressure += `45m起突出外墙物体保护措施，${downlead.exteriorWallProtrusions}。`
                        }
                        if (downlead.verticalMetal) {
                            this.downPressure = `外墙内、外竖直敷设金属物顶端和底端与防雷装置等电位链接，${downlead.verticalMetal}。`
                        }
                        if (downlead.sideDefence60m) {
                            this.downPressure = `高于60m的建筑物，其部占比20%并超过60m的部位，各表面上的尖物、墙角、边缘、设备及显著突出的物体，${downlead.sideDefence60m}。`
                        }
                        if (downlead.sideLayout60m) {
                            this.downPressure = `高于60m的建筑物，其上部占比20%并超过60m的部位接闪器布置位置，${downlead.sideLayout60m}。`
                        }
                        if (downlead.sideMetal60m) {
                            this.downPressure = `高于60m的建筑物，其上部占比20%并超过60m的部位外部金属物作为接闪器，${downlead.sideMetal60m}。`
                        }
                        if (downlead.sideSkeleton60m) {
                            this.downPressure = `高于60m的建筑物，其上部占比20%并超过60m的部位混凝土内钢筋和建筑物金属框架作为接闪器，${downlead.sideSkeleton60m}。`
                        }
                        if (downlead.sideSkeleton60m) {
                            this.downPressure = `高于60m的建筑物，其上部占比20%并超过60m的部位混凝土内钢筋和建筑物金属框架作为接闪器，${downlead.sideSkeleton60m}。`
                        }
                    }
                }
            }
        }
    },
};
</script>
<style  scoped> #fthree {
     margin-top: 50px;
     height: auto;
     width: 210mm;
     margin: 0 5mm;
     font-family: 'song';
     /* page-break-after: always; */
 }

 #rthree {
     border: 1px solid rgb(32, 32, 32);
     width: 200mm;
     height: auto;
     display: inline-block;
 }

 #rdetails {
     padding-left: 30px;
 }

 .xscenter p {
     margin-top: 5px;
 }</style>