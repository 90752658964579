import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views-tt/login'
import Index from '../views-w/Index'
import Equipment from '../views-w/Equipment'
import ReportMain from '../views-w/ReportMain'
import reportrawData from '../views-tt/reportrawData'
import reportManagement from '../views-tt/reportManagement'  //报告管理
import organizationalStructure from '../views-tt/organizationalStructure' //组织架构
import coordination from '../views-tt/coordination'   //协作页面
import attachmentManagement from '../views-tt/attachmentManagement'
import text from '../views-tt/Text'
import reportPage from '../views-tt/reportPage'
import reportConfirm from '../views-tt/reportConfirm'
// import { component } from 'vue/types/umd'
import printTest from '../views-w/printTest'
import Process from '../views-w/Process'
import editRecord from '../views-tt/editRecord/index'
import auditList from '../views-w/auditList'
import ratifyList from '../views-w/ratifyList'
import preview from '../views-tt/preview/index'
import reportPageNew from "../views-tt/reportPageNew"
import reportConfirmNew from '../views-tt/reportConfirmNew/index'
import reportrawDataNew from '../views-tt/reportrawDataNew/index'

Vue.use(Router)

// 解决跳转重复路由报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}
const router = new Router({
    routes: [
        {
            path:'/',
            component:Login,
            meta:{
              title: '登录'
            }
        },
        {
          path:'/index',
          component:Index,
          meta:{
            title: '首页'
          }
        },
        {
          path:'/equipment',
          component:Equipment,
          meta:{
            title: '设备管理'
          }
        },
        {
          path:'/reportmain',
          component:ReportMain
        },
        {
          path:'/reportManagement',
          component:reportManagement,
          meta:{
            title: '检测报告管理'
          }
        },
        {
          path:'/organizationalStructure',
          component:organizationalStructure,
          meta:{
            title: '组织架构管理'
          }
        },
        {
          path:'/attachmentManagement',
          component:attachmentManagement,
          meta:{
            title: '附件管理'
          }
        },
        {
          path:'/preview',
          component:preview,
          meta:{
            title: '预览'
          }
        },
        {
          path:'/reportrawData',
          component:reportrawData,
          meta:{
            title: '原始记录'
          }
        },
        {
          path:'/text',
          component:text
        },
        {
          path:'/reportPage',
          component:reportPage,
          meta:{
            title: '审核'
          }
        },
        {
          path:'/reportConfirm',
          component:reportConfirm,
          meta:{
            title: '批准'
          }
        },
        {
          path:'/editRecord',
          component:editRecord,
          meta:{
            title: '修改记录'
          }
        },
        {
          path:'/process',
          component:Process,
          meta:{
            title: '流程管理'
          }
        },
        {
          path:'/coordination',
          component:coordination,
          meta:{
            title: '协作'
          }
        },
        {
          path:'/printTest',
          component:printTest,
          meta:{
            title: '打印测试'
          }
        },
        {
          path:'/auditList',
          component:auditList,
          meta:{
            title: '审核表'
          }
        },
        {
          path:'/ratifyList',
          component:ratifyList,
          meta:{
            title: '批准表'
          }
        },
        {
          path:'/reportPageNew',
          component:reportPageNew,
          meta:{
            title: '新审核'
          }
        },
        {
          path:'/reportConfirmNew',
          component:reportConfirmNew,
          meta:{
            title: '2023版批准'
          }
        },
        {
          path:'/reportrawDataNew',
          component:reportrawDataNew,
          meta:{
            title: '2023版原始记录'
          }
        },
    ]
  })

  // 全局导航守卫
router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token') // 获取用户登录token
  if (token === null || token === 'undefined') { // 如果token不存在或者为空
    if (to.path === '/') {
      next()
    } else {
      next({path: '/'}) // 则跳转到登录页
      window.document.body.style.backgroundColor = "#055b89";
    }
  } else {
    if (to.path === '/') {
      next({path: '/index'})
    } else {
      next()
    }
  }
})
router.afterEach((to,from,next) =>{
  if(to.meta.title){
    document.title = to.meta.title
  }else{
    document.title = '创检平台'
  }
})
export default router
