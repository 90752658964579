<!--
 * @Descripttion: 
 * @version: 
 * @Author: 白一桐
 * @Date: 2023-01-12 10:24:54
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-04-25 15:43:30
-->
<template>
  <div id="printnote">
    <div id="printborder2" style="page-break-after: always;">
      <h2>注意事项</h2>
      <div>
        <p>1.&nbsp;&nbsp;根据《中华人民共和国气象法》、《防雷减灾管理办法》等有关法律法规规定，投入使用后的防雷装置实行定期检测制度。防雷装置应当每年检测一次，对爆炸和火灾危险环境场所的防雷装置应当每半年检测一次。
        </p>
        <p>2.&nbsp;&nbsp;本报告封面及检测结论处未加盖“河北德创检测服务有限公司检验检测专用章”无效,未加盖骑缝章无效。</p>
        <p>3.&nbsp;&nbsp;未经本检测机构书面授权，不得部分复制(全部复制除外)本报告。复制报告未重新加盖“河北德创检测服务有限公司检验检测专用章”和盖骑缝章无效。</p>
        <p>4.&nbsp;&nbsp;本报告无检测人、审核人、批准人签字无效。</p>
        <p>5.&nbsp;&nbsp;本报告涂改无效。</p>
        <p>6.&nbsp;&nbsp;本检验检测报告仅对本项目、本次抽检数据负责。</p>
        <p>7.&nbsp;&nbsp;请于本报告到期前一个月，向检测单位申请检测。</p>
        <p>8.&nbsp;&nbsp;如对本报告检测结果有异议,应在收到本报告15日内,向本检测机构书面提出。</p>
        <br>
        <br>
      </div>
      <div>
        <p>检测机构：&nbsp; 河北德创检测服务有限公司</p>
        <p>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：&nbsp; 河北省石家庄市鹿泉区鹿岛 V 谷科技工业园15号楼三层 </p>
        <p>电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：&nbsp; 4008-0311-93</p>
        <p>传真电话:&nbsp; &nbsp; 0311-87750099</p>
        <p>网&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：&nbsp; hbdcjc.com</p>
        <p>邮政编码:&nbsp;&nbsp;&nbsp; 050000</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statements: ''
    }
  },
  methods: {
    getstatement() {
      this.statements = document.getElementById("printnote").innerHTML
    }
  }
}
</script>

<style scoped>
#printnote {
  width: 210mm;
  height: 297mm;
}

#printborder2 {
  margin: 0 auto;
  width: 210mm;
  height: 297mm;
  padding-top: 70px;
  margin-left: 45px;
  margin-right: 45px;
  /* border: 1px solid saddlebrown; */

  /* padding-bottom: 175px; */
  font-family: 'song';
}

#printborder2 h2 {
  text-align: center;
  margin-bottom: 20px;
}

#printborder2 p {
  font-size: 21.5px;
  line-height: 2.1;
}
</style>
