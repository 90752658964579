<!--
 * @Author: 白一桐
 * @Date: 2023-09-14 10:43:29
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-10-09 10:06:58
 个人资质的空白页
-->
<template>
  <div>
    <div id="border" style="page-break-before: always;">
      <div id="padborder">
        <h2>
          <slot name="title"></slot>
        </h2>
        <div id="img">
          <personalPictures v-for="i in imgnames" :key="i" :peopleImg="i"></personalPictures>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import personalPictures from './personalPictures.vue'
export default {
  props: ['imgnames'],
  data() {
    return {
      arrurl: [],
      dialogImageUrl: ''
    }
  },
  components: {
    personalPictures
  },
  mounted() {
    // console.log(this.imgnames)
  },
  methods: {}
}
</script>

<style scoped>
@media print {
  #padborder {
    width: 210mm;
    height: 290mm;
    margin: 0 auto;
    padding: 20px;
  }

  #img {
    width: 200mm;
    height: 285mm;
    margin-top: 10px;
    padding: 10px;
    text-align: center;
    border: 1px solid rgb(89, 88, 88);
    box-sizing: border-box;
  }
}

@media screen {
  #border {
    width: 210mm;
    height: 330mm;
    margin-left: 25%;
    margin-top: 10px;
    border: 1px solid gainsboro;
    box-shadow: 1px 2px 13px grey;
    padding-top: 70px;
    box-sizing: border-box;
  }

  #padborder {
    width: 180mm;
    height: 290mm;
    margin: 0 auto;
    padding: 20px;
  }

  #img {
    width: 180mm;
    height: 280mm;
    margin-top: 10px;
    text-align: center;
    border: 1px solid rgb(89, 88, 88);
    box-sizing: border-box;
  }
}
</style>
