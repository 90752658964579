/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:39:00 
 * @Last Modified by: 王翎爆
 * @Last Modified time: 2023-04-14 17:29:44
 */
<template>
    <div>
        <el-dropdown @command="dropdown">
            <span class="el-dropdown-link">
                <el-avatar size="small" :src="src.profilePicture" style="position: relative;top:7px;"></el-avatar>
                欢迎登录，
                <span style="color: #ffd04b">{{ name }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu>
                <el-dropdown-item command="userSet">个人设置</el-dropdown-item>
                <el-dropdown-item divided command="updateCode">修改密码</el-dropdown-item>
                <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-dialog :visible.sync="editUserIframe" id="addUserIframe">
            <el-form :model="personalInformation" :rules="rules" ref="dialogForm">
                <div style="width: 100%;height: 30px;text-align: center;font-size: 16px;">修改个人信息</div>
                <div>
                    <label>姓名：</label>
                    <el-input placeholder="请输入显示名称" v-model="personalInformation.relname"
                        style="margin-top:10px;margin-bottom: 10px;"></el-input>
                </div>
                <div>
                    <label>用户名：</label>
                    <el-input placeholder="请输入用户名/账号" v-model="personalInformation.name" disabled
                        style="margin-top:10px;margin-bottom: 10px;"></el-input>
                </div>
                <div>
                    <label>电话：</label>
                    <el-input placeholder="请输入电话" v-model="personalInformation.phone"
                        style="margin-top:10px;margin-bottom: 10px;"></el-input>
                </div>
                <div>
                    <div>头像：</div>
                    <!-- <img :src="dialogImageUrl" width="100px" height="100px" @click="upImage()" id="show"> -->
                    <el-button @click="upImage()">上传头像</el-button>
                    <avatarCropper :dialogVisible.sync="dialogVisible" @closeAvatarDialog="closeAvatarDialog">
                    </avatarCropper>
                </div>
                <div>
                    <label>备注：</label>
                    <el-input placeholder="请输入备注" v-model="personalInformation.others"
                        style="margin-top:10px;margin-bottom: 10px;"></el-input>
                </div>
                <el-button id="submitAddUserbtn" type="primary" @click="submitEditUser">确认修改</el-button>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="updateCode" id="addUserIframe">
        <el-form :model="personalInformation" :rules="rules" ref="dialogForm">
                <div>
                    <el-form-item prop="oldpassword">
                        <label>旧密码：</label>
                        <el-input placeholder="请输入旧密码" v-model="personalInformation.oldpassword" show-password></el-input>
                        <!-- <i class="el-icon-check" v-if="changeFlag == 1" color="#35B449"></i>
                            <i class="el-icon-close" v-else-if="changeFlag == 2" color="#f00"></i> -->
                    </el-form-item>
                </div>
            <div>
                    <el-form-item prop="password">
                        <label>新密码：</label>
                        <el-input placeholder="密码格式为6-10位字母和数字组成" v-model="personalInformation.password"
                            show-password></el-input>
                        <!-- <i class="el-icon-check" v-if="changeFlag == 1" color="#35B449"></i>
                            <i class="el-icon-close" v-else-if="changeFlag == 2" color="#f00"></i> -->
                    </el-form-item>
            </div>
                <div>
                    <el-form-item prop="againpassword">
                        <label>确认新密码：</label>
                        <el-input placeholder="请确认密码" v-model="personalInformation.againpassword" show-password></el-input>
                        <!-- <icon type="ios-checkmark-circle" v-if="changeAgainFlag == 1" color="#35B449"/>
                            <icon type="md-close-circle" v-else-if="changeAgainFlag == 2" color="#f00"/> -->
                    </el-form-item>
                </div>
                <el-button id="submitAddUserbtn" type="primary" @click="submitUpdateCode">确认修改</el-button>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import avatarCropper from '@/components/avatarCropper'
export default {
    name: 'Dropdown',
    components: {
        avatarCropper
    },
    data() {
        // 密码验证
        const pwdCheck = async (rule, value, callback) => {
            console.log(value);
            let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/
            if (value == undefined || value == '') {

            } else if (value.length < 6) {
                this.changeFlag = 2;
                return callback(new Error('密码不能少于6位！'));
            } else if (value.length > 16) {
                this.changeFlag = 2;
                return callback(new Error('密码最长不能超过16位！'));
            } else if (!reg.test(value)) {
                this.changeFlag = 2;
                return callback(new Error('密码输入有误，请检查格式是否正确！'));
            } else {
                this.changeFlag = 1;
                callback()
            }
        }
        // 重复密码验证
        const pwdAgainCheck = async (rule, value, callback) => {
            console.log(value);
            if (value == '' && this.personalInformation.password != '') {
                this.changeAgainFlag = 2;
                return callback(new Error('重复密码不能为空！'));
            } else if (this.personalInformation.password != this.personalInformation.againpassword && this.personalInformation.password != '') {
                this.changeAgainFlag = 2;
                return callback(new Error('两次输入密码不一致！'));
            } else {
                this.changeAgainFlag = 1;
                callback()
            }
        };
        const pwdOldCheck = async (rule, value, callback) => {
            console.log(value);
            if (value !== this.$cookies.get('pwd')) {
                return callback(new Error('旧密码不正确'));
            }
        }
        return {
            id: '',
            name: '',
            src: {
                profilePicture: ''
            },
            editUserIframe: false,
            updateCode: false,
            personalInformation: {
                relname: '',
                name: '',
                password: '',
                againpassword: '',
                phone: '',
                others: '',
                oldpassword: '',
                oldpwdStatus: '',
                avatar: ''
            },
            changeFlag: 0,
            changeAgainFlag: 0,
            count: '',
            rules: {
                password: [{ required: true, validator: pwdCheck, trigger: 'blur' }],
                againpassword: [{ required: true, validator: pwdAgainCheck, trigger: 'blur' }],
                oldpassword: [{ required: true, validator: pwdOldCheck, trigger: 'blur' }]
            },
            imgIframe: false,
            key: '',
            fromData: {
                policy: '',
                ak: '',
                signature: ''
            },
            imgName: '',
            dialogImageUrl: '',
            dialogVisible: false,
            filebase64: '',
            picValue: ''
        };
    },
    mounted() {
        this.name = this.$cookies.get('user').relname
        this.id = this.$cookies.get('user').id
        this.avatarManage()
    },
    methods: {
        avatarManage() {
            if (this.$cookies.get('user').avatar == '') {
                this.src.profilePicture = require('@/assets/user1.png')
            } else {
                this.avatarInit()
            }
        },
        //         upload (e) {
        //             let files = e.target.files || e.dataTransfer.files;
        //             if (!files.length) return;
        //             this.picValue = files[0];
        //             this.imgPreview(this.picValue);
        //         },
        //         imgPreview (file) {
        //             let self = this;
        //             let Orientation;
        //             //去获取拍照时的信息，解决拍出来的照片旋转问题
        //             Exif.getData(file, function(){
        //                 Orientation = Exif.getTag(this, 'Orientation');
        //             });
        //             // 看支持不支持FileReader
        //             if (!file || !window.FileReader) return;

        //             if (/^image/.test(file.type)) {
        //                 // 创建一个reader
        //                 let reader = new FileReader();
        //                 // 将图片2将转成 base64 格式
        //                 reader.readAsDataURL(file);
        //                 // 读取成功后的回调
        //                 reader.onloadend = function () {
        //                     let result = this.result;
        //                     let img = new Image();
        //                     img.src = result;
        //                     //判断图片是否大于100K,是就直接上传，反之压缩图片
        //                     if (this.result.length <= (100 * 1024)) {
        //                     self.headerImage = this.result;
        //                     // self.postImg();
        //                     }else {
        //                     img.onload = function () {
        //                         let data = self.compress(img,Orientation);
        //                         self.headerImage = data;
        //                         // self.postImg();
        //                     }
        //                     }
        //                 } 
        //                 }
        //         },
        //         compress(img,Orientation) {
        //       let canvas = document.createElement("canvas");
        //       let ctx = canvas.getContext('2d');
        //         //瓦片canvas
        //       let tCanvas = document.createElement("canvas");
        //       let tctx = tCanvas.getContext("2d");
        //       let initSize = img.src.length;
        //       let width = img.width;
        //       let height = img.height;
        //       //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
        //       let ratio;
        //       if ((ratio = width * height / 4000000) > 1) {
        //         console.log("大于400万像素")
        //         ratio = Math.sqrt(ratio);
        //         width /= ratio;
        //         height /= ratio;
        //       } else {
        //         ratio = 1;
        //       }
        //       canvas.width = width;
        //       canvas.height = height;
        //   //        铺底色
        //       ctx.fillStyle = "#fff";
        //       ctx.fillRect(0, 0, canvas.width, canvas.height);
        //       //如果图片像素大于100万则使用瓦片绘制
        //       let count;
        //       if ((count = width * height / 1000000) > 1) {
        //         console.log("超过100W像素");
        //         count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
        //   //            计算每块瓦片的宽和高
        //         let nw = ~~(width / count);
        //         let nh = ~~(height / count);
        //         tCanvas.width = nw;
        //         tCanvas.height = nh;
        //         for (let i = 0; i < count; i++) {
        //           for (let j = 0; j < count; j++) {
        //             tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
        //             ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
        //           }
        //         }
        //       } else {
        //         ctx.drawImage(img, 0, 0, width, height);
        //       }
        //       //修复ios上传图片的时候 被旋转的问题
        //       if(Orientation != "" && Orientation != 1){
        //         switch(Orientation){
        //           case 6://需要顺时针（向左）90度旋转
        //               this.rotateImg(img,'left',canvas);
        //               break;
        //           case 8://需要逆时针（向右）90度旋转
        //               this.rotateImg(img,'right',canvas);
        //               break;
        //           case 3://需要180度旋转
        //               this.rotateImg(img,'right',canvas);//转两次
        //               this.rotateImg(img,'right',canvas);
        //               break;
        //         }
        //       }
        //       //进行最小压缩
        //       let ndata = canvas.toDataURL('image/jpeg', 0.1);
        //       console.log('压缩前：' + initSize);
        //       console.log('压缩后：' + ndata.length);
        //       console.log('压缩率：' + ~~(100 * (initSize - ndata.length) / initSize) + "%");
        //       tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
        //       return ndata;
        //     },
        closeAvatarDialog(data) {
            let result = this.base64toFile(data, 'ceshi')
            // this.imgIframe = true
            this.filebase64 = result
            const formData = new FormData();
            formData.append('policy', this.fromData.policy);
            formData.append('key', this.key);
            formData.append('x-obs-acl', 'public-read');
            formData.append('content-type', 'text/plain');
            formData.append('AccessKeyId', this.fromData.ak);
            formData.append('signature', this.fromData.signature);
            formData.append('file', this.filebase64);
            this.$axios.post('https://lps-hbdcjc-com.obs.cn-north-1.myhuaweicloud.com', formData, {

            }).then(
                (res) => {
                    let updateAvatar = this.$cookies.get('user')
                    updateAvatar.avatar = this.imgName
                    this.$cookies.set('user', updateAvatar)
                })
            // this.$axios({
            //     method: 'post',
            //     url: 'http://lps-hbdcjc-com.obs.cn-north-1.myhuaweicloud.com',
            //     data: formData,
            //     headers: {
            //        'Content-Type': 'multipart/form-data',
            //         }
            // }).then(res =>{
            //     console.log(成功啦);
            // })
        },
        base64toFile(dataurl, filename) {
            let arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {
                type: mime
            });
        },
        dropdown(command) {
            if (command == 'userSet') {
                this.editUserIframe = true
                this.userInit()
            } else if (command == 'logout') {
                this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '退出成功!'
                    });
                    sessionStorage.removeItem('token');
                    this.$cookies.remove('user')
                    this.$cookies.remove('pwd')
                    this.$router.push('/')
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消退出'
                    });
                });
            } else {
                this.updateCode = true
            }
        },
        userInit() {
            this.$axios({
                method: 'GET',
                url: '/lps/sys/user/' + this.id,
            }).then(res => {
                this.personalInformation = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        //获取图片url
        avatarInit() {
            this.$axios({
                method: 'get',
                url: '/lps/hwcloud/obs/downurl',
                params: {
                    objname: this.$cookies.get('user').avatar
                }
            }).then(res => {
                if (res.status == 200) {
                    this.src.profilePicture = res.data.url
                }
            })
        },
        //确认修改个人信息
        submitEditUser() {
            this.$axios({
                method: 'PUT',
                url: '/lps/sys/user/' + this.id,
                data: {
                    relname: this.personalInformation.relname,
                    phone: this.personalInformation.phone,
                    others: this.personalInformation.others,
                    avatar: this.imgName,
                }
            }).then(res => {
                if (res.data) {
                    this.avatarInit()
                    this.editUserIframe = false
                    this.$message({
                        type: 'success',
                        message: '个人信息修改成功啦'
                    });
                }
            })
        },
        //确认修改密码
        submitUpdateCode() {
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    this.$axios({
                        method: 'PUT',
                        url: '/lps/sys/user/' + this.id,
                        data: {
                            password: this.personalInformation.password,
                        }
                    }).then(res => {
                        if (res.data) {
                            this.updateCode = false
                            const TIME_COUNT = 3;
                            if (!this.timer) {
                                this.count = TIME_COUNT;
                                this.timer = setInterval(() => {
                                    if (this.count > 1 && this.count <= TIME_COUNT) {
                                        //限制倒计时区间
                                        this.count--;
                                        a.message = '修改密码成功！' + this.count + '秒后将退出登录'
                                    } else {
                                        clearInterval(this.timer); //删除定时器
                                        this.timer = null;
                                        //三秒后关闭（三秒后需要进行的操作）
                                        sessionStorage.removeItem('token');
                                        this.$cookies.remove('user')
                                        this.$router.push('/')
                                    }
                                }, 1000);
                            }
                            var a = this.$message({
                                type: 'success',
                                message: '修改密码成功！3秒后将退出登录'
                            });
                        }
                    })
                }
            })
        },
        upImage() {
            this.dialogVisible = true
            // this.imgIframe = true
            this.getjpgData()
        },
        //获取图片名称key等数据
        getjpgData() {
            this.$axios({
                method: 'GET',
                url: '/lps/hwcloud/obs/upurl?filetype=jpg',
            }).then(res => {
                this.fromData = res.data
                this.key = res.data.objpath + res.data.objname
                this.imgName = res.data.objname   //图片名称
            }).catch(error => {
                console.log(error.response.data)
            })
        },
        changepic() {

        },
        upImg() {
            this.imgIframe = false
        }
    },
};
</script>
<style  scoped>
.el-dropdown {
    position: absolute;
    left: 80%;
    margin-top: 10px;
}

.el-dropdown-link {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
}

.el-icon-arrow-down {
    font-size: 16px;
}
</style>