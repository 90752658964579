<!--
 * @Author: 白一桐
 * @Date: 2023-04-04 09:11:38
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-07-30 15:41:08
-->
<template>
  <div>
    <!-- -------------------------------------------t----------------------------------------------- -->
    <!-- 按钮行部分 -->
    <div id="btnList">
      <div class="left">
        <el-button @click="startExamine()" id="startBtn" :disabled=startBtn type="primary">开始审核</el-button>
        <el-button @click="endExamine()" :disabled=endBtn id="endBtn" type="primary">结束审核</el-button>
        <!-- <el-button @click="Staging()" type="primary">暂存</el-button> -->
        <el-tooltip class="item" effect="dark" content="暂不可用" placement="top-start">
          <el-button type="primary">暂存</el-button>
        </el-tooltip>
        <!-- <el-button type="primary" @click="dialogVisible = true">目录</el-button>
        <el-button type="primary" @click="dialogVisibleAttach = true">附件</el-button> -->
        <el-button type="primary" @click="goRawdata()">原始记录</el-button>
      </div>
      <div class="right">
        <div style="margin:20px;color:slategrey;" v-show="text">
          <h4>您已开始审核</h4>
        </div>
        <div style="margin:20px;color:green;" v-show="text1">
          <h4>审核已通过</h4>
        </div>
        <div style="margin:20px;color:saddlebrown;" v-show="text2">
          <h4>审核未通过，点击"开始审核"重新审核</h4>
        </div>
      </div>
    </div>
    <img src="../../assets/tops.png" @click="toTop" class="top" alt="" style="width: 64px;height: 64px;"
      v-show="imgshow">
    <div>
      <!-- 结束审核 -->
      <el-dialog :visible.sync="endFormVisible"
        style="width:1000px; margin-top:5%;margin-left: 20%;text-align: center;">
        <h3>请选择要退回或进行到下一步确认</h3>
        <div style="margin-top:30px">
          <el-button type="warning" @click="goback()">退回</el-button>
          <el-button style="margin-left:160px" type="primary" @click="confirm()">确认</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="backFormVisible"
        style="width:1000px; margin-top:5%;margin-left: 20%;text-align: center;">
        <h3>退回操作页面</h3>
        <div style="margin-top:30px">
          <el-input type="textarea" placeholder="请输入审核意见" v-model="gobackOpinion"></el-input>
        </div>
        <div style="margin-top:10px">
          <el-button type="primary" @click="gobackIfreame()">确认</el-button>
        </div>

      </el-dialog>
      <el-dialog :visible.sync="okFormVisible" style="width:1000px; margin-top:5%;margin-left: 20%;text-align: center;">
        <h3>确认操作页面</h3>
        <div style="margin-top:30px">
          <el-input type="textarea" placeholder="请输入审核意见" v-model="confirmOpinion"></el-input>
        </div>
        <div style="margin-top:10px">
          <el-button type="primary" @click="confirmIfreame()">确认</el-button>
        </div>
      </el-dialog>
      <!-- 获取图片部分开始 -->
      <!-- <el-button style="margin-left: 20px;" @click="upImage()">上传图片</el-button> -->
      <el-dialog :visible.sync="imgIframe" style="width:1000px; margin-top:5%;margin-left: 20%;">
        <el-upload style="margin-left: 40px;" drag action="" :on-change="upload" class="avatar-uploader"
          :show-file-list="false">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-button @click="upImg()" style="margin-left: 40px;"> 上传</el-button> 
      </el-dialog>
      <!-- 获取图片部分结束 -->
    </div>
    <!-- 报告内容部分 -->
    <div id="bodydy">
      <!-- 报告首页 -->
      <div id="catalog">
        <h3>目录结构</h3>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree class="filter-tree" :data="treedata" :props="defaultProps" node-key="id" default-expand-all
          :filter-node-method="filterNode" ref="tree" @node-click="testclick" :expand-on-click-node="false">
        </el-tree>
      </div>
      <reportcover :reportingData="reportingData">
        <template #title>
          <p style="text-align: center;">雷电防护装置检测报告</p>
        </template>
        <template #ircodede>
          <img :src="dialogImageUrl" width="100px" height="100px" @click="upImage()" id="show">
        </template>
      </reportcover>
      <statement></statement> 
      <reportBasicInformation :reportingData="reportingData" ref="reportBasicpage"
        @auditChange="auditChange"  @change="pageAnnotationChange">
        <template #standard>
          <div v-html="reportingData.standardbr"></div>
        </template>
      </reportBasicInformation>
        <div id="siteListdiv" v-if="reporIsno == true">
          <projectSummary :reportingData="reportingData" ref="projectSummarypage"
          @auditChange="auditChange"  @change="pageAnnotationChange"></projectSummary> 
        </div> 
    </div>
    <!-- --------------------------------------------w---------------------------------------------- -->
    <div id="main">
      <el-dialog title="目录结构" :visible.sync="dialogVisible" width="30%">
        <div>
          <div id="catatop">目录</div>
          <div v-for="(item, index) in siteList" id="catacenter" :key="index">
            <span class="span1">{{ index + 1 }}.&nbsp;&nbsp;&nbsp;{{ item.siteName }}</span>
            <span class="span2">......................{{ index + 1 }}</span>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="附件" :visible.sync="dialogVisibleAttach" width="30%">
        <div>
          <div v-for="(item, index) in attdata" id="attachcenter" :key="index">
            <span>{{ item.id }}:&nbsp;&nbsp;{{ item.name }}</span>
            <div>{<a>图片</a>}</div>
          </div>
          <div id="attachbottom">
            <p>以下空白</p>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleAttach = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleAttach = false">确 定</el-button>
        </span>
      </el-dialog>

      <div v-for="(sitem, bindex) in siteList" :key="bindex">
        <div id="siteListdiv">
          <buildingBasicInformation :reportingData="sitem" :endorseMessage="`siteList.` + bindex"  @auditChange="auditChange"
            :auditMessage="sitem.siteName" @change="pageAnnotationChange">
            </buildingBasicInformation>
        </div>
        <div id="total">
          <div id="center">
            <ReportSubrgionNew :id="sitem.siteName" :SubrgionName="sitem" :reportTemplate="reportTemplate"
              :endorseMessage="`siteList.` + bindex " ref="subrgion"
              @change="pageAnnotationChange" :auditMessage="sitem.siteName + `-`"
              @auditChange="auditChange">
            </ReportSubrgionNew>
            <ReportImg v-if="sitem.photo" :photo="sitem.photo"
              :endorseMessage="`siteList.` + bindex " @change="pageAnnotationChange"
              :auditMessage="sitem.siteName + `-` " @auditChange="auditChange">
            </ReportImg>
          </div>  
        </div>
      </div>
      <figures :imgname="itm" v-for="itm in reportPhoto" :key="itm">
        <template #title>
          附图:
        </template>
      </figures>
      <figures v-if="reportingData.license" :imgname="reportingData.license">
        <template #title>
          附件1：
        </template>
      </figures>
      <figures v-if="reportingData.qualification" :imgname="reportingData.qualification">
        <template #title>
          <!-- 附件1： -->
        </template>
      </figures>
      <personalBlankPage v-if="reportInspectorCredential != ''" :imgnames="reportInspectorCredential">
        <template #title>
          附件2：
        </template>
      </personalBlankPage>
      <figures :imgname="i" v-for="i in reportotherPhoto" :key="i">
        <template #title>
          附件3：
        </template>
      </figures>
    </div>

  </div>
</template>

<script>
import reportcover from '@/components/reportcover.vue'
import statement from '@/components/reportstatement.vue'
import reportBasicInformation from '@/components/tmp2023/reportBasicInformation.vue'
import buildingBasicInformation from '@/components/tmp2023/buildingBasicInformation.vue'
import projectSummary from '@/components/tmp2023/projectSummary.vue'

import figures from '@/components/figure.vue'
import personalBlankPage from '@/components/personalBlankPage.vue'

import ReportSummary from '@/views-w/ReportSummary'
import ReportSubrgionNew from '@/views-w/ReportSubrgionNew'
import ReportImg from '@/views-w/ReportImg'

import coordination from '@/views-tt/coordination'
export default {
  data() {
    return {
      imageUrl: '',

      //-----------------------------t-----------------
      reportingData: '',
      reportId: '', //报告Id
      statusId: '', //报告状态
      startBtn: false, //开始按钮
      endBtn: false,
      text: false,
      text1: false, //通过
      text2: false, //不通过
      endFormVisible: false, //结束审核页面
      backFormVisible: false, //退回页面
      okFormVisible: false, // 进行到确认页面
      gobackOpinion: '', //退回意见
      confirmOpinion: '', //确认意见
      ircode: '', //二维码
      dialogImageUrl: '', //二维码图片位置
      examinecontents: '初始值', //批注内容
      examinestatMeta: '', //退回得总意见
      imgIframe: false, //上传图片的弹出层
      fromData: [], //上传图片时的key等数据
      key: '', //Object key
      imgName: '', //图片名称objname
      verdicts: '', //检测结论
      reportPhoto: [], //附图
      reportotherPhoto: [], //其他附图
      reportInspectorCredential: [], //检测员资质第一部分
      //-----------------------------w-----------------

      dialogVisible: false,
      dialogVisibleAttach: false,
      siteList: [],
      catalogueList: [],
      treedata: [],
      filterText: '',
      defaultProps: {
        label: 'label',
        children: 'children'
      },
      attdata: [
        {
          id: '附件一',
          name: '防雷装置检测甲级资质(扫描件)'
        },
        {
          id: '附件二',
          name: '公司营业执照(扫描件)'
        },
        {
          id: '附件三',
          name: '个人健康说明'
        }
      ],
      count: '',
      lastvalue: '',
      lastkey: '',
      auditlastvalue: '',
      auditlastkey: '',
      commentData: {},
      imgshow: false,
      examineMsgs: [],
      reportTemplate: '', //报告版本
      reporIsno:false,

    }
  },
  components: {
    reportcover,
    statement,
    reportBasicInformation,
    buildingBasicInformation,
    projectSummary,
    
    figures,
    personalBlankPage,
    ReportSummary,
    ReportSubrgionNew,
    ReportImg,

    coordination
  },
  mounted() {
    // window.name = 'coorupload1'

    this.getData()
    // this.getExamineData()
    this.reportId = this.$route.query.id
    //报告版本号
    this.reportTemplate = this.$route.query.type
    //----w----
    this.ReportTest()
    window.addEventListener('scroll', this.imgshowListener) //事件监听
    // coordination.methods.requestData1()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    // ----------------------------------t-----------------------------------------
    //#region
    //回到顶部按钮是否展示
    imgshowListener() {
      let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (top > 200) {
        this.imgshow = true
      } else {
        this.imgshow = false
      }
    },
    //回到顶部
    toTop() {
      document.documentElement.scrollTop = 0
    },
    // 获取报告的状态
    getStatus() {
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/' + this.$route.query.id
      })
        .then(res => {
          // console.log(res.data)
          this.examinestatMeta = res.data.examinemeta
          if (res.data.examinestat == 11) {
            this.startBtn = true
            this.text = true
          } else if (res.data.examinestat == 13) {
            this.startBtn = true
            this.text2 = true
            this.endBtn = true
          } else if (res.data.examinestat == 10) {
            this.endBtn = true
          } else {
            this.startBtn = false
          }
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    // 获取整体的数据
    getData() {
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/content/' + this.$route.query.id
      })
        .then(res => {
          this.reportingData = res.data
          // console.log(res.data)
          this.reporIsno = true
          document.title = '审核 - ' + res.data.sites
          if (res.data.photo) {
            let photos = res.data.photo
            photos = photos.substring(0, photos.lastIndexOf('|'))
            this.reportPhoto = photos.split('|')
            // console.log(this.reportPhoto);
          }

          if (res.data.otherPhoto) {
            let otherPhoto = res.data.otherPhoto
            otherPhoto = otherPhoto.substring(0, otherPhoto.lastIndexOf('|'))
            this.reportotherPhoto = otherPhoto.split('|')
          }

          if (res.data.inspectorCredential) {
            let inspectorCredential = res.data.inspectorCredential
            inspectorCredential = inspectorCredential.substring(0, inspectorCredential.lastIndexOf('|'))
            let arr = inspectorCredential.split('|') 
            this.reportInspectorCredential = arr
          }

          if (res.data.verdict) {
            this.vstr = res.data.verdict
            this.verdicts = this.vstr.replace('page', '3')
          }
          if (res.data.standard) {
            let str = res.data.standard
            this.reportingData.standardbr = str.replace(/\n/g, '<br/>') //检测报告规范 
          }
          if (res.data.equipment) {
            //设备
            // console.log(this.reportingData.equipment)
            let equip = this.reportingData.equipment.replaceAll('设备名称：', '')
            let equip1 = equip.replaceAll('型号：', '')
            let equip2 = equip1.replaceAll('设备编号：', '')
            let equip3 = equip2.replaceAll('出厂编号：', '')
            let strequip = equip3.split('|')
            // console.log(strequip)
            let equipmentArr = []
            for (let i = 0; i < strequip.length; i++) {
              let data = {}
              // console.log(strequip[i].split("\n")[1])
              data.eqName = strequip[i].split('\n')[1]
              data.eqModel = strequip[i].split('\n')[2]
              data.eqNo = strequip[i].split('\n')[3]
              equipmentArr.push(data)
            }
            // console.log(equipmentArr)
            this.reportingData.equipmentArr = equipmentArr
          }
          if (res.data.weather == '请选择') {
            this.reportingData.weather = ''
          }
          if (res.data.soil == '请选择') {
            this.reportingData.soil = ''
          }
          this.ircode = res.data.ircode
          if (res.data.ircode) {
            this.imgimg()
          } else {
            this.ircode = '653c8efee531be8de53a01899a5424cb.jpg'
            this.imgimg()
          }
          this.getStatus()
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    //获取批注
    getExamineData() {
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/examine/' + this.$route.query.id
      })
        .then(res => {
          if (res.data.examinecontent != '' && res.data.examinecontent != undefined) {
            let examinecontent = JSON.parse(res.data.examinecontent)
            this.examinecontents = examinecontent
            console.log('批注', this.examinecontents);
            this.commentData = examinecontent //防止刷新页面后commentData为空，将空批注保存到数据库
            this.$bus.$emit('Subrgionchangeexaminecontents', examinecontent)
            this.$bus.$emit('reportBasicContents', examinecontent)
            this.$bus.$emit('projectSummaryContents', examinecontent)
            this.$bus.$emit('buildingBasicContents', examinecontent)

            this.$bus.$emit('Summarychangeexaminecontents', examinecontent)
            this.$bus.$emit('Imgchangeexaminecontents', examinecontent)
            // console.log('父组件examinecontents', this.examinecontents)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    CloseWebPage() {
      //关闭页面
      window.close()
      const bc = new BroadcastChannel('reportReload')
      bc.postMessage('This is a reload.')
    },
    goRawdata() {
      let routeData = this.$router.resolve({
        path: '/reportrawDataNew',
        // path: "/Text",
        query: {
          id: this.reportId
        }
      })
      //必要操作，否则不会打开新页面
      window.open(routeData.href, '_blank')
    },
    //开始审核按钮
    startExamine() {
      console.log('开始审核')
      this.$axios({
        method: 'PUT',
        url: '/lps/test/testreport/' + this.reportId,
        data: {
          examinestat: '11'
        }
      })
        .then(res => {
          console.log(res.data)
          this.text2 = false
          this.text = true
          this.startBtn = true
          this.endBtn = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    //结束审核按钮
    endExamine() {
      this.endFormVisible = true
    },
    //暂存
    Staging() {
      let commentsData = JSON.stringify(this.commentData)  
      if (this.examinecontents == '初始值') {
        console.log('第一次保存')
        this.$axios({
          method: 'POST',
          url: '/lps/test/testreport/examine',
          data: {
            trptid: this.reportId,
            trptcontentid: this.reportId,
            examinecontent: commentsData
          }
        })
          .then(res => {
            // console.log(res.data)
            this.getExamineData()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$axios({
          method: 'PUT',
          url: '/lps/test/testreport/examine/' + this.$route.query.id,
          data: {
            trptid: this.reportId,
            trptcontentid: this.reportId,
            examinecontent: commentsData
          }
        })
          .then(res => {
            // console.log(res.data)
            this.getExamineData()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    //退回
    goback() {
      this.backFormVisible = true
    },
    //退回
    gobackIfreame() {
      console.log('退回')
      let commentsData = JSON.stringify(this.commentData)
      let examineMsgs = JSON.stringify(this.examineMsgs)
      if (this.gobackOpinion == '') {
        this.gobackOpinion = '退回'
      }
      if (this.examinecontents == '初始值') {
        console.log('第一次提交')
        this.$axios({
          method: 'POST',
          url: '/lps/test/testreport/examine',
          data: {
            trptid: this.reportId,
            trptcontentid: this.reportId,
            examinestat: '13',
            examinemeta: this.gobackOpinion, //退回意见
            examinecontent: commentsData, //批注
            examinetext: examineMsgs //审核记录
          }
        })
          .then(res => {
            console.log(res.data)
            this.text = false
            this.startBtn = false
            this.text2 = true
            this.backFormVisible = false
            this.endFormVisible = false
            this.CloseWebPage()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        console.log('第n次提交')
        this.$axios({
          method: 'PUT',
          url: '/lps/test/testreport/examine/' + this.$route.query.id,
          data: {
            trptid: this.reportId,
            trptcontentid: this.reportId,
            examinestat: '13',
            examinemeta: this.gobackOpinion,
            examinecontent: commentsData,
            examinetext: examineMsgs //审核记录
          }
        })
          .then(res => {
            console.log(res.data)
            this.text = false
            this.startBtn = false
            this.text2 = true
            this.backFormVisible = false
            this.endFormVisible = false
            this.CloseWebPage()
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    //confirm 确认
    confirm() {
      this.okFormVisible = true
    },
    // 确认确认
    confirmIfreame() {
      console.log('确认确认')
      let commentsData = JSON.stringify(this.commentData)
      // console.log('commentsData',commentsData) 
      let examineMsgs = JSON.stringify(this.examineMsgs)
      // console.log('examineMsgs',examineMsgs)
      if (this.confirmOpinion == '') {
        this.confirmOpinion = '审核通过'
      }
      if (this.examinecontents == '初始值') {
        this.$axios({
          method: 'POST',
          url: '/lps/test/testreport/examine',
          data: {
            trptid: this.reportId,
            trptcontentid: this.reportId,
            examinestat: '12',
            examinemeta: this.confirmOpinion, //总体意见
            examinecontent: commentsData, //批注
            examinetext: examineMsgs //审核记录
          }
        })
          .then(res => {
            console.log(res.data)
            this.text = false
            this.startBtn = true
            this.text1 = true
            this.okFormVisible = false
            this.endFormVisible = false
            this.$axios({
              method: 'PUT',
              url: '/lps/test/testreport/' + this.reportId,
              data: {
                confirmstat: '20'
              }
            })
              .then(res => {
                console.log(res.data)
                this.CloseWebPage()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$axios({
          method: 'PUT',
          url: '/lps/test/testreport/examine/' + this.reportId,
          data: {
            trptid: this.reportId,
            trptcontentid: this.reportId,
            examinestat: '12',
            examinemeta: this.confirmOpinion, //总体意见
            examinecontent: commentsData, //批注
            examinetext: examineMsgs //审核记录
          }
        })
          .then(res => {
            // console.log(res.data)
            this.text = false
            this.startBtn = true
            this.text1 = true
            this.okFormVisible = false
            this.endFormVisible = false
            this.$axios({
              method: 'PUT',
              url: '/lps/test/testreport/' + this.reportId,
              data: {
                confirmstat: '20'
              }
            })
              .then(res => {
                // console.log(res.data)
                this.CloseWebPage()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    //获取图片名称key等数据
    getjpgData() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/upurl?filetype=jpg'
      })
        .then(res => {
          console.log(res.data)
          this.fromData = res.data
          this.key = res.data.objpath + res.data.objname
          this.imgName = res.data.objname //图片名称
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    upload(file) {
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      this.imageUrl = URL.createObjectURL(file.raw);

      reader.onload = function (e) {
        //   // this.imageUrl = this.result
        document.getElementById('show').src = this.result
        // document.getElementById('bigShow').src = this.result
      }
      const formData = new FormData();
      formData.append('policy', this.fromData.policy);
      formData.append('key', this.key);
      formData.append('x-obs-acl', 'public-read');
      formData.append('content-type', 'text/plain');
      formData.append('AccessKeyId', this.fromData.ak);
      formData.append('signature', this.fromData.signature);
      formData.append('file', file.raw);
      this.$axios.post('https://lps-hbdcjc-com.obs.cn-north-1.myhuaweicloud.com', formData, {
      }).then((res) => {
        console.log(res)
      })
    },
    upImage() {
      //显示上传图片的表单
      this.imgIframe = true
      this.getjpgData()
    },
    upImg() {
      //向接口上传图片
      this.$axios({
        method: 'PUT',
        url: '/lps/test/testreport/content/' + this.$route.query.id,
        data: {
          ircode: this.imgName //二维码名称
        }
      })
        .then(res => {
          console.log(res.data)
          this.imgIframe = false
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    changepic() {
      //选择图片后回显图片
      var reads = new FileReader()
      let f = document.getElementById('file').files[0]
      reads.readAsDataURL(f)
      reads.onload = function (e) {
        document.getElementById('show').src = this.result
        document.getElementById('bigShow').src = this.result
      }
    },
    //默认显示图片
    imgimg() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + this.ircode
      })
        .then(res => {
          // console.log(res.data)
          this.dialogImageUrl = res.data.url
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    //#endregion
    // ----------------------------------w-----------------------------------------
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    //页面定位
    testclick(data) {
      console.log('目录：', data.label)
      var dpoint = data.label.replace(/\s*\(.*?\)\s*/g, '')
      if (dpoint) {
        // console.log('querySelector',document.querySelector('#anch3'))
        document.getElementById(dpoint).scrollIntoView({
          behavior: 'instant'
        })
      }
    },
    //回调函数调用分区子组件修约方法
    useevenRound(v1, v2) {
      let result = this.$refs.subrgion[0].evenRound(v1, v2)
      this.$bus.$emit('changepressureEqualizingRingSpacing', result)
    },
    //获取场所等全部相关数据
    ReportTest() {
      this.$axios({
        method: 'get',
        url: '/lps/test/testreport/content/' + this.$route.query.id
      }).then(res => {
        if (res.data.siteList != null) {
          // this.reportTemplate = res.data.reportTemplate
          this.siteList = res.data.siteList
          this.pointNumber(res.data.siteList)
          this.getExamineData() //防止第一次渲染页面,子组件未加载全局事件未绑定,此方法中触发全局事件不起作用的问题
        }
      })
    },
    //遍历出分区数组中每个对象的长度，作为分区检测点的个数
    pointNumber(pointLists) {
      var pointList = JSON.parse(JSON.stringify(pointLists)) //深拷贝防止影响sitelist数组
      for (var i = 0; i < pointList.length; i++) {
        var bigsum = 0
        if (pointList[i].areaList) {
          if (pointList[i].areaList.length > 0) {
            for (var j = 0; j < pointList[i].areaList.length; j++) {
              var sum = 0
              for (var m = 0; m < pointList[i].areaList[j].sourceDataList.length; m++) {
                if (pointList[i].areaList[j].sourceDataList.length > 0) {
                  var changObj = pointList[i].areaList[j].sourceDataList[m]
                  for (let item in changObj) {
                    if (item.indexOf('Standard') != -1 || changObj[item] == '请选择' || item.indexOf('Unit') != -1 || changObj[item] === '') {
                      delete changObj[item]
                    }
                  }
                  // console.log('changeObj:',changObj);
                  var arr = Object.keys(changObj)
                  // console.log('arr:',arr);
                  // console.log('分区长度：', arr.length)
                  sum += arr.length - 4
                  pointList[i].areaList[j].count = sum
                } else {
                  pointList[i].areaList[j].count = 0
                }
              }
              bigsum += pointList[i].areaList[j].count
              pointList[i].count = bigsum
            }
          } else {
            pointList[i].count = 0
          }
        } else {
          if (pointList[i].sourceDataList.length > 0) {
            var sum = 0
            for (var m = 0; m < pointList[i].sourceDataList.length; m++) {
              if (pointList[i].sourceDataList.length > 0) {
                var changObj = pointList[i].sourceDataList[m]
                for (let item in changObj) {
                  if (item.indexOf('Standard') != -1 || changObj[item] == '请选择' || item.indexOf('Unit') != -1 || changObj[item] === '') {
                    delete changObj[item]
                  }
                }
                // console.log('changeObj:',changObj);
                var arr = Object.keys(changObj)
                // console.log('arr:',arr);
                // console.log('分区长度：', arr.length)
                sum += arr.length - 1
                pointList[i].count = sum
              } else {
                pointList[i].count = 0
              }
            }
            bigsum += pointList[i].count
            pointList[i].count = bigsum
          } else {
            pointList[i].count = 0
          }
        }

      }
      this.getsiteList(pointList)
    },
    //目录结构tree数据转换
    getsiteList(list) {
      var changeList = JSON.parse(JSON.stringify(list))
      changeList.forEach(element => {
        var obj = {
          label: element.siteName + (element.count > 0 ? `(${element.count})` : ''),
          children: element.areaList && element.areaList.length > 0 ? this.copyTree(element.areaList) : element.areaList
        }
        this.treedata.push(obj)
      })
      // console.log('改变后的tree:', this.treedata);
    },
    //递归转换数据格式中每层的格式
    copyTree(childrenList) {
      // console.log('copyTree:',this.count);
      childrenList.forEach(item => {
        item.label = item.areaName + (item.count > 0 ? `(${item.count})` : '')
        item.children = item.childrenList
        if (item.childrenList && item.childrenList.length) {
          this.copyTree(item.children)
        }
      })
      return childrenList
    },
    //页面批注信息返回
    pageAnnotationChange(key, value1) {
      if (this.lastvalue !== value1 && value1 != '') {
        this.lastkey = key
        this.lastvalue = value1
        this.commentData[key] = value1
        console.log('测试批注',this.commentData);
      } else if (this.lastvalue == value1 && this.lastkey !== key) {
        this.commentData[key] = value1
        console.log(this.commentData)
      } else if (this.lastvalue !== value1 && value1 == '') {
        this.commentData[key] = value1
        delete this.commentData[key]
        // console.log('删除属性', this.commentData)
      }
    },
    //审核确认表信息回传文字格式
    auditChange(key, value) {
      const audit = {}
      for (var item in this.examineMsgs) {
        // console.log('上次',this.examineMsgs[item].msg);
        if (this.examineMsgs[item].msg.indexOf(key) != -1 && value != '') {
          this.examineMsgs[item].msg = key + value
          // console.log('替换', this.examineMsgs);
          return
        }
      }
      if (this.auditlastvalue !== value && value != '') {
        //添加新批注
        this.auditlastkey = key
        this.auditlastvalue = value
        audit.msg = key + value
        this.examineMsgs.push(audit)
        // console.log('添加examineMsgs', this.examineMsgs);
      } else if (this.auditlastvalue == value && this.auditlastkey !== key) {
        //和上次批注信息一样情况
        audit.msg = key + value
        this.examineMsgs.push(audit)
        // console.log('父组件中',audit);
      } else if (this.examinecontents == '初始值' && value == '') {
        // 第一次没提交时修改批注为空
        // console.log(666666);
        for (var item in this.examineMsgs) {
          // console.log('上次',this.examineMsgs[item].msg);
          if (this.examineMsgs[item].msg.indexOf(key) != -1) {
            delete this.examineMsgs[item]
            // console.log('删除后', this.examineMsgs);
          }
        }
      } else if (this.examinecontents != '初始值' && value == '') {
        //批注提交后修改批注为空
        audit.msg = key
        this.examineMsgs.push(audit)
        // console.log(88888);
        // for(var item in this.examineMsgs){
        //   // console.log('上次',this.examineMsgs[item].msg);
        //   if(this.examineMsgs[item].msg.indexOf(key) != -1) {
        //     delete this.examineMsgs[item]
        //     console.log('删除后',this.examineMsgs);
        //   } else {
        //     audit.msg = key
        //     this.examineMsgs.push(audit)
        //   }
        // }
        // console.log('第二次：', this.examineMsgs)
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.imgshowListener)
  }
}
</script>
<style scoped>
#btnList {
  height: 40px;
  width: 98.8%;
  padding: 10px;
  background-color: whitesmoke;
}

.left {
  float: left;
}

.right {
  height: 40px;
  line-height: 1px;
  float: left;
  border: 1px solid gainsboro;
  box-shadow: 1px 1px 4px gainsboro;
  /* border-bottom: 1px solid silver; */
  border-radius: 5px;
  text-align: center;
  margin-left: 30px;
}

/* 回到顶部 */
.top {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 50px;
  right: 100px;
}

.el-icon-plus .avatar-uploader-icon {
  border: 1px solid saddlebrown;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

#siteListdiv{
    margin-left: 25%;
    width: 1040px;
    padding: 40px; 
    border: 1px solid gainsboro;
    box-shadow: 1px 2px 13px grey;
    margin-top: 30px; 
}
/* ----------------------------------w------------------------------------ */
#main {
  overflow-x: hidden;
  width: 100%;
}

#total {
  margin-left: 25%;
  width: 60%;
  border: 1px solid gainsboro;
  box-shadow: 1px 2px 13px grey;
  margin-top: 30px;
}

#center {
  width: 90%;
  margin: 0px 50px;
}

#top {
  margin: 20px 200px;
}

#catalog {
  border: 1px solid gainsboro;
  width: 20%;
  height: 70%;
  position: fixed;
  overflow-y: auto;
  /* position: fixed;
    display:flex;
    justify-content:flex-start;
    flex-direction: column; */
  left: 10px;
}

#catatop {
  text-align: center;
  font-size: 22px;
  letter-spacing: 20px;
}

#catacenter {
  margin: 10px 100px;
  font-size: 16px;
}

.span1 {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.span2 {
  position: absolute;
  left: 300px;
}

#attachcenter {
  margin: 10px 50px;
  font-size: 16px;
}

#attachbottom p {
  margin-left: 400px;
  text-decoration: underline;
}
</style>