/*
 * @Author: 白一桐
 * @Date: 2023-03-29 08:52:31
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-08-14 08:53:58
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
// import '@/assets/font/cPrint.css'
import '@/assets/font/font.css'
import 'element-ui/lib/theme-chalk/index.css';//引入ElementUI的所有样式
import Vue2OrgTree from 'vue2-org-tree';
import 'vue2-org-tree/dist/style.css';
import request from './util/request';
import JsonExcel from 'vue-json-excel';
import VueCookies from 'vue-cookies';
import print from 'vue-printjs';
import printnb from 'vue-print-nb';
import $ from 'jquery'
window.jQuery = $
window.$ = $ 
import ELtable3 from './components/table3.vue'
import App from './App.vue'
import router from './router/index'
Vue.component('el-table3',ELtable3)
Vue.use(print)
Vue.use(printnb)
Vue.use(VueCookies)
Vue.use(Vue2OrgTree)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$axios = request
Vue.component('downloadExcel',JsonExcel)


// // 导出页面为PDF格式
// import html2Canvas from 'html2canvas'
// import JsPDF from 'jspdf'
// Vue.prototype.getPdf = function (title, dom, emptyDomId) {
//             // 注册getPdf方法，传入两个参数，此处使用了promise处理导出后的操作
//             /*
//             title: 导出文件名
//             dom: 需要导出dom的id
//              */
//             return new Promise((resolve, reject) => {
//                 html2Canvas(dom, {
//                     useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
//                     allowTaint: true // 允许跨域
//                 }).then(canvas => {
//                     const contentWidth = canvas.width
//                     const contentHeight = canvas.height
//                     // 根据A4纸的大小，计算出dom相应比例的尺寸
//                     const pageHeight = contentWidth / 592.28 * 841.89
//                     let leftHeight = contentHeight
//                     let position = 0
//                     const imgWidth = 595.28
//                     // 根据a4比例计算出需要分割的实际dom位置
//                     const imgHeight = 592.28 / contentWidth * contentHeight
//                     // canvas绘图生成image数据，1.0是质量参数
//                     const pageData = canvas.toDataURL('image/jpeg', 1.0)
//                     // a4大小
//                     const PDF = new JsPDF('', 'pt', 'a4')
//                     // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
//                     if (leftHeight < pageHeight) {
//                         PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//                     } else {
//                         while (leftHeight > 0) {
//                             PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//                             leftHeight -= pageHeight
//                             position -= 841.89
//                             if (leftHeight > 0) {
//                                 PDF.addPage()
//                             }
//                         }
//                     }
//                     // 导出
//                     PDF.save(title + '.pdf')
//                     // 删除添加的空div

//                     const emptyDom = document.getElementById(emptyDomId)
//                     emptyDom.remove()
//                     resolve(true)
//                 })
//                     .catch(() => {
//                         reject(false)
//                     })
//             })
//         }

new Vue({
  router,
  render: h => h(App),
  beforeCreate(){
		Vue.prototype.$bus = this //安装全局事件总线，$bus就是当前应用的vm
}
}).$mount('#app')




