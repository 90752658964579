<!--
 * @Author: 白一桐
 * @Date: 2023-03-31 16:58:44
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-10-09 16:35:09
-->
/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:38:17 
 * @Last Modified by:   王翎爆 
 * @Last Modified time: 2023-03-30 09:38:17 
 */
<template>
  <div>
    <Menu> </Menu>
    <!-- autoplay 自动播放 -->
    <div>
      <div style="text-align: center;margin-top: 30px;">
        <h1>---欢迎来到创检平台---</h1>
      </div>
      <div style="margin:0 auto;display: flex;padding: 50px;margin-left: 14%;">
        <div>
          <img src="../assets/appcode.png" alt="" srcset="" style="width: 200px;height: 200px;text-align: center;">
        </div>
        <div style="margin-left:30px">
          <h2>App下载链接</h2>
          <p style="color: red;margin-top:20px">注：请使用手机浏览器扫码下载app</p>
          <br>
          <el-alert style="width:80% ;background-color: rgb(118, 27, 27);color: aliceblue;" :title=this.msg :closable="false">
          </el-alert>
        </div>

      </div>

      <div style="width: 70%;margin: 0 auto; ">
        <div>
          <el-dialog title="操作视频" :visible.sync="dialogTableVisible" v-if="dialogTableVisible" class="dialogs" :before-close="closeDialog">
            <video controls class="videoItem" width="30%" loop="loop" id="videoa1" autoplay>
              <source :src="videodata" type="video/webm">
            </video>
          </el-dialog>
        </div>
        <div style="margin-bottom:20px">
          <h2 style="margin-bottom:15px"> App操作说明视频</h2>
          <el-collapse style="margin-left: 50px;">
            <el-collapse-item title="功能" name="1">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a1.png" alt="" srcset="" @click="opena1">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a2.png" alt="" srcset="" @click="opena2">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a31.png" alt="" srcset="" @click="opena31">
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="报告、设备" name="2">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a3.png" alt="" srcset="" @click="opena3">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a4.png" alt="" srcset="" @click="opena4">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a5.png" alt="" srcset="" @click="opena5">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a30.png" alt="" srcset="" @click="opena30">
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="内容" name="3">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a6.png" alt="" srcset="" @click="opena6">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a8.png" alt="" srcset="" @click="opena8">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a9.png" alt="" srcset="" @click="opena9">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a26.png" alt="" srcset="" @click="opena26">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a27.png" alt="" srcset="" @click="opena27">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a28.png" alt="" srcset="" @click="opena28">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a29.png" alt="" srcset="" @click="opena29">
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="场所建筑物" name="4">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a10.png" alt="" srcset="" @click="opena10">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a11.png" alt="" srcset="" @click="opena11">
                </div>

                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a25.png" alt="" srcset="" @click="opena25">
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="参数" name="5">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a12.png" alt="" srcset="" @click="opena12">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a13.png" alt="" srcset="" @click="opena13">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a14.png" alt="" srcset="" @click="opena14">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a15.png" alt="" srcset="" @click="opena15">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a16.png" alt="" srcset="" @click="opena16">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a17.png" alt="" srcset="" @click="opena17">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a18.png" alt="" srcset="" @click="opena18">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a19.png" alt="" srcset="" @click="opena19">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a20.png" alt="" srcset="" @click="opena20">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a21.png" alt="" srcset="" @click="opena21">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a22.png" alt="" srcset="" @click="opena22">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/a24.png" alt="" srcset="" @click="opena24">
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div>
          <h2 style="margin-bottom:15px"> Pc操作说明视频（暂无视频）</h2>
          <el-collapse style="margin-left: 50px;margin-bottom: 100px;">
            <el-collapse-item title="功能" name="1">

              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p1.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p3.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p14.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p15.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p16.png" alt="" srcset="" width="215">
                </div>
              </div>

            </el-collapse-item>

            <el-collapse-item title="操作" name="2">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p2.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p4.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p5.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p6.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p7.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p8.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p9.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p10.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p11.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p12.png" alt="" srcset="" width="215">
                </div>
                <div class="imagepcdiv">
                  <img src="../assets/firstpageimgs/p13.png" alt="" srcset="" width="215">
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>

        </div>

        <div>
          <h2 style="margin-bottom:15px"> 其他说明视频</h2>
          <el-collapse style="margin-left: 50px;margin-bottom: 100px;">
            <el-collapse-item title="检测报告编制说明" name="1">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h1.png" alt="" srcset="" @click="openh1">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h2.png" alt="" srcset="" @click="openh2">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h3.png" alt="" srcset="" @click="openh3">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h4.png" alt="" srcset="" @click="openh4">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h5.png" alt="" srcset="" @click="openh5">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h6.png" alt="" srcset="" @click="openh6">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/h7.png" alt="" srcset="" @click="openh7">
                </div>
              </div>
            </el-collapse-item>

            <el-collapse-item title="原始记录填写说明" name="2">
              <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r1.png" alt="" srcset="" @click="openr1">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r2.png" alt="" srcset="" @click="openr2">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r3.png" alt="" srcset="" @click="openr3">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r4.png" alt="" srcset="" @click="openr4">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r5.png" alt="" srcset="" @click="openr5">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r6.png" alt="" srcset="" @click="openr6">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r7.png" alt="" srcset="" @click="openr7">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r8.png" alt="" srcset="" @click="openr8">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r9.png" alt="" srcset="" @click="openr9">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r10.png" alt="" srcset="" @click="openr10">
                </div>
                <div class="imagediv">
                  <img src="../assets/firstpageimgs/r11.png" alt="" srcset="" @click="openr11">
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from '../components/Menu.vue'
export default {
  name: 'Index',
  data() {
    return {
      dialogTableVisible: false,
      msg: '更新提醒',
      videodata: ''
    }
  },
  components: {
    Menu
  },
  mounted() {
    this.getmsg()
  },
  methods: {
    getmsg() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=update_explain.txt'
      })
        .then(res => {
          this.$axios({
            method: 'GET',
            url: res.data.url
          }).then(res1 => {
            this.msg = res1.data
          })
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    geturl(name) {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + name
      })
        .then(res => {
          this.videodata = res.data.url
          this.dialogTableVisible = true
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    closeDialog() {
      this.dialogTableVisible = false
      var x = document.getElementById('videoa1')
      x.pause()
    },
    opena1() {
      this.geturl('a1登录演示.mp4')
    },
    opena2() {
      this.geturl('a2工作台说明.mp4')
    },
    opena3() {
      this.geturl('a3新建检测项目.mp4')
    },
    opena4() {
      this.geturl('a4新建检测报告.mp4')
    },
    opena5() {
      this.geturl('a5报告列表说明.mp4')
    },
    opena6() {
      this.geturl('a6填写报告封面信息.mp4')
    },
    opena8() {
      this.geturl('a8填写报告基本信息.mp4')
    },
    opena9() {
      this.geturl('a9填写检测数据.mp4')
    },
    opena10() {
      this.geturl('a10添加场所信息.mp4')
    },
    opena11() {
      this.geturl('a11年预计雷击次数.mp4')
    },
    opena12() {
      this.geturl('a12添加防雷分区.mp4')
    },
    opena13() {
      this.geturl('a13新增追加按钮说明.mp4')
    },
    opena14() {
      this.geturl('a14新增接闪器项目和参数.mp4')
    },
    opena15() {
      this.geturl('a15新增引下线项目和参数.mp4')
    },
    opena16() {
      this.geturl('a16新增接地装置项目和参数.mp4')
    },
    opena17() {
      this.geturl('a17新增等电位连接项目和参数.mp4')
    },
    opena18() {
      this.geturl('a18新增电源电涌保护器项目和参数.mp4')
    },
    opena19() {
      this.geturl('a19新增雷击电磁脉冲屏蔽项目和参数.mp4')
    },
    opena20() {
      this.geturl('a20新增防侧击雷装置.mp4')
    },
    opena21() {
      this.geturl('a21新增引下线防接触电压措施.mp4')
    },
    opena22() {
      this.geturl('a22新增接地装置防跨步电压措施.mp4')
    },
    opena24() {
      this.geturl('a24防雷分区列表说明.mp4')
    },
    opena25() {
      this.geturl('a25场所列表说明.mp4')
    },
    opena26() {
      this.geturl('a26检测结论编制.mp4')
    },
    opena27() {
      this.geturl('a27暂存检测报告.mp4')
    },
    opena28() {
      this.geturl('a28提交检测报告.mp4')
    },
    opena29() {
      this.geturl('添加报告附件.mp4')
    },
    opena30() {
      this.geturl('a30完成检测按钮.mp4')
    },
    opena31() {
      this.geturl('a31设备管理.mp4')
    },
    openh1() {
      this.geturl('存在问题通知书、结束标识、编制中常见问题说明.mp4')
    },
    openh2() {
      this.geturl('电涌保护器检查数据页的填写说明.mp4')
    },
    openh3() {
      this.geturl('防雷装置工况页的填写说明.mp4')
    },
    openh4() {
      this.geturl('封面、注意事项页、目录的填写说明.mp4')
    },
    openh5() {
      this.geturl('基本情况页的填写说明.mp4')
    },
    openh6() {
      this.geturl('通用数据页的填写说明.mp4')
    },
    openh7() {
      this.geturl('文字说明页、附图页的填写说明.mp4')
    },
    openr1() {
      this.geturl('1.组成.mp4')
    },
    openr2() {
      this.geturl('2.封面.mp4')
    },
    openr3() {
      this.geturl('3.基本情况页.mp4')
    },
    openr4() {
      this.geturl('4.检查页.mp4')
    },
    openr5() {
      this.geturl('5.文字说明页.mp4')
    },
    openr6() {
      this.geturl('6.通用数据页.mp4')
    },
    openr7() {
      this.geturl('7.单组SPD页.mp4')
    },
    openr8() {
      this.geturl('8.多组SPD页.mp4')
    },
    openr9() {
      this.geturl('9.接地（过渡）电阻页.mp4')
    },
    openr10() {
      this.geturl('10.接闪器引下线参数固定页.mp4')
    },
    openr11() {
      this.geturl('11.附图页.mp4')
    }
  }
}
</script>
<style scoped>
.imagediv {
  margin: 10px;
}

.imagediv img {
  width: 215px;
}

.imagepcdiv {
  margin: 10px;
}

.imagepcdiv img {
  width: 410px;
}

.dialogs {
  text-align: center;
}
</style>