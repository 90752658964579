<!--
 * @Author: 白一桐
 * @Date: 2023-02-24 15:22:58
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-08-22 15:07:13
-->
<template>
  <div>
    <div id="coverbody">
      <div id="border1">
        <!-- 二维码部分 -->
        <div id="ewm">
          <div class="ewmImg">
            <slot name="ircodede"></slot>
          </div>
        </div>
        <!-- 内容部分 -->
        <div id="cont">
          <div style="width: 350px;margin: 0 auto;padding-bottom: 50px;font-size: 38px;">
            <h2>
              <slot name="title"></slot>
            </h2>
          </div>
          <!-- 检测报告编号 -->
          <div id="message">
            <p>编号：{{ reportingData.trptNo }}</p>
          </div>
          <!-- 信息 -->
          <div id="message">
            <div id="detailedMsg">
              <div class="msgleft">委托单位：</div>
              <div class="msgright">
                {{ reportingData.client }}
              </div>
            </div><!-- 委托单位名称 -->
            <div id="detailedMsg">
              <div class="msgleft">受检单位：</div>
              <div class="msgright">
                {{ reportingData.insunit }}
              </div>
            </div><!-- 受检单位名称 -->
            <div id="detailedMsg">
              <div class="msgleft">检测场所：</div>
              <div class="msgright">
                {{ reportingData.sites }}
              </div>
            </div><!-- 检测场所名称 -->
            <div id="detailedMsg">
              <div class="msgleft">检测类别：</div>
              <div class="msgright">
                {{ reportingData.testType }}
              </div>
            </div><!-- 检测类别 -->
            <div id="detailedMsg">
              <div class="msgleft">所在地址：</div>
              <div class="msgright">
                {{ reportingData.sitesAddreess }}
              </div>
            </div><!-- 所在地址 -->
            <div id="detailedMsg">
              <div class="msgleft">检测日期：</div>
              <div class="msgright" v-if="reportingData.workStart != this.reportingData.workEnd">
                {{ reportingData.workStart }}{{ " ～ " + this.reportingData.workEnd }}
              </div>
              <div class="msgright" v-else>
                {{ reportingData.workStart }}
              </div>
            </div><!-- 检测日期 -->
          </div>
        </div>
        <div id="logo">
          <img src="../assets/logo.png" alt="logo">
          <div>
            <h1>河北德创检测服务有限公司</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['reportingData'],

  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>
#coverbody {
  margin-left: 25%;
  width: 840px;
  min-height: 317mm;
  border: 1px solid gainsboro;
  box-shadow: 1px 2px 13px grey;
  margin-top: 30px;
  /* margin-bottom: 1120px; */
}

#border1 {
  width: 630px;
  height: 800px;
  position: absolute;
  margin: 100px;
}

#ewm {
  width: 630px;
  height: 150px;
}

.ewmImg {
  width: 150px;
  height: 150px;
  float: right;
}

#file {
  color: darkgrey;
}

#cont {
  width: 630px;
  min-height: 650px;
}

#logo {
  /* border: 1px solid saddlebrown; */
  width: 630px;
  height: 330px;
  margin-top: 30px;
  /* margin-bottom: 30px; */
  text-align: center;
}

#logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  /* margin: 30px; */
}


#message {
  margin: 0 auto;
  font-size: 20px;
  padding-left: 100px;
  padding-bottom: 50px;
}

#detailedMsg {
  width: 500px;
  min-height: 30px;
  margin-bottom: 10px;
}

#detailedMsg .msgleft {
  width: 100px;
  float: left;
}

#detailedMsg .msgright {
  width: 350px;
  float: left;
}
</style>
