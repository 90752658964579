/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:37:20 
 * @Last Modified by:   王翎爆 
 * @Last Modified time: 2023-03-30 09:37:20 
 */
<template>
    <div id="parent">
        <h1>批准表</h1>
        <el-descriptions class="margin-top" title="批准内容" :column="1" :size="size" border>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-user"></i>
                    批准人
                </template>
                {{ approver }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-date"></i>
                    结束日期
                </template>
                {{ dateClosed }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-document"></i>
                    报告编号
                </template>
                {{ reportcode }}
            </el-descriptions-item>
        </el-descriptions>
        <el-table :data="ratifycontent" stripe style="width: 100%">
            <el-table-column prop="msg" label="批准信息:">
            </el-table-column>
        </el-table>
        <div id="opinion">
            <p>审核意见:</p>
            <p style="text-indent: 2em;">{{ ratifymeta }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    data() {
        return {
            size: '',
            approver: '',
            dateClosed: '',
            reportcode: '',
            ratifycontent: [],
            ratifymeta: ''
        };
    },
    components: {
    },
    mounted() {
        this.affirmInit()
    },
    methods: {
        affirmInit() {
            this.$axios({
                method: 'GET',
                url: '/lps/test/testreport/confirm/his/' + this.$route.query.taskid,
            }).then(res => {
                if (res.data != null) {
                    console.log(res.data);
                    let changeFormat = JSON.parse(res.data.confirmtext)
                    console.log(changeFormat);
                    this.approver = res.data.confirmuserrelname
                    this.dateClosed = res.data.confirmtime
                    this.reportcode = res.data.trptno
                    this.ratifymeta = res.data.confirmmeta
                    for (var item in changeFormat) {
                        console.log(item);
                        const change = {}
                        var items = Number(item) + 1
                        change.msg = '\xa0' + '\xa0' + '\xa0' + '\xa0' + '\xa0' + '\xa0' + items + '.\xa0' + changeFormat[item].msg
                        this.ratifycontent.push(change)
                    }
                }
            })
        }
    },
};
</script>
<style  scoped> #parent {
     width: 50%;
     height: auto;
     border: 1px solid gainsboro;
     box-shadow: 1px 1px 1px grey;
     margin: 30px auto;
 }


 #opinion {
     color: rgb(115, 119, 126);
     margin-left: 10px;
     margin-bottom: 20px;
 }

 #opinion p {
     margin-top: 10px;
 }

 #parent h1 {
     text-align: center;
     margin-top: 10px;
 }
</style>