<!--
 * @Author: 白一桐
 * @Date: 2023-09-23 15:42:55
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-05-13 17:15:13
 个人资质的图片
-->
<template>
  <div style="width: 180mm;height: 50mm; padding: 10px; ">
    <img :src="urls" alt="" width="600px">
  </div>
</template>

<script>
export default {
  props: ['peopleImg'],
  data() {
    return {
      urls: ''
    }
  },
  mounted() {
    // console.log(this.peopleImg)
    this.get()
  },
  methods: {
    get() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + this.peopleImg,
      }).then(res => {
        // console.log(res.data)
        this.urls = res.data.url
      }).catch(error => {
        console.log(error.response.data)
      })
    },
  }
}
</script>

<style></style>
