<!--
 * @Author: 白一桐
 * @Date: 2023-09-19 11:19:49
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-09-23 17:45:23
-->
<template>
  <div>
    <Menu></Menu>
    <el-backtop style="background-color: rgb(198, 203, 203);"></el-backtop>
    <div v-for="item in messageData" :key="item.id">
      <div id="company" v-if="item.owntype == '公司' && item.objid == 0">
        <!-- <el-divider content-position="left">公司营业执照</el-divider> -->
        <qualificationManage :msgdata="item">
          <template #br>
            <h2>公司营业执照</h2>
          </template>
        </qualificationManage>
      </div>
      <div id="company" v-if="item.owntype == '公司' && item.objid == 1">
        <!-- <el-divider content-position="left">公司检测资质</el-divider> -->
        <qualificationManage :msgdata="item">
          <template #br>
            <h2>公司检测资质</h2>
          </template>
        </qualificationManage>
      </div>
      <!-- <el-divider content-position="left">个人检测资质</el-divider> -->
      <div v-if="item.owntype == '用户'" id="thatperson">
        <qualificationManage :msgdata="item">
          <template #br>
            <h2>{{ item.name }}检测资质</h2>
          </template>
        </qualificationManage>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '../../components/Menu.vue'
import qualificationManage from '../../components/qualificationManage.vue'


export default {
  data() {
    return {
      messageData: [],
    }
  },
  components: {
    Menu,
    qualificationManage,
  },
  mounted() {
    this.getperson()
  },
  methods: {
    getperson() {
      this.$axios({
        method: 'GET',
        url: '/lps/sys/user',
      }).then(res => {
        let data = res.data.rows
        this.messageData.push({
          objid: '0',
          owntype: "公司",
          ownid: this.$cookies.get('user').orgid,
        })
        this.messageData.push({
          objid: '1',
          owntype: "公司",
          ownid: this.$cookies.get('user').orgid,
        })
        for (let i = 0; i < data.length; i++) {
          let obj = {}
          obj.owntype = "用户"
          obj.ownid = data[i].id
          obj.name = data[i].name
          this.messageData.push(obj)
        }
        // console.log(this.messageData)
      }).catch(error => {
        console.log(error.response.data)
      })
    }
  }
}
</script>

<style scoped>
#company {
  width: 50%;
  /* height: 700px; */
  /* border: 1px solid saddlebrown; */
  margin: 20px;
  margin: 0 auto;
}

#thatperson {
  width: 50%;
  /* height: 300px; */
  /* border: 1px solid rgb(128, 211, 83); */
  /* margin: 20px; */
  margin: 0 auto;
}

.goTop {
  height: 100vh;
  overflow-x: hidden;
}
</style>
