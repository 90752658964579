<!--
 * @Author: 白一桐
 * @Date: 2023-03-29 08:52:31
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-10-14 10:30:20
-->
<template >
  <div id="app">
    <div class="border-box">
      <div id="div">
        <h3>创检平台用户登陆</h3>
        <div class="item">
          <!-- required 对输入框非空校验 -->
          <input type="text" required v-model="sbm.name">
          <label for="">username</label>
        </div>
        <div class="item">
          <input type="password" required v-model="sbm.password">
          <label for="">password</label>
        </div>
        <button class="btn" @click="login" :plain="true" @keyup.enter="keyDown(e)">登录
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sbm: {
        name: '',
        password: ''
      }
    }
  },
  //利用路由守卫来进行单独设置每个页面的背景颜色
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#055b89'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    login() {
      this.$axios({
        method: 'POST',
        url: '/lps/sys/user/login',
        data: this.sbm
      })
        .then(res => {
          if (res.data != null) {
            sessionStorage.setItem('token', res.data.token)
            this.$cookies.set('user', res.data)
            this.$cookies.set('pwd', this.sbm.password)
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            this.$router.push('/Index')
          }
        })
        .catch(error => {
          console.log(error.response.data)
          let errorData = error.response.data
          this.$message({
            message: errorData.message,
            type: 'error'
          })
        })
    },
    keyDown(e) {
      if (e.keyCode == 13) {
        this.login()
      }
    }
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.border-box {
  position: relative;
  margin: 300px auto;
  width: 400px;
  height: 300px;
  background: #173f54;
  border-radius: 8px;
}

.border-box h3 {
  /* line-height:50px; */
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

#div {
  padding: 30px;
}

.item {
  /* width: 100px; */
  height: 45px;
  border-bottom: 1px solid #fff;
  margin-bottom: 40px;
  position: relative;
}

.item input {
  width: 100%;
  height: 100%;
  color: #fff;
  /* 输入框输入光标为白色 */
  padding-top: 20px;
  box-sizing: border-box;
}

/* valid 合法状态下  focus 获取焦点时*/
.item input:focus + label,
.item input:valid + label {
  top: 0;
  font-size: 12px;
}

.item label {
  position: absolute;
  left: 0;
  top: 12px;
  transition: all 0.2s linear;
  color: #03e9f4;
  /* linear 匀速 */
}

/* 重置输入框和按钮的样式 */
input,
button {
  /* transparent表示透明无颜色，是background-color 的默认值 */
  background: transparent;
  border: none;
  outline: none;
}

.btn {
  width: 100%;
  padding: 10px 20px;
  color: #03e9f4;
  position: relative;
  overflow: hidden;
}

.btn:hover {
  background-color: #03e9f4;
  border-radius: 5px;
  color: #fff;
}

.btn > span {
  position: absolute;
}

.btn > span:nth-child(1) {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, transparent, #03e9f4);
  left: -100%;
  top: 0;
  animation: span-line-one 2s linear infinite;
}

.btn > span:nth-child(2) {
  width: 2px;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, #03e9f4);
  right: 0;
  top: -100%;
  /* 延迟 0.25s */
  animation: span-line-two 2s 0.25s linear infinite;
}

.btn > span:nth-child(3) {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to left, transparent, #03e9f4);
  left: 100%;
  bottom: 0;
  /* 延迟 0.5s */
  animation: span-line-three 2s 0.5s linear infinite;
}

.btn > span:nth-child(4) {
  width: 2px;
  height: 100%;
  background-image: linear-gradient(to top, transparent, #03e9f4);
  left: 0;
  bottom: -100%;
  /* 延迟 0.75s */
  animation: span-line-four 2s 0.75s linear infinite;
}

/*@keyframes 使一个div元素逐渐移动n像素 */
@keyframes span-line-one {
  50%,
  100% {
    left: 100%;
  }
}

@keyframes span-line-two {
  50%,
  100% {
    top: 100%;
  }
}

@keyframes span-line-three {
  50%,
  100% {
    left: -100%;
  }
}

@keyframes span-line-four {
  50%,
  100% {
    bottom: 100%;
  }
}
</style>
