<template>
<div>
    <el-button @click="exportReport()">生成PDF</el-button>
    <div>
    <div id="fbody">
          <div v-for="(printitem, printbindex) in siteList" :key="printbindex" id="fbodystyle" >
            <printReportSummary :index="printbindex" :id="printitem.siteName" :SummaryName="printitem"  className="item">
            </printReportSummary>
            <div v-for="(item, index) in printitem.areaList" :key="index" >
              <printReportSubrgion :id="item.areaName" :SubrgionName="item" ref="printsubrgion">
              </printReportSubrgion>
              <printReportImg v-if="item.photo" :photo="item.photo" :key="index">
              </printReportImg>
            </div>
          </div>
            <!-- <div>
            <paperModule :siteList="siteList" v-if="Object.keys(siteList).length>0"></paperModule>
          </div> -->
        </div>
    </div>
</div>
</template>
<script>
import printReportSummary from '@/views-w/printReportSummary'
import printReportSubrgion from '@/views-w/printReportSubrgion'
import printReportImg from '@/views-w/printReportImg'
export default {
name:'',
data() {
  return {
    siteList: []
  };
},
components:{
    printReportSummary,
    printReportSubrgion,
    printReportImg
},
mounted(){
    this.ReportTest()
},
methods: {
    ReportTest() {
      this.$axios({
        method: 'get',
        url: '/lps/test/testreport/content/' + 56
      }).then(res => {
        if (res.data.siteList != null) {
          this.siteList = res.data.siteList
        }
      })
    },
    exportReport() {
       const target = document.getElementById('fbody')
       this.outPutPdfFn('检测报告', target, 'item')
    },
    outPutPdfFn(title, target, itemClass) {
            const vm = this
            const A4_WIDTH = 592.28
            const A4_HEIGHT = 841.89

            vm.$nextTick(() => {
                // dom的id。
                const pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT
                // 获取分割dom，此处为class类名为item的dom
                const lableListID = document.getElementsByClassName(itemClass)
                // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
                for (let i = 0; i < lableListID.length; i++) {
                    const multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight)
                    if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                        const divParent = lableListID[i].parentNode // 获取该div的父节点
                        const newNode = document.createElement('div')
                        newNode.className = 'emptyDiv'
                        newNode.id = 'emptyDiv'
                        newNode.style.background = '#ffffff'
                        const _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight)
                        newNode.style.height = _H + 30 + 'px'
                        newNode.style.width = '100%'
                        const next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
                        // 判断兄弟节点是否存在
                        console.log(next)
                        if (next) {
                            // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
                            divParent.insertBefore(newNode, next)
                        } else {
                            // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
                            divParent.appendChild(newNode)
                        }
                    }
                }
                // 传入title和dom标签，此处是 #content
                // 异步函数，导出成功后处理交互
                this.getPdf(title, target, 'emptyDiv').then(() => {
                    // 自定义等待动画关闭
                    this.$message({
                        type: 'success',
                        message: '导出成功'
                    })
                    this.detailSHow = false
                })
                this.$nextTick(() => {
                    const emptyDom = document.getElementById('emptyDiv')
                    emptyDom.remove()
                })
            })
        },
        isSplit(nodes, index, pageHeight) {
            // 计算当前这块dom是否跨越了a4大小，以此分割
            if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
                return true
            }
                return false
        }
  },
};
</script>
<style  scoped> 

</style>