<!--
 * @Author: 白一桐
 * @Date: 2024-07-04 10:23:38
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-08-08 17:33:53
-->
<template>
  <div id="buildbox">
    <div style="width: 210mm;min-height: 297mm; border:1px solid rgb(76, 76, 76);page-break-before: always;">
      <div id="homebox">
        <div id="homebox">
          <div class="homeleft" style="text-align: center;width: 100%;">建筑物基本情况</div> 
        </div> 
        <div id="homebox">
          <div class="homeleft">编号</div>
          <div class="homeright">
            <p>{{ reportingData.buildingWidth }}</p>
          </div>
        </div> 
        <div class="homeleft">名称</div>
          <div class="homeright">
            <p>{{ reportingData.siteName }} </p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">周围环境</div>
          <div class="homeright" >
            <p>{{ reportingData.environment }}</p> 
          </div>
        </div> 
        <div id="homebox">
          <div class="homeleft">长度/m</div>
          <div class="homeright"> 
            <p>{{ reportingData.buildingLength }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">宽度/m</div>
          <div class="homeright">
            <p>{{ reportingData.buildingWidth }}</p>
          </div> 
        </div> 
        <div id="homebox">
          <div class="homeleft">高度/m</div>
          <div class="homeright">
            <p>{{ reportingData.buildingHeight }}</p>
          </div>
        </div>  
        <div id="homebox">
          <div class="homeleft">年预计雷击次数</div>
          <div class="homeright">
            <p v-if="reportingData.lightningStrokePerYear">{{ reportingData.lightningStrokePerYear }}</p>
            <p v-else >/</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">爆炸危险环境场所</div>
          <div class="homeright">
            <p>{{ reportingData.isDangerSite }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">使用性质</div>
          <div class="homeright">
            <p>{{ reportingData.buildingWidth }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">防雷分类</div>
          <div class="homeright">
            <p>{{ reportingData.buildingWidth }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">电子信息系统雷电防护等级</div>
          <div class="homeright">
            <p>{{ reportingData.buildingWidth }}</p>
          </div>
        </div>
        <div id="homebox" style="min-height: 200px;">
          <div style="padding: 5px;">防雷区：</div> 
          <div style="padding: 5px;">  
            <p>{{ mineprotectionArea}}</p>
          </div>
        </div>
        <div id="homebox" style="min-height: 200px;">
          <div style="padding: 5px;">存在问题：</div> 
          <div style="padding: 5px;">
            <p>{{ reportingData.problem}}</p>
          </div>
        </div>
        <div id="homebox" style="min-height: 100px;border: none;">
          <div style="padding: 5px;">备注：</div> 
          <div style="padding: 5px;">  
            <p>{{ reportingData.bx}}</p>
          </div>
        </div>
    </div>
    <div id="marks">
      <div class="inputs"></div> 
      <el-input @blur="inputContent('编号')" v-model="buildMaker.siteNumber" class="inputs" placeholder="请输入批注[编号]"></el-input>
      <el-input @blur="inputContent('名称')" v-model="buildMaker.siteName" class="inputs" placeholder="请输入批注[名称]"></el-input>
      <el-input @blur="inputContent('周围环境')" v-model="buildMaker.environment" class="inputs" placeholder="请输入批注[周围环境]"></el-input>
      <el-input @blur="inputContent('长度')" v-model="buildMaker.buildingLength" class="inputs" placeholder="请输入批注[长度]"></el-input>
      <el-input @blur="inputContent('宽度')" v-model="buildMaker.buildingWidth" class="inputs" placeholder="请输入批注[宽度]"></el-input>
      <el-input @blur="inputContent('高度')" v-model="buildMaker.buildingHeight" class="inputs" placeholder="请输入批注[高度]"></el-input>
      <el-input @blur="inputContent('年预计雷击次数')" v-model="buildMaker.lightningStrokePerYear" class="inputs" placeholder="请输入批注[年预计雷击次数]"></el-input>
      <el-input @blur="inputContent('爆炸危险环境场所')" v-model="buildMaker.isDangerSite" class="inputs" placeholder="请输入批注[爆炸危险环境场所]"></el-input>
      <el-input @blur="inputContent('使用性质')" v-model="buildMaker.syxz" class="inputs" placeholder="请输入批注[使用性质]"></el-input>
      <el-input @blur="inputContent('防雷分类')" v-model="buildMaker.protectionType" class="inputs" placeholder="请输入批注[防雷分类]"></el-input>
      <el-input @blur="inputContent('电子信息系统雷电防护等级')" v-model="buildMaker.dzxxxtldfhdj" class="inputs1" placeholder="请输入批注[电子信息系统雷电防护等级]"></el-input>
      <el-input @blur="inputContent('防雷区')" v-model="buildMaker.mineprotectionArea" class="inputs1" placeholder="请输入批注[防雷区]"></el-input>
      <el-input @blur="inputContent('存在问题')" v-model="buildMaker.problem" class="inputs1" placeholder="请输入批注[存在问题]"></el-input>
      <el-input @blur="inputContent('备注')" v-model="buildMaker.bz" class="inputs1" placeholder="请输入批注[备注]"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  // props: ['reportingData', "examinecontents"],
  props: ['reportingData','endorseMessage','auditMessage'],

  data(){
    return{
      buildpageData:'',
      mineprotectionArea:'',
      buildMaker:{
        siteNumber:'',
        siteName:'',
        environment:'',
        buildingLength:'',
        buildingWidth:'',
        buildingHeight:'',
        lightningStrokePerYear:'',
        isDangerSite:'',
        syxz:'',
        protectionType:'',
        dzxxxtldfhdj:'',
        problem:'',
        mineprotectionArea:'',
        czwt:'',
        bz:'', 
      },
    }
  },
  created() { 
    this.$bus.$on('buildingBasicContents', this.buildingBasicContents)
  },
  mounted(){ 
    if(this.reportingData!=''){
      this.processingData()  
    }
  },
  
  methods:{
    getbuildpage() {
      this.buildpageData = document.getElementById("buildbox").innerHTML
    },
    processingData(){
      let sourceDataList = this.reportingData.sourceDataList
      for(let i in sourceDataList){
        if(sourceDataList[i].testProject == '防雷分区'){ 
          this.mineprotectionArea += sourceDataList[i].testPosition + " -- " +  sourceDataList[i].flfq + ";"
        }
      } 
    }, 
    //获取表格批注信息
    inputContent(name) { 
      const keys = Object.keys(this.buildMaker);
      keys.forEach(key => {  
        if(this.buildMaker[key] != ''){ 
          this.$emit('change', this.endorseMessage + `.${key}` , `${this.buildMaker[key]}`)
          this.$emit('auditChange', this.auditMessage + `-`+ name+ `:` , `${this.buildMaker[key]}`) 
        }
      }) 
    },
    //回显
    buildingBasicContents(examinecontents) {  
      for (let i in examinecontents) {
        if(i.indexOf("Total") == -1 && i.includes('siteList')){  
          if(this.endorseMessage != undefined){
            let msgIndex = this.endorseMessage.split(".") 
            var items = i.split(".") 
            const keys = Object.keys(this.buildMaker);
            keys.forEach(key => {
              // console.log(this.endorseMessage)  
              if(items[2] == key && items[1] == msgIndex[1]){  
                this.buildMaker[key] = examinecontents[i]  
              }
            })
          }
           
        } 
      } 
    },
  }
}
</script>

<style scoped>
@media print {
  #marks{
    display: none;
  }
}
@media screen {
  #buildbox{
    display:flex;  
    min-height: 317mm; 
    margin-top: 30px; 
  }
  #marks{
    width: 70mm;
    height: 297mm; 
  }
  .inputs{ 
    height: 45px;
    padding: 5px; 
  }   
  .inputs1{
    padding: 5px;
    height: 140px; 
  }  
}

#homebox {
  margin: 0;
  padding: 0; 
  width: 100%;
  border-bottom: 1px solid rgb(76, 76, 76);
  display: inline-block;
  box-sizing:border-box; 
}
.homeleft {
  margin: 0;
  padding: 0;
  width:30%;
  height: 40px;
  float: left;
  padding: 5px;
  text-align: center;
  line-height: 40px;  
}

.homeright {
  width: 66%;
  min-height: 40px;
  border-left: 1px solid rgb(76, 76, 76);
  /* padding: 10px; */
  padding-left: 14px;
  padding-bottom: 5px;
  padding-top: 5px;
  float: left;
  line-height: 40px;
  word-wrap:break-word 
}
 
</style>
