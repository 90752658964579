<!--
 * @Author: 白一桐
 * @Date: 2023-09-14 10:43:29
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-12-21 14:56:51
 展示报告中的附件
-->
<template>
  <div>
    <div id="border" style="page-break-before: always;">
      <div id="padborder">
        <h2>
          <slot name="title"></slot>
        </h2>
        <div id="img">
          <img :src="dialogImageUrl">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['imgname'],
  data() {
    return {
      dialogImageUrl: ''
    }
  },
  components: {},
  mounted() {
    // console.log(this.imgname)
    this.showpics()
  },
  methods: {
    showpics() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + this.imgname
      })
        .then(res => {
          // console.log(res.data)
          this.dialogImageUrl = res.data.url
        })
        .catch(error => {
          console.log(error.response.data)
        })
    }
  }
}
</script>

<style scoped>
@media print {
  #padborder {
    width: 210mm;
    height: 290mm;
    margin: 0 auto;
    padding: 20px;
  }

  #img {
    width: 200mm;
    height: 285mm;
    margin-top: 10px;
    padding: 30px;
    text-align: center;
    border: 1px solid rgb(89, 88, 88);
    box-sizing: border-box;
  }

  #img img {
    /* width: 700px;  */
    max-width: 100%;
    max-height: 230mm;
  }
}

@media screen {
  #border {
    width: 210mm;
    height: 297mm;
    margin-left: 25%;
    margin-top: 30px;
    border: 1px solid gainsboro;
    box-shadow: 1px 2px 13px grey;
    padding-top: 70px;
    box-sizing: border-box;
  }

  #padborder {
    width: 180mm;
    height: 250mm;
    margin: 0 auto;
    padding: 20px;
  }

  #img {
    width: 180mm;
    height: 240mm;
    margin-top: 10px;
    padding: 30px;
    text-align: center;
    border: 1px solid rgb(89, 88, 88);
    box-sizing: border-box;
  }

  #img img {
    max-width: 100%;
    /* width: 70%; */
    max-height: 230mm;
  }
}
</style>
