<!--
 * @Author: 白一桐
 * @Date: 2022-10-27 11:38:07
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-04-04 16:46:23
-->
<template>
  <div>
    <Menu></Menu>
    <div id="head">
      检测报告管理
      <el-button type="primary" plain size="small" id="btn" @click="search">筛选</el-button>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 95% ;margin: 0 auto;">
      <!-- <el-table-column prop="id" label="序号" width="70"></el-table-column> -->
      <el-table-column prop="entryname" label="项目名称" width="190"></el-table-column>
      <el-table-column prop="trptno" label="检测报告编号" width="220"></el-table-column>
      <el-table-column prop="orderdate" label="任务下发日期"></el-table-column>
      <el-table-column prop="mainpsn" label="主要检测人员"></el-table-column>
      <el-table-column prop="asspsn" label="协助检测人员"></el-table-column>
      <el-table-column prop="testtime" label="报告提交时间"></el-table-column>
      <el-table-column prop="examineman" label="审核人"></el-table-column>
      <el-table-column prop="examinetime" label="审核通过时间"></el-table-column>
      <el-table-column prop="confirmman" label="批准人"></el-table-column>
      <el-table-column prop="confirmtime" label="批准通过时间"></el-table-column>
    </el-table>
    <!--分页器 -->
    <div class="block" style="margin-top: 15px;margin-right: 30px;">
      <el-pagination align="right" @size-change='handleSizeChange' @current-change='handleCurrentChange'
        :current-page="queryInfo.pagenum" :page-size="queryInfo.pagesize" layout="prev, pager, next,total" background
        :total="total" hide-on-single-page>
      </el-pagination>
    </div>
    <!-- 弹出层表单 -->
    <el-dialog :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="项目名称：">
          <el-input v-model="form.entryname" placeholder="请输入项目名称(可模糊搜索)"></el-input>
        </el-form-item>
        <el-form-item label="报告编号：">
          <el-input v-model="form.trptno" placeholder="请输入报告编号"></el-input>
        </el-form-item>
        <el-form-item label="主要检测人员：">
          <el-input v-model="form.mainpsn" placeholder="请输入主要检测人员"></el-input>
        </el-form-item>
        <el-form-item label="审核人：">
          <el-input v-model="form.examineman" placeholder="请输入审核人"></el-input>
        </el-form-item>
        <el-form-item label=" 批准人：">
          <el-input v-model="form.confirmman" placeholder="请输入批准人"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">搜索</el-button>
          <el-button @click="cancel">取消</el-button>
          <el-button @click="resetForm" type="warning">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template> 
<script>
import Menu from '@/components/Menu.vue';
export default {
  data() {
    return {
      form: {
        entryname: '',
        trptno: '',
        mainpsn: '',
        examineman: '',
        confirmman: '',
      },
      dialogFormVisible: false, //表单隐藏
      tableData: [],
      total: 0, //总条数
      queryInfo: {
        pagenum: 1,
        pagesize: 10
      }
    };
  },
  components: {
    Menu
  },
  mounted() {
    this.dataRequest()
  },
  methods: {
    dataRequest() {
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport',
        params: {
          entryname: this.form.entryname,
          trptno: this.form.trptno,
          mainpsn: this.form.mainpsn,
          examineman: this.form.examineman,
          confirmman: this.form.confirmman,
          current: this.queryInfo.pagenum,
          size: this.queryInfo.pagesize
        }
      }).then(res => {
        console.log(res.data)
        this.tableData = res.data.rows
        this.total = res.data.total
      }).catch(error => {
        console.log(error)
      })
    },

    search() {//筛选按钮弹出悬浮层
      this.dialogFormVisible = true
    },
    onSubmit() {
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport',
        params: {
          entryname: this.form.entryname,
          trptno: this.form.trptno,
          mainpsn: this.form.mainpsn,
          examineman: this.form.examineman,
          confirmman: this.form.confirmman,
          current: this.queryInfo.pagenum,
          size: this.queryInfo.pagesize
        }
      }).then(res => {
        console.log(res.data)
        this.tableData = res.data.rows
        this.total = res.data.total
        console.log(this.tableData)
      }).catch(error => {
        console.log(error)
      })
      this.dialogFormVisible = false //关闭弹出层
    },
    handleSizeChange(size) {
      this.queryInfo.pagesize = size
      this.dataRequest()
    },
    handleCurrentChange(num) {
      this.queryInfo.pagenum = num
      this.dataRequest()
    },
    cancel() {//取消
      this.dialogFormVisible = false
    },
    resetForm() { //重置
      Object.assign(this.$data.form, this.$options.data().form)
    }
  },

};
</script>
<style  scoped> #head {
   margin-left: 40px;
   padding: 10px;
 }

 #btn {
   margin-left: 10px;
 }
</style>