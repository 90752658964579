/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:35:07 
 * @Last Modified by: 王翎爆
 * @Last Modified time: 2023-07-18 17:42:44
 */
<template>
    <div>
        <div id="printSubrgionbody">
            <div id="ftable">
                <div id="fqtotal">
                    <div id="fq">
                        <p>防雷分区</p>
                    </div>
                    <div id="fqcenter">
                        <p>{{ SubrgionName.areaName }}({{ SubrgionName.areaCode }}),等电位连接测试基准点：{{
                            SubrgionName.eqConnectDatumPoint }}</p>
                    </div>
                </div>
            </div>
            <el-table3 :data="changeData" style="width: 200mm; margin-left: 19px;color: black; font-family: 'song';"
                       ref="table" :header-cell-style="{ 'text-align': 'center' }" :row-style="{ height: '8' }"
                       :cell-style="{ padding: '0' }" class="eltable">
                <el-table-column prop="point" label="检测点" className="my-cell" width="200px">
                </el-table-column>
                <el-table-column prop="project" label="检测项目" width="190px">
                </el-table-column>
                <el-table-column prop="paramet" label="检测参数" width="186px">
                </el-table-column>
                <el-table-column  label="检测结果" width="180px">
                    <!-- <template slot-scope="scope">
                        <div id="spdImg" key="result" v-if="scope.row.type == 'photo'">
                            <el-image id="img" style="width: 35mm; height: 35mm" :src="scope.row.imgurl"
                                      :preview-src-list="urlList"></el-image>
                        </div>
                        <span v-else>{{ scope.row.result }}</span>
                    </template> -->
                </el-table-column>
            </el-table3>
        </div>
    </div>
</template>
<script>
export default {
    name: 'printReportSubrgion',
    props: ['SubrgionName'],
    data() {
        return {
            status: true,
            spanArr: [],
            pos: null,
            mark: '',
            changeData: [],
            backesdorse: '',
            inputIndex: '',
            urlList: [],
            imgStatus: false
        };
    },
    components: {
    },
    mounted() {
        this.changeDataForm()
        // this.originNoImg()
    },
    methods: {
        //传递无图片加载
        // originNoImg() {
        //     if (this.imgStatus == false) {
        //         console.log(99999);
        //         this.$bus.$emit('originnoimg', '无图片加载')
        //     }
        // },
        //传递图片是否加载完成
        // imgInit() {
        //     console.log(88888);
        //     this.imgStatus = true
        //     this.$bus.$emit('imgstate', '完成加载')
        // },
        //单元格合并
        getSpanArr(data) {
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr.push(1);
                    this.pos = 0
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].point === data[i - 1].point) {
                        this.spanArr[this.pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.pos = i;
                    }
                }
                // console.log(this.spanArr)
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0 || columnIndex === 1) {
                const _row = this.spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                // console.log(`rowspan:${_row} colspan:${_col}`)
                return { // [0,0] 表示这一行不显示,被合并了  [2,1]表示行的合并数
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        // 将检测结果和标准值的字符串类型改为数值类型
        changeType(result, standard) {
            // console.log(standard);
            var changeResult = result.match(/\d+(\.\d+)?/g)
            changeResult = changeResult.map(Number)
            changeResult = changeResult[0]
            if (standard) {
                var changeStandard = standard.match(/\d+(\.\d+)?/g)
                changeStandard = changeStandard.map(Number)
                changeStandard = changeStandard[0]
            }
            return { changeResult, changeStandard }
        },
        //分区数据格式转换
        changeDataForm() {
            // console.log('分区数据：',this.SubrgionName.sourceDataList);
            //接闪器相关数据
            for (let i = 0; i < this.SubrgionName.sourceDataList.length; i++) {
                let subregion = this.SubrgionName.sourceDataList[i]
                let downlead = this.SubrgionName.sourceDataList[i]
                let earthing = this.SubrgionName.sourceDataList[i]
                let strike = this.SubrgionName.sourceDataList[i]
                let powersurge = this.SubrgionName.sourceDataList[i]
                let equipotential = this.SubrgionName.sourceDataList[i]
                if (subregion.airTerminationType && subregion.testProject == '接闪器' && subregion.airTerminationType != '请选择') {
                    const airTerminationType = {}
                    airTerminationType.point = subregion.testPosition
                    airTerminationType.project = subregion.testProject
                    airTerminationType.paramet = '类型'
                    airTerminationType.result = subregion.airTerminationType
                    airTerminationType.time = subregion.createTime
                    this.changeData.push(airTerminationType)
                }
                if (subregion.layingMethod && subregion.testProject == '接闪器' && subregion.layingMethod != '请选择') {
                    const layingMethod = {}
                    layingMethod.point = subregion.testPosition
                    layingMethod.project = subregion.testProject
                    layingMethod.paramet = '敷设方式'
                    layingMethod.result = subregion.layingMethod
                    layingMethod.time = subregion.createTime
                    this.changeData.push(layingMethod)
                }
                if (subregion.material && subregion.testProject == '接闪器') {
                    const material = {}
                    material.point = subregion.testPosition
                    material.project = subregion.testProject
                    material.paramet = '材料'
                    material.result = subregion.material
                    material.time = subregion.createTime
                    this.changeData.push(material)
                }
                if (subregion.specification && subregion.testProject == '接闪器') {
                    const specification = {}
                    specification.point = subregion.testPosition
                    specification.project = subregion.testProject
                    specification.paramet = '规格(mm)'
                    specification.result = subregion.specification
                    specification.time = subregion.createTime
                    this.changeData.push(specification)
                }
                if (subregion.installationMethod && subregion.testProject == '接闪器' && subregion.installationMethod != '请选择') {
                    const installationMethod = {}
                    installationMethod.point = subregion.testPosition
                    installationMethod.project = subregion.testProject
                    installationMethod.paramet = '安装方式'
                    installationMethod.result = subregion.installationMethod
                    installationMethod.time = subregion.createTime
                    this.changeData.push(installationMethod)
                }
                if (subregion.bracketDistance && subregion.testProject == '接闪器') {
                    const bracketDistance = {}
                    bracketDistance.point = subregion.testPosition
                    bracketDistance.project = subregion.testProject
                    bracketDistance.paramet = '固定支架间距(m)'
                    bracketDistance.result = subregion.bracketDistance
                    bracketDistance.time = subregion.createTime
                    this.changeData.push(bracketDistance)
                }
                if (subregion.bracketHeight && subregion.testProject == '接闪器') {
                    const bracketHeight = {}
                    bracketHeight.point = subregion.testPosition
                    bracketHeight.project = subregion.testProject
                    bracketHeight.paramet = '固定支架高度(mm)'
                    bracketHeight.result = subregion.bracketHeight
                    bracketHeight.time = subregion.createTime
                    this.changeData.push(bracketHeight)
                }
                if (subregion.verticalPull && subregion.testProject == '接闪器') {
                    const verticalPull = {}
                    verticalPull.point = subregion.testPosition
                    verticalPull.project = subregion.testProject
                    verticalPull.paramet = '网带支持件承受垂直拉力(N)'
                    verticalPull.result = subregion.verticalPull
                    verticalPull.time = subregion.createTime
                    this.changeData.push(verticalPull)
                }
                if (subregion.gridSize && subregion.testProject == '接闪器') {
                    const gridSize = {}
                    gridSize.point = subregion.testPosition
                    gridSize.project = subregion.testProject
                    gridSize.paramet = '网格尺寸(m)'
                    gridSize.result = subregion.gridSize
                    gridSize.time = subregion.createTime
                    this.changeData.push(gridSize)
                }
                if (subregion.welding && subregion.testProject == '接闪器' && subregion.welding != '请选择') {
                    const welding = {}
                    welding.point = subregion.testPosition
                    welding.project = subregion.testProject
                    welding.paramet = '焊接固定'
                    welding.result = subregion.welding
                    welding.time = subregion.createTime
                    this.changeData.push(welding)
                }
                if (subregion.antiCorrosionMeasures && subregion.testProject == '接闪器' && subregion.antiCorrosionMeasures != '请选择') {
                    const antiCorrosionMeasures = {}
                    antiCorrosionMeasures.point = subregion.testPosition
                    antiCorrosionMeasures.project = subregion.testProject
                    antiCorrosionMeasures.paramet = '防腐措施'
                    antiCorrosionMeasures.result = subregion.antiCorrosionMeasures
                    antiCorrosionMeasures.time = subregion.createTime
                    this.changeData.push(antiCorrosionMeasures)
                }
                if (subregion.rustCondition && subregion.testProject == '接闪器' && subregion.rustCondition != '请选择') {
                    const rustCondition = {}
                    rustCondition.point = subregion.testPosition
                    rustCondition.project = subregion.testProject
                    rustCondition.paramet = '腐蚀状况'
                    rustCondition.result = subregion.rustCondition
                    rustCondition.time = subregion.createTime
                    this.changeData.push(rustCondition)
                }
                if (subregion.connectionWithDownConductors && subregion.testProject == '接闪器') {
                    const connectionWithDownConductors = {}
                    connectionWithDownConductors.point = subregion.testPosition
                    connectionWithDownConductors.project = subregion.testProject
                    connectionWithDownConductors.paramet = '与引下线的电气连接(Ω)'
                    connectionWithDownConductors.result = subregion.connectionWithDownConductors
                    connectionWithDownConductors.time = subregion.createTime
                    this.changeData.push(connectionWithDownConductors)
                }
                if (subregion.equipotentialBondingOfRoof && subregion.testProject == '接闪器') {
                    const equipotentialBondingOfRoof = {}
                    equipotentialBondingOfRoof.point = subregion.testPosition
                    equipotentialBondingOfRoof.project = subregion.testProject
                    equipotentialBondingOfRoof.paramet = '屋面设施的等电位连接(Ω)'
                    equipotentialBondingOfRoof.result = subregion.equipotentialBondingOfRoof
                    equipotentialBondingOfRoof.time = subregion.createTime
                    this.changeData.push(equipotentialBondingOfRoof)
                }
                if (subregion.otherMetalConnections && subregion.testProject == '接闪器') {
                    const otherMetalConnections = {}
                    otherMetalConnections.point = subregion.testPosition
                    otherMetalConnections.project = subregion.testProject
                    otherMetalConnections.paramet = '与建筑物顶部外露的其他金属物的电气连接(Ω)'
                    otherMetalConnections.result = subregion.otherMetalConnections
                    otherMetalConnections.time = subregion.createTime
                    this.changeData.push(otherMetalConnections)
                }
                if (subregion.otherLines && subregion.testProject == '接闪器' && subregion.otherLines != '请选择') {
                    const otherLines = {}
                    otherLines.point = subregion.testPosition
                    otherLines.project = subregion.testProject
                    otherLines.paramet = '附着的其他电气线路'
                    otherLines.result = subregion.otherLines
                    otherLines.time = subregion.createTime
                    this.changeData.push(otherLines)
                }
                if (subregion.earthResistance && subregion.testProject == '接闪器') {
                    const earthResistance = {}
                    earthResistance.point = subregion.testPosition
                    earthResistance.project = subregion.testProject
                    earthResistance.paramet = '接地电阻(Ω)'
                    earthResistance.result = subregion.earthResistance
                    earthResistance.time = subregion.createTime
                    this.changeData.push(earthResistance)
                }
                if (subregion.structure && subregion.testProject == '接闪器') {
                    const structure = {}
                    structure.point = subregion.testPosition
                    structure.project = subregion.testProject
                    structure.paramet = '结构'
                    structure.result = subregion.structure
                    structure.time = subregion.createTime
                    this.changeData.push(structure)
                } if (subregion.minSection && subregion.testProject == '接闪器') {
                    const minSection = {}
                    minSection.point = subregion.testPosition
                    minSection.project = subregion.testProject
                    minSection.paramet = '最小截面(mm²)'
                    minSection.result = subregion.minSection
                    minSection.time = subregion.createTime
                    this.changeData.push(minSection)
                } if (subregion.protectedRange && subregion.testProject == '接闪器') {
                    const protectedRange = {}
                    protectedRange.point = subregion.testPosition
                    protectedRange.project = subregion.testProject
                    protectedRange.paramet = '保护范围(m)'
                    protectedRange.result = subregion.protectedRange
                    protectedRange.time = subregion.createTime
                    this.changeData.push(protectedRange)
                } if (subregion.distanceToProtectedObject && subregion.testProject == '接闪器') {
                    const distanceToProtectedObject = {}
                    distanceToProtectedObject.point = subregion.testPosition
                    distanceToProtectedObject.project = subregion.testProject
                    distanceToProtectedObject.paramet = '距被保护物的距离(m)'
                    distanceToProtectedObject.result = subregion.distanceToProtectedObject
                    distanceToProtectedObject.time = subregion.createTime
                    this.changeData.push(distanceToProtectedObject)
                } if (subregion.protectionAgainstFlanking && subregion.testProject == '接闪器' && subregion.protectionAgainstFlanking != '请选择') {
                    const protectionAgainstFlanking = {}
                    protectionAgainstFlanking.point = subregion.testPosition
                    protectionAgainstFlanking.project = subregion.testProject
                    protectionAgainstFlanking.paramet = '防侧击保护措施'
                    protectionAgainstFlanking.result = subregion.protectionAgainstFlanking
                    protectionAgainstFlanking.time = subregion.createTime
                    this.changeData.push(protectionAgainstFlanking)
                } if (subregion.treeDistanceFromBuilding && subregion.testProject == '接闪器') {
                    const treeDistanceFromBuilding = {}
                    treeDistanceFromBuilding.point = subregion.testPosition
                    treeDistanceFromBuilding.project = subregion.testProject
                    treeDistanceFromBuilding.paramet = '树木距建筑物距离(m)'
                    treeDistanceFromBuilding.result = subregion.treeDistanceFromBuilding
                    treeDistanceFromBuilding.time = subregion.createTime
                    this.changeData.push(treeDistanceFromBuilding)
                } if (subregion.chimneyAirTermination && subregion.testProject == '接闪器') {
                    const chimneyAirTermination = {}
                    chimneyAirTermination.point = subregion.testPosition
                    chimneyAirTermination.project = subregion.testProject
                    chimneyAirTermination.paramet = '烟囱的接闪器'
                    chimneyAirTermination.result = subregion.chimneyAirTermination
                    chimneyAirTermination.time = subregion.createTime
                    this.changeData.push(chimneyAirTermination)
                } if (subregion.lightningSurgeIncoming && subregion.testProject == '接闪器') {
                    const lightningSurgeIncoming = {}
                    lightningSurgeIncoming.point = subregion.testPosition
                    lightningSurgeIncoming.project = subregion.testProject
                    lightningSurgeIncoming.paramet = '外部设备、线路防闪电电涌侵入措施'
                    lightningSurgeIncoming.result = subregion.lightningSurgeIncoming
                    lightningSurgeIncoming.time = subregion.createTime
                    this.changeData.push(lightningSurgeIncoming)
                }
                //引下线相关数据
                if (downlead.layingMethod && downlead.testProject == '引下线' && downlead.layingMethod != '请选择') {
                    const downleadlayingMethod = {}
                    downleadlayingMethod.point = downlead.testPosition
                    downleadlayingMethod.project = downlead.testProject
                    downleadlayingMethod.paramet = '敷设方式'
                    downleadlayingMethod.result = downlead.layingMethod
                    downleadlayingMethod.time = downlead.createTime
                    this.changeData.push(downleadlayingMethod)
                }
                if (downlead.material && downlead.testProject == '引下线') {
                    const downleadmaterial = {}
                    downleadmaterial.point = downlead.testPosition
                    downleadmaterial.project = downlead.testProject
                    downleadmaterial.paramet = '材料'
                    downleadmaterial.result = downlead.material
                    downleadmaterial.time = downlead.createTime
                    this.changeData.push(downleadmaterial)
                }
                if (downlead.specification && downlead.testProject == '引下线') {
                    const downleadspecification = {}
                    downleadspecification.point = downlead.testPosition
                    downleadspecification.project = downlead.testProject
                    downleadspecification.paramet = '规格(mm)'
                    downleadspecification.result = downlead.specification
                    downleadspecification.time = downlead.createTime
                    this.changeData.push(downleadspecification)
                }
                if (downlead.quantity && downlead.testProject == '引下线') {
                    const downleadquantity = {}
                    downleadquantity.point = downlead.testPosition
                    downleadquantity.project = downlead.testProject
                    downleadquantity.paramet = '数量'
                    downleadquantity.result = downlead.quantity
                    downleadquantity.time = downlead.createTime
                    this.changeData.push(downleadquantity)
                }
                if (downlead.averageSpacing && downlead.testProject == '引下线') {
                    const downleadaverageSpacing = {}
                    downleadaverageSpacing.point = downlead.testPosition
                    downleadaverageSpacing.project = downlead.testProject
                    downleadaverageSpacing.paramet = '平均间距(m)'
                    downleadaverageSpacing.result = downlead.averageSpacing
                    downleadaverageSpacing.time = downlead.createTime
                    this.changeData.push(downleadaverageSpacing)
                }
                if (downlead.spacing && downlead.testProject == '引下线') {
                    const downleadspacing = {}
                    downleadspacing.point = downlead.testPosition
                    downleadspacing.project = downlead.testProject
                    downleadspacing.paramet = '间距(m)'
                    downleadspacing.result = downlead.spacing
                    downleadspacing.time = downlead.createTime
                    this.changeData.push(downleadspacing)
                }
                if (downlead.welding && downlead.testProject == '引下线' && downlead.welding != '请选择') {
                    const downleadwelding = {}
                    downleadwelding.point = downlead.testPosition
                    downleadwelding.project = downlead.testProject
                    downleadwelding.paramet = '焊接固定'
                    downleadwelding.result = downlead.welding
                    downleadwelding.time = downlead.createTime
                    this.changeData.push(downleadwelding)
                }
                if (downlead.antiCorrosionMeasures && downlead.testProject == '引下线' && downlead.antiCorrosionMeasures != '请选择') {
                    const downleadantiCorrosionMeasures = {}
                    downleadantiCorrosionMeasures.point = downlead.testPosition
                    downleadantiCorrosionMeasures.project = downlead.testProject
                    downleadantiCorrosionMeasures.paramet = '防腐措施'
                    downleadantiCorrosionMeasures.result = downlead.antiCorrosionMeasures
                    downleadantiCorrosionMeasures.time = downlead.createTime
                    this.changeData.push(downleadantiCorrosionMeasures)
                }
                if (downlead.rustCondition && downlead.testProject == '引下线' && downlead.rustCondition != '请选择') {
                    const downleadrustCondition = {}
                    downleadrustCondition.point = downlead.testPosition
                    downleadrustCondition.project = downlead.testProject
                    downleadrustCondition.paramet = '腐蚀状况'
                    downleadrustCondition.result = downlead.rustCondition
                    downleadrustCondition.time = downlead.createTime
                    this.changeData.push(downleadrustCondition)
                }
                if (downlead.transitionWithAt && downlead.testProject == '引下线') {
                    const downleadtransitionWithAt = {}
                    downleadtransitionWithAt.point = downlead.testPosition
                    downleadtransitionWithAt.project = downlead.testProject
                    downleadtransitionWithAt.paramet = '与接闪器的连接过渡电阻(Ω)'
                    downleadtransitionWithAt.result = downlead.transitionWithAt
                    downleadtransitionWithAt.time = downlead.createTime
                    this.changeData.push(downleadtransitionWithAt)
                }
                if (downlead.transitionWithEarthEiectrode && downlead.testProject == '引下线') {
                    const downleadtransitionWithEarthEiectrode = {}
                    downleadtransitionWithEarthEiectrode.point = downlead.testPosition
                    downleadtransitionWithEarthEiectrode.project = downlead.testProject
                    downleadtransitionWithEarthEiectrode.paramet = '与接地体的连接过渡电阻(Ω)'
                    downleadtransitionWithEarthEiectrode.result = downlead.transitionWithEarthEiectrode
                    downleadtransitionWithEarthEiectrode.time = downlead.createTime
                    this.changeData.push(downleadtransitionWithEarthEiectrode)
                }
                if (downlead.earthResistance && downlead.testProject == '引下线') {
                    const downleadearthResistance = {}
                    downleadearthResistance.point = downlead.testPosition
                    downleadearthResistance.project = downlead.testProject
                    downleadearthResistance.paramet = '接地电阻(Ω)'
                    downleadearthResistance.result = downlead.earthResistance
                    downleadearthResistance.time = downlead.createTime
                    this.changeData.push(downleadearthResistance)
                }
                if (downlead.bracketDistance && downlead.testProject == '引下线') {
                    const downleadbracketDistance = {}
                    downleadbracketDistance.point = downlead.testPosition
                    downleadbracketDistance.project = downlead.testProject
                    downleadbracketDistance.paramet = '固定支架间距(m)'
                    downleadbracketDistance.result = downlead.bracketDistance
                    downleadbracketDistance.time = downlead.createTime
                    this.changeData.push(downleadbracketDistance)
                }
                if (downlead.bracketHeight && downlead.testProject == '引下线') {
                    const downleadbracketHeight = {}
                    downleadbracketHeight.point = downlead.testPosition
                    downleadbracketHeight.project = downlead.testProject
                    downleadbracketHeight.paramet = '固定支架高度(mm)'
                    downleadbracketHeight.result = downlead.bracketHeight
                    downleadbracketHeight.time = downlead.createTime
                    this.changeData.push(downleadbracketHeight)
                }
                if (downlead.verticalPull && downlead.testProject == '引下线') {
                    const downleadverticalPull = {}
                    downleadverticalPull.point = downlead.testPosition
                    downleadverticalPull.project = downlead.testProject
                    downleadverticalPull.paramet = '网带支持件承受垂直拉力(N)'
                    downleadverticalPull.result = downlead.verticalPull
                    downleadverticalPull.time = downlead.createTime
                    this.changeData.push(downleadverticalPull)
                }
                if (downlead.otherLines && downlead.testProject == '引下线' && downlead.otherLines != '请选择') {
                    const downleadotherLines = {}
                    downleadotherLines.point = downlead.testPosition
                    downleadotherLines.project = downlead.testProject
                    downleadotherLines.paramet = '附着的其他电气线路'
                    downleadotherLines.result = downlead.otherLines
                    downleadotherLines.time = downlead.createTime
                    this.changeData.push(downleadotherLines)
                }
                if (downlead.minDistance && downlead.testProject == '引下线') {
                    const downleadminDistance = {}
                    downleadminDistance.point = downlead.testPosition
                    downleadminDistance.project = downlead.testProject
                    downleadminDistance.paramet = '与电气和电子线路最小距离(m)'
                    downleadminDistance.result = downlead.minDistance
                    downleadminDistance.time = downlead.createTime
                    this.changeData.push(downleadminDistance)
                }
                if (downlead.distanceToInsulation && downlead.testProject == '引下线') {
                    const downleaddistanceToInsulation = {}
                    downleaddistanceToInsulation.point = downlead.testPosition
                    downleaddistanceToInsulation.project = downlead.testProject
                    downleaddistanceToInsulation.paramet = '与保温层间距(m)'
                    downleaddistanceToInsulation.result = downlead.distanceToInsulation
                    downleaddistanceToInsulation.time = downlead.createTime
                    this.changeData.push(downleaddistanceToInsulation)
                }
                if (downlead.mechanicalProtectionMeasures && downlead.testProject == '引下线' && downlead.mechanicalProtectionMeasures != '请选择') {
                    const downleadmechanicalProtectionMeasures = {}
                    downleadmechanicalProtectionMeasures.point = downlead.testPosition
                    downleadmechanicalProtectionMeasures.project = downlead.testProject
                    downleadmechanicalProtectionMeasures.paramet = '受机械损伤保护措施'
                    downleadmechanicalProtectionMeasures.result = downlead.mechanicalProtectionMeasures
                    downleadmechanicalProtectionMeasures.time = downlead.createTime
                    this.changeData.push(downleadmechanicalProtectionMeasures)
                }
                if (downlead.distanceFromEntrance && downlead.testProject == '引下线') {
                    const downleaddistanceFromEntrance = {}
                    downleaddistanceFromEntrance.point = downlead.testPosition
                    downleaddistanceFromEntrance.project = downlead.testProject
                    downleaddistanceFromEntrance.paramet = '距离出入口或人行道边沿距离(m)'
                    downleaddistanceFromEntrance.result = downlead.distanceFromEntrance
                    downleaddistanceFromEntrance.time = downlead.createTime
                    this.changeData.push(downleaddistanceFromEntrance)
                }
                //接地装置相关数据
                if (earthing.earthingDeviceCategory && earthing.testProject == '接地装置' && earthing.earthingDeviceCategory != '请选择') {
                    const earthingDeviceCategory = {}
                    earthingDeviceCategory.point = earthing.testPosition
                    earthingDeviceCategory.project = earthing.testProject
                    earthingDeviceCategory.paramet = '共用/独立接地'
                    earthingDeviceCategory.result = earthing.earthingDeviceCategory
                    earthingDeviceCategory.time = earthing.createTime
                    this.changeData.push(earthingDeviceCategory)
                }
                if (earthing.earthConductorConnectionSituation && earthing.testProject == '接地装置') {
                    const earthConductorConnectionSituation = {}
                    earthConductorConnectionSituation.point = earthing.testPosition
                    earthConductorConnectionSituation.project = earthing.testProject
                    earthConductorConnectionSituation.paramet = '相邻两接地装置电气导通(Ω)'
                    earthConductorConnectionSituation.result = earthing.earthConductorConnectionSituation
                    earthConductorConnectionSituation.time = earthing.createTime
                    this.changeData.push(earthConductorConnectionSituation)
                }
                if (earthing.distanceToTheProtectedObject && earthing.testProject == '接地装置') {
                    const distanceToTheProtectedObject = {}
                    distanceToTheProtectedObject.point = earthing.testPosition
                    distanceToTheProtectedObject.project = earthing.testProject
                    distanceToTheProtectedObject.paramet = '至被保护物及与其有联系的金属物的间隔距离(m)'
                    distanceToTheProtectedObject.result = earthing.distanceToTheProtectedObject
                    distanceToTheProtectedObject.time = earthing.createTime
                    this.changeData.push(distanceToTheProtectedObject)
                }
                if (earthing.earthResistance && earthing.testProject == '接地装置') {
                    const earthResistance = {}
                    earthResistance.point = earthing.testPosition
                    earthResistance.project = earthing.testProject
                    earthResistance.paramet = '接地电阻(Ω)'
                    earthResistance.result = earthing.earthResistance
                    earthResistance.time = earthing.createTime
                    this.changeData.push(earthResistance)
                }
                if (earthing.materialNatural && earthing.testProject == '接地装置') {
                    const materialNatural = {}
                    materialNatural.point = earthing.testPosition
                    materialNatural.project = earthing.testProject
                    materialNatural.paramet = '自然接地体的材料'
                    materialNatural.result = earthing.materialNatural
                    materialNatural.time = earthing.createTime
                    this.changeData.push(materialNatural)
                }
                if (earthing.specificationNatural && earthing.testProject == '接地装置') {
                    const specificationNatural = {}
                    specificationNatural.point = earthing.testPosition
                    specificationNatural.project = earthing.testProject
                    specificationNatural.paramet = '自然接地体的规格'
                    specificationNatural.result = earthing.specificationNatural
                    specificationNatural.time = earthing.createTime
                    this.changeData.push(specificationNatural)
                }
                if (earthing.materialArtificial && earthing.testProject == '接地装置') {
                    const materialArtificial = {}
                    materialArtificial.point = earthing.testPosition
                    materialArtificial.project = earthing.testProject
                    materialArtificial.paramet = '人工接地体的材料'
                    materialArtificial.result = earthing.materialArtificial
                    materialArtificial.time = earthing.createTime
                    this.changeData.push(materialArtificial)
                }
                if (earthing.specificationArtificial && earthing.testProject == '接地装置') {
                    const specificationArtificial = {}
                    specificationArtificial.point = earthing.testPosition
                    specificationArtificial.project = earthing.testProject
                    specificationArtificial.paramet = '人工接地体的规格'
                    specificationArtificial.result = earthing.specificationArtificial
                    specificationArtificial.time = earthing.createTime
                    this.changeData.push(specificationArtificial)
                }
                if (earthing.buriedDepth && earthing.testProject == '接地装置') {
                    const buriedDepth = {}
                    buriedDepth.point = earthing.testPosition
                    buriedDepth.project = earthing.testProject
                    buriedDepth.paramet = '人工接地体的埋设深度(m)'
                    buriedDepth.result = earthing.buriedDepth
                    buriedDepth.time = earthing.createTime
                    this.changeData.push(buriedDepth)
                }
                if (earthing.earthElectrodeSpacing && earthing.testProject == '接地装置') {
                    const earthElectrodeSpacing = {}
                    earthElectrodeSpacing.point = earthing.testPosition
                    earthElectrodeSpacing.project = earthing.testProject
                    earthElectrodeSpacing.paramet = '人工接地体的间距(m)'
                    earthElectrodeSpacing.result = earthing.earthElectrodeSpacing
                    earthElectrodeSpacing.time = earthing.createTime
                    this.changeData.push(earthElectrodeSpacing)
                }
                if (earthing.soilResistivity && earthing.testProject == '接地装置') {
                    const soilResistivity = {}
                    soilResistivity.point = earthing.testPosition
                    soilResistivity.project = earthing.testProject
                    soilResistivity.paramet = '土壤电阻率(Ωm)'
                    soilResistivity.result = earthing.soilResistivity
                    soilResistivity.time = earthing.createTime
                    this.changeData.push(soilResistivity)
                }
                if (earthing.intervalDistance && earthing.testProject == '接地装置') {
                    const intervalDistance = {}
                    intervalDistance.point = earthing.testPosition
                    intervalDistance.project = earthing.testProject
                    intervalDistance.paramet = '间隔距离(m)'
                    intervalDistance.result = earthing.intervalDistance
                    intervalDistance.time = earthing.createTime
                    this.changeData.push(intervalDistance)
                }
                if (earthing.antiCorrosionMeasures && earthing.testProject == '接地装置' && earthing.antiCorrosionMeasures != '请选择') {
                    const antiCorrosionMeasures = {}
                    antiCorrosionMeasures.point = earthing.testPosition
                    antiCorrosionMeasures.project = earthing.testProject
                    antiCorrosionMeasures.paramet = '防腐措施'
                    antiCorrosionMeasures.result = earthing.antiCorrosionMeasures
                    antiCorrosionMeasures.time = earthing.createTime
                    this.changeData.push(antiCorrosionMeasures)
                }
                if (earthing.rustCondition && earthing.testProject == '接地装置' && earthing.rustCondition != '请选择') {
                    const rustCondition = {}
                    rustCondition.point = earthing.testPosition
                    rustCondition.project = earthing.testProject
                    rustCondition.paramet = '腐蚀状况'
                    rustCondition.result = earthing.rustCondition
                    rustCondition.time = earthing.createTime
                    this.changeData.push(rustCondition)
                }
                if (earthing.connectionMethod && earthing.testProject == '接地装置') {
                    const connectionMethod = {}
                    connectionMethod.point = earthing.testPosition
                    connectionMethod.project = earthing.testProject
                    connectionMethod.paramet = '连接方式'
                    connectionMethod.result = earthing.connectionMethod
                    connectionMethod.time = earthing.createTime
                    this.changeData.push(connectionMethod)
                }
                //等电位连接相关数据
                if (equipotential.installationLocation && equipotential.testProject == '等电位连接') {
                    const installationLocation = {}
                    installationLocation.point = equipotential.testPosition
                    installationLocation.project = equipotential.testProject
                    installationLocation.paramet = '等电位连接设施安放位置'
                    installationLocation.result = equipotential.installationLocation
                    installationLocation.time = equipotential.createTime
                    this.changeData.push(installationLocation)
                }
                if (equipotential.lebMaterial && equipotential.testProject == '等电位连接') {
                    const lebMaterial = {}
                    lebMaterial.point = equipotential.testPosition
                    lebMaterial.project = equipotential.testProject
                    lebMaterial.paramet = '等电位连接导体材料'
                    lebMaterial.result = equipotential.lebMaterial
                    lebMaterial.time = equipotential.createTime
                    this.changeData.push(lebMaterial)
                }
                if (equipotential.lebSpecification && equipotential.testProject == '等电位连接') {
                    const lebSpecification = {}
                    lebSpecification.point = equipotential.testPosition
                    lebSpecification.project = equipotential.testProject
                    lebSpecification.paramet = '等电位连接导体规格尺寸'
                    lebSpecification.result = equipotential.lebSpecification
                    lebSpecification.time = equipotential.createTime
                    this.changeData.push(lebSpecification)
                }
                if (equipotential.lebEarthResistance && equipotential.testProject == '等电位连接') {
                    const lebEarthResistance = {}
                    lebEarthResistance.point = equipotential.testPosition
                    lebEarthResistance.project = equipotential.testProject
                    lebEarthResistance.paramet = '等电位连接设施接地电阻(Ω)'
                    lebEarthResistance.result = equipotential.lebEarthResistance
                    lebEarthResistance.time = equipotential.createTime
                    this.changeData.push(lebEarthResistance)
                }
                if (equipotential.equipotentialForm && equipotential.testProject == '等电位连接' && equipotential.equipotentialForm != '请选择') {
                    const equipotentialForm = {}
                    equipotentialForm.point = equipotential.testPosition
                    equipotentialForm.project = equipotential.testProject
                    equipotentialForm.paramet = '电子设备等电位连接基本形式'
                    equipotentialForm.result = equipotential.equipotentialForm
                    equipotentialForm.time = equipotential.createTime
                    this.changeData.push(equipotentialForm)
                }
                if (equipotential.transitionResistor && equipotential.testProject == '等电位连接') {
                    const transitionResistor = {}
                    transitionResistor.point = equipotential.testPosition
                    transitionResistor.project = equipotential.testProject
                    if(equipotential.transitionResistorUnit == 'mΩ'){
                        transitionResistor.paramet = '等电位连接过渡电阻(mΩ)'
                    }else{
                        transitionResistor.paramet = '等电位连接过渡电阻(Ω)'
                    }
                    transitionResistor.result = equipotential.transitionResistor
                    transitionResistor.time = equipotential.createTime
                    this.changeData.push(transitionResistor)
                }
                //雷击电磁脉冲屏蔽相关数据
                if (strike.largeSizeMetalConnection && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const largeSizeMetalConnection = {}
                    largeSizeMetalConnection.point = strike.testPosition
                    largeSizeMetalConnection.project = strike.testProject
                    largeSizeMetalConnection.paramet = '大尺寸金属件等电位连接(Ω)'
                    largeSizeMetalConnection.result = strike.largeSizeMetalConnection
                    largeSizeMetalConnection.time = strike.createTime
                    this.changeData.push(largeSizeMetalConnection)
                }
                if (strike.largeSizeMetalEarthResistance && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const largeSizeMetalEarthResistance = {}
                    largeSizeMetalEarthResistance.point = strike.testPosition
                    largeSizeMetalEarthResistance.project = strike.testProject
                    largeSizeMetalEarthResistance.paramet = '大尺寸金属件接地电阻(Ω)'
                    largeSizeMetalEarthResistance.result = strike.largeSizeMetalEarthResistance
                    largeSizeMetalEarthResistance.time = strike.createTime
                    this.changeData.push(largeSizeMetalEarthResistance)
                }
                if (strike.shieldGroundedAtBothEnds && strike.testProject == '雷击电磁脉冲屏蔽' && strike.shieldGroundedAtBothEnds != '请选择') {
                    const shieldGroundedAtBothEnds = {}
                    shieldGroundedAtBothEnds.point = strike.testPosition
                    shieldGroundedAtBothEnds.project = strike.testProject
                    shieldGroundedAtBothEnds.paramet = '金属屏蔽层两端接地'
                    shieldGroundedAtBothEnds.result = strike.shieldGroundedAtBothEnds
                    shieldGroundedAtBothEnds.time = strike.createTime
                    this.changeData.push(shieldGroundedAtBothEnds)
                }
                if (strike.transitionResistanceOfMetalObjects && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const transitionResistanceOfMetalObjects = {}
                    transitionResistanceOfMetalObjects.point = strike.testPosition
                    transitionResistanceOfMetalObjects.project = strike.testProject
                    transitionResistanceOfMetalObjects.paramet = '和所连接金属物的等电位连接过渡电阻(Ω)'
                    transitionResistanceOfMetalObjects.result = strike.transitionResistanceOfMetalObjects
                    transitionResistanceOfMetalObjects.time = strike.createTime
                    this.changeData.push(transitionResistanceOfMetalObjects)
                }
                if (strike.shieldedCableEarthResistance && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const shieldedCableEarthResistance = {}
                    shieldedCableEarthResistance.point = strike.testPosition
                    shieldedCableEarthResistance.project = strike.testProject
                    shieldedCableEarthResistance.paramet = '屏蔽电缆接地电阻(Ω)'
                    shieldedCableEarthResistance.result = strike.shieldedCableEarthResistance
                    shieldedCableEarthResistance.time = strike.createTime
                    this.changeData.push(shieldedCableEarthResistance)
                }
                if (strike.oneEndOfShield && strike.testProject == '雷击电磁脉冲屏蔽' && strike.oneEndOfShield != '请选择') {
                    const oneEndOfShield = {}
                    oneEndOfShield.point = strike.testPosition
                    oneEndOfShield.project = strike.testProject
                    oneEndOfShield.paramet = '金属屏蔽层一端接地'
                    oneEndOfShield.result = strike.oneEndOfShield
                    oneEndOfShield.time = strike.createTime
                    this.changeData.push(oneEndOfShield)
                }
                if (strike.doubleShielding && strike.testProject == '雷击电磁脉冲屏蔽' && strike.doubleShielding != '请选择') {
                    const doubleShielding = {}
                    doubleShielding.point = strike.testPosition
                    doubleShielding.project = strike.testProject
                    doubleShielding.paramet = '双层屏蔽'
                    doubleShielding.result = strike.doubleShielding
                    doubleShielding.time = strike.createTime
                    this.changeData.push(doubleShielding)
                }
                if (strike.protectorGroundedAtBothEnds && strike.testProject == '雷击电磁脉冲屏蔽' && strike.protectorGroundedAtBothEnds != '请选择') {
                    const protectorGroundedAtBothEnds = {}
                    protectorGroundedAtBothEnds.point = strike.testPosition
                    protectorGroundedAtBothEnds.project = strike.testProject
                    protectorGroundedAtBothEnds.paramet = '非屏蔽电缆的金属保护物两端接地'
                    protectorGroundedAtBothEnds.result = strike.protectorGroundedAtBothEnds
                    protectorGroundedAtBothEnds.time = strike.createTime
                    this.changeData.push(protectorGroundedAtBothEnds)
                }
                if (strike.shieldMaterial && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const shieldMaterial = {}
                    shieldMaterial.point = strike.testPosition
                    shieldMaterial.project = strike.testProject
                    shieldMaterial.paramet = '屏蔽材料'
                    shieldMaterial.result = strike.shieldMaterial
                    shieldMaterial.time = strike.createTime
                    this.changeData.push(shieldMaterial)
                }
                if (strike.shieldSpecification && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const shieldSpecification = {}
                    shieldSpecification.point = strike.testPosition
                    shieldSpecification.project = strike.testProject
                    shieldSpecification.paramet = '屏蔽材料规格尺寸'
                    shieldSpecification.result = strike.shieldSpecification
                    shieldSpecification.time = strike.createTime
                    this.changeData.push(shieldSpecification)
                }
                if (strike.electromagneticShieldingEffectiveness && strike.testProject == '雷击电磁脉冲屏蔽') {
                    const electromagneticShieldingEffectiveness = {}
                    electromagneticShieldingEffectiveness.point = strike.testPosition
                    electromagneticShieldingEffectiveness.project = strike.testProject
                    electromagneticShieldingEffectiveness.paramet = '电磁屏蔽效能'
                    electromagneticShieldingEffectiveness.result = strike.electromagneticShieldingEffectiveness
                    electromagneticShieldingEffectiveness.time = strike.createTime
                    this.changeData.push(electromagneticShieldingEffectiveness)
                }
                if (strike.metalEquipotentialConnection && strike.testProject == '雷击电磁脉冲屏蔽' && strike.metalEquipotentialConnection != '请选择') {
                    const metalEquipotentialConnection = {}
                    metalEquipotentialConnection.point = strike.testPosition
                    metalEquipotentialConnection.project = strike.testProject
                    metalEquipotentialConnection.paramet = '门窗、幕墙、屋面及立面金属表面、大尺寸金属构件等是否等电位连接'
                    metalEquipotentialConnection.result = strike.metalEquipotentialConnection
                    metalEquipotentialConnection.time = strike.createTime
                    this.changeData.push(metalEquipotentialConnection)
                }
                //电源电涌保护器相关数据
                if (powersurge.modelNumber && powersurge.testProject == '电源电涌保护器') {
                    const modelNumber = {}
                    modelNumber.point = powersurge.testPosition
                    modelNumber.project = powersurge.testProject
                    modelNumber.paramet = '型号'
                    modelNumber.result = powersurge.modelNumber
                    modelNumber.time = powersurge.createTime
                    this.changeData.push(modelNumber)
                }
                if (powersurge.outwardAppearance && powersurge.testProject == '电源电涌保护器' && powersurge.outwardAppearance != '请选择') {
                    const outwardAppearance = {}
                    outwardAppearance.point = powersurge.testPosition
                    outwardAppearance.project = powersurge.testProject
                    outwardAppearance.paramet = '外观'
                    outwardAppearance.result = powersurge.outwardAppearance
                    outwardAppearance.time = powersurge.createTime
                    this.changeData.push(outwardAppearance)
                }
                if (powersurge.level && powersurge.testProject == '电源电涌保护器' && powersurge.level != '请选择') {
                    const level = {}
                    level.point = powersurge.testPosition
                    level.project = powersurge.testProject
                    level.paramet = '级数'
                    level.result = powersurge.level
                    level.time = powersurge.createTime
                    this.changeData.push(level)
                }
                if (powersurge.testType && powersurge.testProject == '电源电涌保护器' && powersurge.testType != '请选择') {
                    const testType = {}
                    testType.point = powersurge.testPosition
                    testType.project = powersurge.testProject
                    testType.paramet = '试验类型'
                    testType.result = powersurge.testType
                    testType.time = powersurge.createTime
                    this.changeData.push(testType)
                }
                if (powersurge.backupProtection && powersurge.testProject == '电源电涌保护器' && powersurge.backupProtection != '请选择') {
                    const backupProtection = {}
                    backupProtection.point = powersurge.testPosition
                    backupProtection.project = powersurge.testProject
                    backupProtection.paramet = '后备保护装置'
                    backupProtection.result = powersurge.backupProtection
                    backupProtection.time = powersurge.createTime
                    this.changeData.push(backupProtection)
                }
                if (powersurge.deviceType && powersurge.testProject == '电源电涌保护器' && powersurge.deviceType != '请选择') {
                    const deviceType = {}
                    deviceType.point = powersurge.testPosition
                    deviceType.project = powersurge.testProject
                    deviceType.paramet = '器件类型'
                    deviceType.result = powersurge.deviceType
                    deviceType.time = powersurge.createTime
                    this.changeData.push(deviceType)
                }
                if (powersurge.protectedMode && powersurge.testProject == '电源电涌保护器' && powersurge.protectedMode != '请选择') {
                    const protectedMode = {}
                    protectedMode.point = powersurge.testPosition
                    protectedMode.project = powersurge.testProject
                    protectedMode.paramet = '保护模式'
                    protectedMode.result = powersurge.protectedMode
                    protectedMode.time = powersurge.createTime
                    this.changeData.push(protectedMode)
                }
                if (powersurge.statusIndicator && powersurge.testProject == '电源电涌保护器' && powersurge.statusIndicator != '请选择') {
                    const statusIndicator = {}
                    statusIndicator.point = powersurge.testPosition
                    statusIndicator.project = powersurge.testProject
                    statusIndicator.paramet = '状态指示器'
                    statusIndicator.result = powersurge.statusIndicator
                    statusIndicator.time = powersurge.createTime
                    this.changeData.push(statusIndicator)
                }
                if (powersurge.conductorColor && powersurge.testProject == '电源电涌保护器' && powersurge.conductorColor != '请选择') {
                    const conductorColor = {}
                    conductorColor.point = powersurge.testPosition
                    conductorColor.project = powersurge.testProject
                    conductorColor.paramet = '连接导体颜色'
                    conductorColor.result = powersurge.conductorColor
                    conductorColor.time = powersurge.createTime
                    this.changeData.push(conductorColor)
                }
                if (powersurge.sumOfLengths && powersurge.testProject == '电源电涌保护器') {
                    const sumOfLengths = {}
                    sumOfLengths.point = powersurge.testPosition
                    sumOfLengths.project = powersurge.testProject
                    sumOfLengths.paramet = '两端引线长度之和(m)'
                    sumOfLengths.result = powersurge.sumOfLengths
                    sumOfLengths.time = powersurge.createTime
                    this.changeData.push(sumOfLengths)
                }
                if (powersurge.spdOfDistance && powersurge.testProject == '电源电涌保护器') {
                    const spdOfDistance = {}
                    spdOfDistance.point = powersurge.testPosition
                    spdOfDistance.project = powersurge.testProject
                    spdOfDistance.paramet = 'SPD各级距离(m)'
                    spdOfDistance.result = powersurge.spdOfDistance
                    spdOfDistance.time = powersurge.createTime
                    this.changeData.push(spdOfDistance)
                }
                if (powersurge.powerLineEntryMethod && powersurge.testProject == '电源电涌保护器' && powersurge.powerLineEntryMethod != '请选择') {
                    const powerLineEntryMethod = {}
                    powerLineEntryMethod.point = powersurge.testPosition
                    powerLineEntryMethod.project = powersurge.testProject
                    powerLineEntryMethod.paramet = '电源线路入户方式'
                    powerLineEntryMethod.result = powersurge.powerLineEntryMethod
                    powerLineEntryMethod.time = powersurge.createTime
                    this.changeData.push(powerLineEntryMethod)
                }
                if (powersurge.electricalSystemGroundingType && powersurge.testProject == '电源电涌保护器' && powersurge.electricalSystemGroundingType != '请选择') {
                    const electricalSystemGroundingType = {}
                    electricalSystemGroundingType.point = powersurge.testPosition
                    electricalSystemGroundingType.project = powersurge.testProject
                    electricalSystemGroundingType.paramet = '配电系统接地型式'
                    electricalSystemGroundingType.result = powersurge.electricalSystemGroundingType
                    electricalSystemGroundingType.time = powersurge.createTime
                    this.changeData.push(electricalSystemGroundingType)
                }
                if (powersurge.uc && powersurge.testProject == '电源电涌保护器') {
                    const uc = {}
                    uc.point = powersurge.testPosition
                    uc.project = powersurge.testProject
                    uc.paramet = `最大持续运行电压Uc( ${powersurge.ucUnit})`
                    uc.result = powersurge.uc
                    uc.time = powersurge.createTime
                    this.changeData.push(uc)
                }
                if (powersurge.ucN && powersurge.testProject == '电源电涌保护器') {
                    const ucN = {}
                    ucN.point = powersurge.testPosition
                    ucN.project = powersurge.testProject
                    ucN.paramet = `最大持续运行电压(N相)Uc( ${powersurge.ucNUnit})`
                    ucN.result = powersurge.ucN
                    ucN.time = powersurge.createTime
                    this.changeData.push(ucN)
                }
                if (powersurge.up && powersurge.testProject == '电源电涌保护器') {
                    const up = {}
                    up.point = powersurge.testPosition
                    up.project = powersurge.testProject
                    up.paramet = '电压保护水平Up(kV)'
                    up.result = powersurge.up
                    up.time = powersurge.createTime
                    this.changeData.push(up)
                }
                if (powersurge.upN && powersurge.testProject == '电源电涌保护器') {
                    const upN = {}
                    upN.point = powersurge.testPosition
                    upN.project = powersurge.testProject
                    upN.paramet = '电压保护水平(N相)Up(kV)'
                    upN.result = powersurge.upN
                    upN.time = powersurge.createTime
                    this.changeData.push(upN)
                }
                if (powersurge.uw && powersurge.testProject == '电源电涌保护器') {
                    const uw = {}
                    uw.point = powersurge.testPosition
                    uw.project = powersurge.testProject
                    uw.paramet = '被保护设备的耐冲击过电压额定值UW(kV)'
                    uw.result = powersurge.uw
                    uw.time = powersurge.createTime
                    this.changeData.push(uw)
                }
                if (powersurge.upf && powersurge.testProject == '电源电涌保护器') {
                    const upf = {}
                    upf.point = powersurge.testPosition
                    upf.project = powersurge.testProject
                    upf.paramet = '有效电压保护水平Up/f(kV)'
                    upf.result = powersurge.upf
                    upf.time = powersurge.createTime
                    this.changeData.push(upf)
                }
                if (powersurge.wireMaterialSpecifications && powersurge.testProject == '电源电涌保护器') {
                    const wireMaterialSpecifications = {}
                    wireMaterialSpecifications.point = powersurge.testPosition
                    wireMaterialSpecifications.project = powersurge.testProject
                    wireMaterialSpecifications.paramet = '连接导线材料规格'
                    wireMaterialSpecifications.result = powersurge.wireMaterialSpecifications
                    wireMaterialSpecifications.time = powersurge.createTime
                    this.changeData.push(wireMaterialSpecifications)
                }
                if (powersurge.wireTransitionResistor && powersurge.testProject == '电源电涌保护器') {
                    const wireTransitionResistor = {}
                    wireTransitionResistor.point = powersurge.testPosition
                    wireTransitionResistor.project = powersurge.testProject
                    wireTransitionResistor.paramet = '连接导线的过渡电阻(Ω)'
                    wireTransitionResistor.result = powersurge.wireTransitionResistor
                    wireTransitionResistor.time = powersurge.createTime
                    this.changeData.push(wireTransitionResistor)
                }
                if (powersurge.impulseCurrent && powersurge.testProject == '电源电涌保护器') {
                    const impulseCurrent = {}
                    impulseCurrent.point = powersurge.testPosition
                    impulseCurrent.project = powersurge.testProject
                    impulseCurrent.paramet = '冲击电流Iimp(kA)'
                    impulseCurrent.result = powersurge.impulseCurrent
                    impulseCurrent.time = powersurge.createTime
                    this.changeData.push(impulseCurrent)
                }
                if (powersurge.impulseCurrentN && powersurge.testProject == '电源电涌保护器') {
                    const impulseCurrentN = {}
                    impulseCurrentN.point = powersurge.testPosition
                    impulseCurrentN.project = powersurge.testProject
                    impulseCurrentN.paramet = '冲击电流(N相)Iimp(kA)'
                    impulseCurrentN.result = powersurge.impulseCurrentN
                    impulseCurrentN.time = powersurge.createTime
                    this.changeData.push(impulseCurrentN)
                }
                if (powersurge.iTotal && powersurge.testProject == '电源电涌保护器') {
                    const iTotal = {}
                    iTotal.point = powersurge.testPosition
                    iTotal.project = powersurge.testProject
                    iTotal.paramet = '总放电电流ITotal(kA)'
                    iTotal.result = powersurge.iTotal
                    iTotal.time = powersurge.createTime
                    this.changeData.push(iTotal)
                }
                if (powersurge.nominalDischargeEurrent && powersurge.testProject == '电源电涌保护器') {
                    const nominalDischargeEurrent = {}
                    nominalDischargeEurrent.point = powersurge.testPosition
                    nominalDischargeEurrent.project = powersurge.testProject
                    nominalDischargeEurrent.paramet = '标称放电电流In(kA)'
                    nominalDischargeEurrent.result = powersurge.nominalDischargeEurrent
                    nominalDischargeEurrent.time = powersurge.createTime
                    this.changeData.push(nominalDischargeEurrent)
                }
                if (powersurge.nominalDischargeEurrentN && powersurge.testProject == '电源电涌保护器') {
                    const nominalDischargeEurrentN = {}
                    nominalDischargeEurrentN.point = powersurge.testPosition
                    nominalDischargeEurrentN.project = powersurge.testProject
                    nominalDischargeEurrentN.paramet = '标称放电电流(N相)In(kA)'
                    nominalDischargeEurrentN.result = powersurge.nominalDischargeEurrentN
                    nominalDischargeEurrentN.time = powersurge.createTime
                    this.changeData.push(nominalDischargeEurrentN)
                }
                if (powersurge.maxDischargeEurrent && powersurge.testProject == '电源电涌保护器') {
                    const maxDischargeEurrent = {}
                    maxDischargeEurrent.point = powersurge.testPosition
                    maxDischargeEurrent.project = powersurge.testProject
                    maxDischargeEurrent.paramet = '最大放电电流(kA)'
                    maxDischargeEurrent.result = powersurge.maxDischargeEurrent
                    maxDischargeEurrent.time = powersurge.createTime
                    this.changeData.push(maxDischargeEurrent)
                }
                if (powersurge.maxDischargeEurrentN && powersurge.testProject == '电源电涌保护器') {
                    const maxDischargeEurrentN = {}
                    maxDischargeEurrentN.point = powersurge.testPosition
                    maxDischargeEurrentN.project = powersurge.testProject
                    maxDischargeEurrentN.paramet = '最大放电电流(N相)Imax(kA)'
                    maxDischargeEurrentN.result = powersurge.maxDischargeEurrentN
                    maxDischargeEurrentN.time = powersurge.createTime
                    this.changeData.push(maxDischargeEurrentN)
                }
                if (powersurge.terminalEarthResistance && powersurge.testProject == '电源电涌保护器') {
                    const terminalEarthResistance = {}
                    terminalEarthResistance.point = powersurge.testPosition
                    terminalEarthResistance.project = powersurge.testProject
                    terminalEarthResistance.paramet = '接地端接地电阻(Ω)'
                    terminalEarthResistance.result = powersurge.terminalEarthResistance
                    terminalEarthResistance.time = powersurge.createTime
                    this.changeData.push(terminalEarthResistance)
                }
                if (powersurge.u1maL1 && powersurge.testProject == '电源电涌保护器') {
                    const u1maL1 = {}
                    u1maL1.point = powersurge.testPosition
                    u1maL1.project = powersurge.testProject
                    u1maL1.paramet = '压敏电压U1 mA (L1)(V)'
                    u1maL1.result = powersurge.u1maL1
                    u1maL1.time = powersurge.createTime
                    this.changeData.push(u1maL1)
                }
                if (powersurge.u1maL2 && powersurge.testProject == '电源电涌保护器') {
                    const u1maL2 = {}
                    u1maL2.point = powersurge.testPosition
                    u1maL2.project = powersurge.testProject
                    u1maL2.paramet = '压敏电压U1 mA (L2)(V)'
                    u1maL2.result = powersurge.u1maL2
                    u1maL2.time = powersurge.createTime
                    this.changeData.push(u1maL2)
                }
                if (powersurge.u1maL3 && powersurge.testProject == '电源电涌保护器') {
                    const u1maL3 = {}
                    u1maL3.point = powersurge.testPosition
                    u1maL3.project = powersurge.testProject
                    u1maL3.paramet = '压敏电压U1 mA (L3)(V)'
                    u1maL3.result = powersurge.u1maL3
                    u1maL3.time = powersurge.createTime
                    this.changeData.push(u1maL3)
                }
                if (powersurge.u1maN && powersurge.testProject == '电源电涌保护器') {
                    const u1maN = {}
                    u1maN.point = powersurge.testPosition
                    u1maN.project = powersurge.testProject
                    u1maN.paramet = '压敏电压U1 mA (N)(V)'
                    u1maN.result = powersurge.u1maN
                    u1maN.time = powersurge.createTime
                    this.changeData.push(u1maN)
                }
                if (powersurge.iieL1 && powersurge.testProject == '电源电涌保护器') {
                    const iieL1 = {}
                    iieL1.point = powersurge.testPosition
                    iieL1.project = powersurge.testProject
                    iieL1.paramet = '泄漏电流Iie (L1)(μA)'
                    iieL1.result = powersurge.iieL1
                    iieL1.time = powersurge.createTime
                    this.changeData.push(iieL1)
                }
                if (powersurge.iieL2 && powersurge.testProject == '电源电涌保护器') {
                    const iieL2 = {}
                    iieL2.point = powersurge.testPosition
                    iieL2.project = powersurge.testProject
                    iieL2.paramet = '泄漏电流Iie (L2)(μA)'
                    iieL2.result = powersurge.iieL2
                    iieL2.time = powersurge.createTime
                    this.changeData.push(iieL2)
                }
                if (powersurge.iieL3 && powersurge.testProject == '电源电涌保护器') {
                    const iieL3 = {}
                    iieL3.point = powersurge.testPosition
                    iieL3.project = powersurge.testProject
                    iieL3.paramet = '泄漏电流Iie (L3)(μA)'
                    iieL3.result = powersurge.iieL3
                    iieL3.time = powersurge.createTime
                    this.changeData.push(iieL3)
                }
                if (powersurge.iieN && powersurge.testProject == '电源电涌保护器') {
                    const iieN = {}
                    iieN.point = powersurge.testPosition
                    iieN.project = powersurge.testProject
                    iieN.paramet = '泄漏电流Iie (N)(μA)'
                    iieN.result = powersurge.iieN
                    iieN.time = powersurge.createTime
                    this.changeData.push(iieN)
                }
                if (powersurge.insulationResistanceL1 && powersurge.testProject == '电源电涌保护器') {
                    const insulationResistanceL1 = {}
                    insulationResistanceL1.point = powersurge.testPosition
                    insulationResistanceL1.project = powersurge.testProject
                    insulationResistanceL1.paramet = '绝缘电阻 (L1)(MΩ)'
                    insulationResistanceL1.result = powersurge.insulationResistanceL1
                    insulationResistanceL1.time = powersurge.createTime
                    this.changeData.push(insulationResistanceL1)
                }
                if (powersurge.insulationResistanceL2 && powersurge.testProject == '电源电涌保护器') {
                    const insulationResistanceL2 = {}
                    insulationResistanceL2.point = powersurge.testPosition
                    insulationResistanceL2.project = powersurge.testProject
                    insulationResistanceL2.paramet = '绝缘电阻 (L2)(MΩ)'
                    insulationResistanceL2.result = powersurge.insulationResistanceL2
                    insulationResistanceL2.time = powersurge.createTime
                    this.changeData.push(insulationResistanceL2)
                }
                if (powersurge.insulationResistanceL3 && powersurge.testProject == '电源电涌保护器') {
                    const insulationResistanceL3 = {}
                    insulationResistanceL3.point = powersurge.testPosition
                    insulationResistanceL3.project = powersurge.testProject
                    insulationResistanceL3.paramet = '绝缘电阻 (L3)(MΩ)'
                    insulationResistanceL3.result = powersurge.insulationResistanceL3
                    insulationResistanceL3.time = powersurge.createTime
                    this.changeData.push(insulationResistanceL3)
                }
                if (powersurge.insulationResistanceN && powersurge.testProject == '电源电涌保护器') {
                    const insulationResistanceN = {}
                    insulationResistanceN.point = powersurge.testPosition
                    insulationResistanceN.project = powersurge.testProject
                    insulationResistanceN.paramet = '绝缘电阻 (N)(MΩ)'
                    insulationResistanceN.result = powersurge.insulationResistanceN
                    insulationResistanceN.time = powersurge.createTime
                    this.changeData.push(insulationResistanceN)
                }
                if (powersurge.photo && powersurge.testProject == '电源电涌保护器') {
                    const photo = {}
                    let imgsrcs = powersurge.photo.split('|')
                    for (let i in imgsrcs) {
                        if (imgsrcs[i] != null && imgsrcs[i] != '') {
                            this.$axios({
                                method: 'get',
                                url: '/lps/hwcloud/obs/downurl',
                                params: {
                                    objname: imgsrcs[i]
                                }
                            }).then(async res => {
                                if (res.data != null) {
                                    this.$set(photo, 'point', powersurge.testPosition)
                                    this.$set(photo, 'project', powersurge.testProject)
                                    this.$set(photo, 'imgurl', res.data.url)
                                    this.$set(photo, 'paramet', 'SPD现场图片')
                                    this.$set(photo, 'type', 'photo')
                                    this.$set(photo, 'time', powersurge.createTime)
                                    this.urlList.push(res.data.url)
                                }
                            })
                        }
                    }
                    this.changeData.push(photo)
                }
            }
            for (let item in this.changeData) {
                delete this.changeData[item].result
                // console.log(delete this.changeData[item].result);
            }
            this.getSpanArr(this.changeData)
        },
    }
}
</script>
<style scoped>
#ftable {
    width: 199.5mm;
    min-height: 48px;
    border: 0.5px solid rgb(32, 32, 32);
    position: relative;
    margin-left: 5mm;
    font-family: 'song';
    margin-top: -1px;
    margin-bottom: -1px;
}

#fqtotal {
    width: 210mm;
    display: inline-block;
}

#fq {
    width: 31.5mm;
    vertical-align: top;
    height: 100%;
    display: inline-block;
}

#fq p {
    text-align: center;
    font-family: 黑体;
    font-weight: 700;
    font-size: 17px;
    padding-top: 15px;
}

#fqcenter p {
    font-family: 黑体;
    font-weight: 700;
    font-size: 17px;
    padding-top: 15px;
}

#fqcenter {
    border-left: 1px solid rgb(32, 32, 32);
    width: 168.2mm;
    min-height: 48px;
    display: inline-block;
}

.el-table {
    border-bottom: 0.5px solid black !important;
    /* margin-bottom: -1px; */
}

.el-table>>>.my-cell {
    text-align: center;
    border-left: 0.5px solid rgb(32, 32, 32) !important;
}

.el-table>>>.el-table__cell {
    border-right: 0.5px solid rgb(32, 32, 32) !important;
    border-bottom: 0.5px solid rgb(32, 32, 32) !important;
}

::v-deep .el-table th {
    border-top: 1px solid rgb(32, 32, 32) !important;
}

#spdImg {
    margin-left: -10px;
}</style>