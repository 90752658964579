/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:37:07 
 * @Last Modified by:   王翎爆 
 * @Last Modified time: 2023-03-30 09:37:07 
 */
<template>
    <div>
        <Menu></Menu>
        <el-row>
            <el-col :span="22" :offset="1">
                <div class="grid-content bg-purple-dark"
                    style="border-bottom: solid 1px #ced5db;margin-top: 10px;padding-bottom:5px;display: inline-block; ">
                    <span>
                        <span style="margin-left: 10px;">
                            <el-button @click="dialogFormVisibleSift = true">筛选</el-button>
                        </span>
                    </span>
                </div>
                <!-- 筛选 -->
                <el-dialog title="筛选" :visible.sync="dialogFormVisibleSift" style="width: 60%;">
                    <el-form :model="Siftform">
                        <el-form-item label="流程" label-width="50px">
                            <el-input v-model="Siftform.pcdname" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="initProcess()">筛 选</el-button>
                        <el-button @click="dialogFormVisibleSift = false">取 消</el-button>
                    </div>
                </el-dialog>
                <el-table :data="tableData" border style="width:90%;margin-top: 20px;">
                    <el-table-column prop="id" label="序号" width="80">
                    </el-table-column>
                    <el-table-column prop="pcdname" label="流程" width="140">
                    </el-table-column>
                    <el-table-column prop="pcdmeta" label="流程描述" >
                    </el-table-column>
                    <el-table-column prop="pcdstatus_meta" label="状态" width="200">
                    </el-table-column>
                    <el-table-column prop="others" label="备注">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button type="dange" @click="editProcess(scope.$index, scope.row)">编辑
                            </el-button> <br>
                            <el-button id="down" type="dange" @click="FlowProcess(scope.row)">流程图</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 编辑 -->
                <el-dialog title="流程信息" :visible.sync="dialogFormVisibleProcess" style="width: 60%; left:20%">
                    <el-form :model="Processform">
                        <el-form-item label="流程" label-width="80px">
                            <el-input v-model="Processform.pcdname" autocomplete="off">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="流程描述" label-width="80px">
                            <el-input v-model="Processform.pcdmeta" autocomplete="off">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="流程状态" label-width="80px">
                            <el-select v-model="Processform.pflag" style="width: 170px" placeholder="请选择流程状态">
                                <el-option v-for="item in Processform.pflags" :key="item.dictcode"
                                    :label="item.dictcodemeta" :value="item.dictcode"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" label-width="80px">
                            <el-input v-model="Processform.others" type="textarea" autocomplete="off" placeholder="备注">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="ProcessEditAssign()">确 定</el-button>
                        <el-button @click="dialogFormVisibleProcess = false">取 消</el-button>
                    </div>
                </el-dialog>
                <!-- 流程图 -->
                <el-dialog title="流程图" :visible.sync="dialogFormVisibleFlow">
                    <div slot="footer" id="mountNode">
                        <!-- <el-table>
                    <el-table-column  label="业务管理人员" width="140">
                    </el-table-column>
                    <el-table-column  label="检测人员" width="140">
                    </el-table-column>
                    <el-table-column  label="审核人员" width="350">
                    </el-table-column>
                    <el-table-column  label="技术负责人" width="140">
                    </el-table-column>
                    <el-table-column  label="文印人员" width="145">
                    </el-table-column>
                </el-table> -->
                    </div>
                </el-dialog>
                <!--分页-->
                <el-pagination layout=" total,sizes,prev,pager,next,jumper" :total="total"
                    @size-change='handleSizeChange' @current-change='handleCurrentChange'
                    :current-page='queryInfo.pagenum' :page-sizes='[10, 20, 30, 40, 50]' :page-size='queryInfo.pagesize'>
                </el-pagination>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Menu from '@/components/Menu.vue';
import G6 from '@antv/g6'
export default {
    name: '',
    data() {
        return {
            tableData: [],
            dialogFormVisibleSift: false,
            dialogFormVisibleProcess: false,
            dialogFormVisibleFlow: false,
            Siftform: {
                pcdname: ''
            },
            total: 0,
            queryInfo: {
                pagenum: 1,
                pagesize: 20
            },
            pid: '',
            Processform: {
                pcdname: '',
                pcdmeta: '',
                pflag: '',
                pflags: '',
                others: ''
            },
            mountNode: {
                // 点集
                nodes: [
                    {
                        id: 'node1', // String，该节点存在则必须，节点的唯一标识
                        x: 100, // Number，可选，节点位置的 x 值
                        y: 200, // Number，可选，节点位置的 y 值
                        type: 'rect',
                        label: '导出《派工单》',
                        size: [120, 60]
                    },
                    {
                        id: 'node2', // String，该节点存在则必须，节点的唯一标识
                        x: 300, // Number，可选，节点位置的 x 值
                        y: 200, // Number，可选，节点位置的 y 值
                        type: 'diamond',
                        label: '检测《检测记录》',
                        size: [120, 60]
                    },
                    {
                        id: 'node3', // String，该节点存在则必须，节点的唯一标识
                        x: 300, // Number，可选，节点位置的 x 值
                        y: 100, // Number，可选，节点位置的 y 值
                        type: 'ellipse',
                        label: '上传《检测报告》',
                        size: [120, 60]
                    },
                    {
                        id: 'node4', // String，该节点存在则必须，节点的唯一标识
                        x: 500, // Number，可选，节点位置的 x 值
                        y: 100, // Number，可选，节点位置的 y 值
                        type: 'ellipse',
                        label: '审核《检测报告》',
                        size: [120, 60]
                    },
                    {
                        id: 'node5', // String，该节点存在则必须，节点的唯一标识
                        x: 700, // Number，可选，节点位置的 x 值
                        y: 100, // Number，可选，节点位置的 y 值
                        type: 'rect',
                        label: '导出检测报告',
                        size: [120, 60]
                    }
                ],
                // 边集
                edges: [
                    {
                        source: 'node1', // String，必须，起始点 id
                        target: 'node2', // String，必须，目标点 id
                    },
                    {
                        source: 'node2', // String，必须，起始点 id
                        target: 'node3', // String，必须，目标点 id
                    },
                    {
                        source: 'node3', // String，必须，起始点 id
                        target: 'node4', // String，必须，目标点 id
                    },
                    {
                        source: 'node4', // String，必须，起始点 id
                        target: 'node5', // String，必须，目标点 id
                    },
                ],
            }
        };
    },
    components: {
        Menu
    },
    mounted() {
        const graph = new G6.Graph({
            container: 'mountNode', // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
            width: 900, // Number，必须，图的宽度
            height: 500, // Number，必须，图的高度
        });
        graph.data(this.mountNode); // 读取 Step 2 中的数据源到图上
        graph.render(); // 渲染图
        this.initProcess()
        this.initFlags()
    },
    methods: {
        initFlags() {
            this.$axios({
                method: 'get',
                url: '/lps/sys/dict',
                params: {
                    dicttype: 'pcdstatus'
                }
            }).then(res => {
                console.log(res);
                this.Processform.pflags = res.data
            })
        },
        initProcess() {
            this.$axios({
                method: 'get',
                url: '/lps/sys/procedure',
                params: {
                    pcdname: this.Siftform.pcdname,
                    current: this.queryInfo.pagenum,
                    size: this.queryInfo.pagesize
                }
            }).then(res => {
                if (res.data.rows != null) {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    this.dialogFormVisibleSift = false
                }
            })
        },
        handleSizeChange(size) {
            this.queryInfo.pagesize = size
            this.initEquipment()
        },
        handleCurrentChange(num) {
            this.queryInfo.pagenum = num
            this.initEquipment()
        },
        editProcess(index, row) {
            this.dialogFormVisibleProcess = true
            this.pid = row.id
            this.Processform.pcdname = row.pcdname
            this.Processform.pcdmeta = row.pcdmeta
            this.Processform.pflag = row.pcdstatus
            this.Processform.others = row.others
        },
        ProcessEditAssign() {
            this.$axios({
                method: 'put',
                url: '/lps/sys/procedure/' + this.pid,
                data: {
                    pcdname: this.Processform.pcdname,
                    pcdmeta: this.Processform.pcdmeta,
                    pcdstatus: this.Processform.pflag,
                    others: this.Processform.others
                }
            }).then(res => {
                if (res.data.total != null) {
                    this.dialogFormVisibleProcess = false
                    this.$message.success('流程信息修改成功！')
                    this.initProcess()
                }
            })
        },
        FlowProcess() {
            this.dialogFormVisibleFlow = true
        }
    }
};
</script>
<style  scoped>
#down {
    margin-top: 5px;
}

.el-input {
    max-width: 300px;
}

#mountNode {
    position: relative;
    padding-left: -100px;
    display: inline-block;
}
</style>