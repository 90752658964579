/*
* @Author: 王翎爆
* @Date: 2023-03-30 09:37:48
* @Last Modified by: 王翎爆
* @Last Modified time: 2023-03-30 09:37:48
*/
<template>
    <div id="main">
        <div id="top">
            <el-button @click="dialogVisible = true">目录</el-button>
            <el-button @click="dialogVisibleAttach = true">附件</el-button>
        </div>
        <el-dialog title="目录结构" :visible.sync="dialogVisible" width="30%">
            <div>
                <div id="catatop">目录</div>
                <div v-for="(item, index) in siteList" id="catacenter" :key="index">
                    <span class="span1">{{ index + 1 }}.&nbsp;&nbsp;&nbsp;{{ item.siteName }}</span>
                    <span class="span2">......................{{ index + 1 }}</span>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="附件" :visible.sync="dialogVisibleAttach" width="30%">
            <div>
                <div v-for="(item, index) in attdata" id="attachcenter" :key="index">
                    <span>{{ item.id }}:&nbsp;&nbsp;{{ item.name }}</span>
                    <div>{<a>图片</a>}</div>
                </div>
                <div id="attachbottom">
                    <p>以下空白</p>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleAttach = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisibleAttach = false">确 定</el-button>
            </span>
        </el-dialog>

        <div id="catalog">
            <h3>目录结构</h3>
            <el-input placeholder="输入关键字进行过滤" v-model="filterText">
            </el-input>

            <el-tree class="filter-tree" :data="treedata" :props="defaultProps" node-key="id" default-expand-all
                :filter-node-method="filterNode" ref="tree" @node-click="testclick" :expand-on-click-node="false">
            </el-tree>
        </div>

        <div id="total">
            <div id="center">
                <div v-for="(item, bindex) in siteList" :key="bindex">
                    <ReportSummary :id="item.siteName" :SummaryName="item" :endorseMessage="`siteList.` + bindex"
                        @change="SummaryChange">
                    </ReportSummary>
                    <div v-for="(item, index) in item.areaList" :key="index">
                        <ReportSubrgion :id="item.areaName" :SubrgionName="item"
                            :endorseMessage="`siteList.` + bindex + `.areaList.` + index" @change="SubrgionChange">
                        </ReportSubrgion>
                        <ReportImg></ReportImg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Menu from '@/components/Menu.vue'
import ReportSummary from '../ReportSummary'
import ReportSubrgion from '../ReportSubrgion'
import ReportImg from '../ReportImg'
export default {
    name: '',
    data() {
        return {
            dialogVisible: false,
            dialogVisibleAttach: false,
            siteList: [],
            catalogueList: [],
            treedata: [],
            filterText: '',
            defaultProps: {
                label: 'label',
                children: 'children'
            },
            attdata: [{
                id: '附件一',
                name: '防雷装置检测甲级资质(扫描件)',
            }, {
                id: '附件二',
                name: '公司营业执照(扫描件)',
            }, {
                id: '附件三',
                name: '个人健康说明',
            }],
            count: '',
            lastvalue: '',
            lastkey: '',
            commentData: {}
        }
    },
    components: {
        Menu,
        ReportSummary,
        ReportSubrgion,
        ReportImg
    },
    mounted() {
        this.ReportTest()
        console.log('id:', this.$route.query.id);
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        //页面定位
        testclick(data) {
            console.log('目录：', data.label);
            var dpoint = data.label.replace(/\s*\(.*?\)\s*/g, '')
            if (dpoint) {
                // console.log('querySelector',document.querySelector('#anch3'))
                document.getElementById(dpoint).scrollIntoView({
                    behavior: "instant"
                });
            }
        },
        openCatalog() {

        },
        ReportTest() {
            this.$axios({
                method: 'get',
                url: '/lps/test/testreport/content/' + this.$route.query.id
            }).then(res => {
                if (res.data != null) {
                    // console.log(res.data);
                    this.siteList = res.data.siteList
                    this.pointNumber(res.data.siteList)
                }
            })
        },
        //遍历出分区数组中每个对象的长度，作为分区检测点的个数
        pointNumber(pointLists) {
            var pointList = JSON.parse(JSON.stringify(pointLists)) //深拷贝防止影响sitelist数组
            for (var i = 0; i < pointList.length; i++) {
                var bigsum = 0
                if (pointList[i].areaList) {
                    for (var j = 0; j < pointList[i].areaList.length; j++) {
                        var sum = 0
                        for (var m = 0; m < pointList[i].areaList[j].sourceDataList.length; m++) {
                            var changObj = pointList[i].areaList[j].sourceDataList[m]
                            for (let item in changObj) {
                                if (item.indexOf('Standard') != -1) {
                                    delete changObj[item]
                                }
                            }
                            // console.log('changeObj:',changObj);
                            var arr = Object.keys(changObj)
                            // console.log('arr:',arr);
                            // console.log('分区长度：', arr.length)
                            sum += arr.length - 6
                            pointList[i].areaList[j].count = sum
                        }
                        bigsum += pointList[i].areaList[j].count
                        pointList[i].count = bigsum
                    }
                } else {
                        var sum = 0
                        for (var m = 0; m < pointList[i].sourceDataList.length; m++) {
                            var changObj = pointList[i].sourceDataList[m]
                            for (let item in changObj) {
                                if (item.indexOf('Standard') != -1) {
                                    delete changObj[item]
                                }
                            }
                            // console.log('changeObj:',changObj);
                            var arr = Object.keys(changObj)
                            // console.log('arr:',arr);
                            // console.log('分区长度：', arr.length)
                            sum += arr.length - 6
                            pointList[i].count = sum
                        }
                        bigsum += pointList[i].count
                        pointList[i].count = bigsum
                }

            }
            this.getsiteList(pointList)
        },
        //目录结构tree数据转换
        getsiteList(list) {
            var changeList = JSON.parse(JSON.stringify(list))
            changeList.forEach(element => {
                var obj = {
                    label: element.siteName + `(${element.count})`,
                    children: element.areaList && element.areaList.length > 0 ? this.copyTree(element.areaList) : element.areaList
                }
                this.treedata.push(obj)
            });
            // console.log('改变后的tree:', this.treedata);
        },
        //递归转换数据格式中每层的格式
        copyTree(childrenList) {
            // console.log('copyTree:',this.count);
            childrenList.forEach((item) => {
                item.label = item.areaName + `(${item.count})`
                item.children = item.childrenList
                if (item.childrenList && item.childrenList.length) {
                    this.copyTree(item.children)
                }
            })
            return childrenList;
        },
        //场所批注信息返回
        SummaryChange(key, value1) {
            if (this.lastvalue !== value1) {
                this.lastkey = key
                this.lastvalue = value1
                this.commentData[key] = value1
                console.log(this.commentData);
                console.log('父组件：', key, this.lastvalue);
            } else if (this.lastvalue == value1 && this.lastkey !== key) {
                this.commentData[key] = value1
                console.log(this.commentData);
                console.log('父组件：', key, this.lastvalue);
            }

        },
        //分区批注信息返回
        SubrgionChange(key, value) {
            if (this.lastvalue !== value) {
                this.lastkey = key
                this.lastvalue = value
                this.commentData[key] = value
                console.log(this.commentData);
                console.log('父组件：', key, this.lastvalue);
            } else if (this.lastvalue == value && this.lastkey !== key) {
                this.commentData[key] = value
                console.log(this.commentData);
                console.log('父组件：', key, this.lastvalue);
            }
        }
    }
}
</script>
<style scoped>
#main {
    overflow-x: hidden;
    width: 100%;
}

#total {
    margin-left: 25%;
    width: 60%;
    border: 1px solid gainsboro;
    box-shadow: 1px 1px 4px grey;
    margin-top: 30px;
}

#center {
    width: 90%;
    margin: 0px 50px;
}

#top {
    margin: 20px 200px;
}

#catalog {
    border: 1px solid gainsboro;
    width: 20%;
    height: 700px;
    position: fixed;
    /* position: fixed;
    display:flex;
    justify-content:flex-start;
    flex-direction: column; */
    left: 10px;
}

#catatop {
    text-align: center;
    font-size: 22px;
    letter-spacing: 20px;
}

#catacenter {
    margin: 10px 100px;
    font-size: 16px;
}

.span1 {
    display: inline-block;
    width: 100px;
    text-align: left;
}

.span2 {
    position: absolute;
    left: 300px;
}

#attachcenter {
    margin: 10px 50px;
    font-size: 16px;
}

#attachbottom p {
    margin-left: 400px;
    text-decoration: underline;
}
</style>