<!--
 * @Author: 白一桐
 * @Date: 2023-03-31 16:58:44
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-11-13 16:53:39
 原始记录页面
-->
<template>
  <div>
    <!-- -------------------------------------------t----------------------------------------------- -->
    <!-- 按钮行部分 -->
    <!-- <div id="btnList">
      <div class="left"> 
        <el-button v-print="printObj" @click="print()" :disabled="isdisabled">导出pdf</el-button>
        <div class="printcss">
          &nbsp; &nbsp;报告总页码共 &nbsp; <el-input v-model="pagenum" placeholder="输入"
            style="display: inline-block;width: 70px;"></el-input>
          &nbsp;页 &nbsp; &nbsp;
        </div>
      </div>
    </div> -->
    <div id="btnList">
      <div class="left">
        <el-button @click="dialogVisibleOk = true" :disabled="isdisabled">导出pdf</el-button>
      </div>
      <!-- <div class="right">
      </div> --> 
      <div class="printcss">
        &nbsp; &nbsp;报告总页码共 &nbsp; <el-input v-model="pagenum" placeholder="输入" style="display: inline-block;width: 70px;"></el-input>
        &nbsp;页 &nbsp; &nbsp;
      </div>
    </div>
    <img src="../../assets/tops.png" @click="toTop" class="top" alt="" style="width: 64px;height: 64px;" v-show="imgshow">

    <!-- 报告内容部分 -->
    <div id="bodydy">
      <!-- 报告首页 -->
      <div id="catalog">
        <h3>目录结构</h3>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>

        <el-tree class="filter-tree" :data="treedata" :props="defaultProps" node-key="id" default-expand-all :filter-node-method="filterNode" ref="tree" @node-click="testclick" :expand-on-click-node="false">
        </el-tree>
      </div>
      <reportcover :reportingData="reportingData">
        <template #title>
          <p style="text-align: center;font-size: 50px;width: 120mm;">雷电防护装置检测<br>原始记录</p>
        </template>
        <!-- <template #ircodede>
                          <img :src="dialogImageUrl" width="100px" height="100px" id="show">
                        </template> -->
      </reportcover>
      <rawDatapage :reportingData="reportingData" ref="homepage">
        <template #standard>
          <div v-html="reportingData.standardbr"></div>
        </template>
      </rawDatapage>

    </div>
    <!---------------------------------------------w------------------------------------------------>
    <div id="main">
      <el-dialog title="目录结构" :visible.sync="dialogVisible" width="30%">
        <div>
          <div id="catatop">目录</div>
          <div v-for="(item, index) in siteList" id="catacenter" :key="index">
            <span class="span1">{{ index + 1 }}.&nbsp;&nbsp;&nbsp;{{ item.siteName }}</span>
            <span class="span2">......................{{ index + 1 }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="附件" :visible.sync="dialogVisibleAttach" width="30%">
        <div>
          <div v-for="(item, index) in attdata" id="attachcenter" :key="index">
            <span>{{ item.id }}:&nbsp;&nbsp;{{ item.name }}</span>
            <div>{<a>图片</a>}</div>
          </div>
          <div id="attachbottom">
            <p>以下空白</p>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleAttach = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleAttach = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="打印确认" :visible.sync="dialogVisibleOk" width="30%">
        <div>
          <p></p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="seleteResultFull()" v-print="printObj">完整版</el-button>
          <el-button type="primary" @click="seleteResult()" v-print="printObj">简版</el-button>
        </span>
      </el-dialog>
      <div id="origintotal">
        <div id="center">
          <div v-for="(printitem, printbindex) in siteList" :key="printbindex" id="fbodystyle">
            <originDataSummary :index="printbindex" :id="printitem.siteName" :SummaryName="printitem">
            </originDataSummary>
            <div v-for="(item, index) in printitem.areaList" :key="index">
              <originDataSubrgion :id="item.areaName" :SubrgionName="item" ref="printsubrgion">
              </originDataSubrgion>
              <printReportImg v-if="item.photo" :photo="item.photo">
              </printReportImg>
            </div>
          </div>
        </div>
      </div>
      <figures :imgname="itm" v-for="itm in reportPhoto" :key="itm">
        <template #title>
          附图:
        </template>
      </figures>
    </div>
    <!------------------------------------------打印部分----------------------------------------------->
    <iframe width="0" height="0" id="showMemberInfo">
      <div id="bodydy">
        <!-- 报告首页 -->
        <div id="printSection" ref="table"> 
          <table id="printData" ref="printData">
            <div id="noheaderBody"></div>
            <thead style="display: table-header-group;" v-show="resultIf==false">
              <!-- 完整版 -->
              <tr>
                <td>
                  <printHeader>
                    <template #printheader>河北德创检测服务有限公司防雷防静电装置检测原始记录</template>
                    <template #reportcode> 编号：{{ printdata.printcode }}</template>
                    <template #page>
                      <div id="page000"></div>
                    </template>
                  </printHeader>
                </td>
              </tr>
            </thead>
            <thead style="display: table-header-group;" v-show="resultIf==true">
              <!-- 简版 -->
              <tr>
                <td>
                  <printHeader>
                    <template #printheader>河北德创检测服务有限公司防雷防静电装置检测原始记录</template>
                    <template #reportcode> 编号：德雷检字[&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]第（&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;）号</template>
                    <template #page>
                      <div>第___页 共____页</div>
                    </template>
                  </printHeader>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div id="headerBody"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <printReportcover :reportingData="reportingData" ref="cover">
          <template #title>
            <p style="text-align: center;font-size:50px;width: 120mm;">雷电防护装置检测<br>原始记录</p>
          </template>
          <!-- <template #ircodede>
                            <img :src="dialogImageUrl" width="100px" height="100px"></img>
                          </template> -->
        </printReportcover>
        <printReportcoverTest :reportingData="reportingData" ref="cover1">
          <template #title>
            <p style="text-align: center;font-size:50px;width: 120mm;">雷电防护装置检测<br>原始记录</p>
          </template>
          <!-- <template #ircodede>
                            <img :src="dialogImageUrl" width="100px" height="100px"></img>
                          </template> -->
        </printReportcoverTest>
        <rawDatapage :reportingData="reportingData" ref="homepage">
          <template #standard>
            <div v-html="reportingData.standardbr"></div>
          </template>
        </rawDatapage>
        <rawDatapageTest :reportingData="reportingData" ref="homepage1">
          <template #standard>
            <div v-html="reportingData.standardbr"></div>
          </template>
        </rawDatapageTest>
        <!--------------------------------------------w---------------------------------------------->
        <div id="fbody">
          <div v-for="(printitem, printbindex) in siteList" :key="printbindex" id="fbodystyle">
            <originDataSummary :index="printbindex" :id="printitem.siteName" :SummaryName="printitem" :equipmentIsShow="reportingData.isShow" v-show="resultIf==false">
            </originDataSummary>
            <originDataSummaryTest :index="printbindex" :id="printitem.siteName" :SummaryName="printitem" :equipmentIsShow="reportingData.isShow" v-show="resultIf==true">
            </originDataSummaryTest>
            <div v-for="(item, index) in printitem.areaList" :key="index">
              <originDataSubrgionTest :id="item.areaName" :SubrgionName="item" ref="printsubrgion" v-show="resultIf==true">
              </originDataSubrgionTest>
              <originDataSubrgion :id="item.areaName" :SubrgionName="item" ref="printsubrgion" v-show="resultIf==false">
              </originDataSubrgion>
              <printReportImg v-if="item.photo" :photo="item.photo">
              </printReportImg>
            </div>
          </div>
          <figures :imgname="itm" v-for="itm in reportPhoto" :key="itm">
            <template #title>
              附图:
            </template>
          </figures>
          <div v-if="siteList != ''" style="width:100%;height:20px;text-align:center;color:black;text-decoration:underline;">
            &nbsp;&nbsp;以下空白&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </iframe>
  </div>
</template>

<script>
//打印
import printHeader from '@/components/printHeader.vue'
import reportcover from '@/components/reportcover.vue'
import rawDatapage from '@/components/rawDatapage.vue'
import rawDatapageTest from '@/components/rawDatapageTest.vue'

import figures from '@/components/figure.vue'

import ReportSummary from '@/views-w/ReportSummary'
import ReportSubrgion from '@/views-w/ReportSubrgion'
import ReportImg from '@/views-w/ReportImg'
//打印
import printReportcover from '@/components/printReportcover.vue'
import printReportcoverTest from '@/components/printReportcoverTest.vue'
import originDataSummary from '@/views-w/originDataSummary'
import originDataSummaryTest from '@/views-w/originDataSummaryTest'
import originDataSubrgion from '@/views-w/originDataSubrgion'
import originDataSubrgionTest from '@/views-w/originDataSubrgionTest'
import printReportImg from '@/views-w/printReportImg'
import paperModule from '@/views-w/paperModule'

export default {
  data() {
    return {
      //-----------------------------t-----------------
      reportingData: {},
      reportId: '', //报告Id
      statusId: '', //报告状态
      ircode: '', //二维码
      dialogImageUrl: '', //二维码图片位置
      examinecontents: '初始值', //批注内容
      confirmstatMeta: '', //退回得总意见
      imgIframe: false, //上传图片的弹出层
      fromData: [], //上传图片时的key等数据
      key: '', //Object key
      imgName: '', //图片名称objname
      showMemberInfo: false, //打印页面
      printdata: {
        username: '河北德创检测服务有限公司防雷防静电装置检测报告',
        printcode: ''
      },
      printObj: {
        id: 'printSection', //要打印的id名 无#号
        popTitle: '', //页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
        extraHead: '' //头部文字 默认空
      },
      reportPhoto: [], //附图
      //-----------------------------w-----------------

      dialogVisible: false,
      dialogVisibleAttach: false,
      dialogVisibleOk: false,
      siteList: [],
      catalogueList: [],
      treedata: [],
      filterText: '',
      defaultProps: {
        label: 'label',
        children: 'children'
      },
      attdata: [
        {
          id: '附件一',
          name: '防雷装置检测甲级资质(扫描件)'
        },
        {
          id: '附件二',
          name: '公司营业执照(扫描件)'
        },
        {
          id: '附件三',
          name: '个人健康说明'
        }
      ],
      count: '',
      lastvalue: '',
      lastkey: '',
      commentData: {},
      isdisabled: true,
      imgshow: false,
      pagenum: '',
      resultIf: false
    }
  },
  components: {
    reportcover,
    printReportcoverTest,
    rawDatapage,
    rawDatapageTest,
    ReportSummary,
    ReportSubrgion,
    ReportImg,
    printHeader,

    figures,

    //打印
    printReportcover,
    // printReoprtstatement,
    originDataSummary,
    originDataSubrgion,
    printReportImg,
    paperModule,
    originDataSubrgionTest,
    originDataSummaryTest
  },
  created() {},
  mounted() {
    this.getData()
    this.reportId = this.$route.query.id
    //----w----
    window.addEventListener('scroll', this.imgshowListener) //事件监听
    this.imgload()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    //回到顶部按钮是否展示
    imgshowListener() {
      let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (top > 200) {
        this.imgshow = true
      } else {
        this.imgshow = false
      }
    },
    //图片加载完放开按钮
    imgload() {
      setTimeout(() => {
        this.isdisabled = false
      }, 3000)
    },
    // ----------------------------------t-----------------------------------------
    //回到顶部
    toTop() {
      document.documentElement.scrollTop = 0
    },
    // 获取整体的数据
    getData() {
      if (this.$route.query.type) {
        //从预览界面的原始记录
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/content/temp/' + this.$route.query.id
        }).then(res => {
            let site = res.data.siteList
            this.reportingData = res.data
            document.title = '原始记录 - ' + res.data.sites
            if (res.data.siteList != null) {
              //场所
              this.siteList = res.data.siteList
              this.pointNumber(res.data.siteList)
              // this.getExamineData()
            }
            if (res.data.photo) {
              let photos = res.data.photo
              photos = photos.substring(0, photos.lastIndexOf('|'))
              this.reportPhoto = photos.split('|')
              // console.log(this.reportPhoto);
            }

            if (res.data.siteList) {
              if (site.length == 1) {
                // console.log("没分区")
                this.reportingData.isShow = false
              } else {
                for (let i = 0; i < site.length; i++) {
                  for (let j = i + 1; j < site.length; j++) {
                    if (site[i].siteRelname != site[j].siteRelname) {
                      // console.log('分区了')
                      this.reportingData.isShow = true
                    } else {
                      // console.log("没分区")
                      this.reportingData.isShow = false
                    }
                  }
                }
              }
            }
            if (res.data.trptNo) {
              this.printdata.printcode = res.data.trptNo
            }
            if (res.data.standard) {
              let str = res.data.standard
              this.reportingData.standardbr = str.replace(/\n/g, '<br/>') //检测报告规范
            }
            if (res.data.equipment) {
              //设备
              // console.log(this.reportingData.equipment)
              let equip = this.reportingData.equipment.replaceAll('设备名称：', '')
              let equip1 = equip.replaceAll('型号：', '')
              let equip2 = equip1.replaceAll('设备编号：', '')
              let equip3 = equip2.replaceAll('出厂编号：', '')
              let strequip = equip3.split('|')
              // console.log(strequip)
              let equipmentArr = []
              for (let i = 0; i < strequip.length; i++) {
                let data = {}
                // console.log(strequip[i].split("\n")[1])
                data.eqName = strequip[i].split('\n')[1]
                data.eqModel = strequip[i].split('\n')[2]
                data.eqNo = strequip[i].split('\n')[3]
                equipmentArr.push(data)
              }
              // console.log(equipmentArr)
              this.reportingData.equipmentArr = equipmentArr
            }
            if (res.data.weather == '请选择') {
              this.reportingData.weather = ''
            }
            if (res.data.soil == '请选择') {
              this.reportingData.soil = ''
            }
            this.ircode = res.data.ircode
            if (res.data.ircode) {
              this.imgimg()
            } else {
              this.ircode = 'd62bdcd41fa4becb23e201851378e28d.jpg'
              this.imgimg()
            }
          })
          .catch(error => {
            console.log(error.data)
          })
      } else {
        //提交后版本的原始记录
        this.$axios({
          method: 'GET',
          url: '/lps/test/testreport/content/' + this.$route.query.id
        })
          .then(res => {
            let site = res.data.siteList
            this.reportingData = res.data
            document.title = '原始记录 - ' + res.data.sites
            if (res.data.siteList != null) {
              this.siteList = res.data.siteList
              this.pointNumber(res.data.siteList)
            }
            if (res.data.photo) {
              let photos = res.data.photo
              photos = photos.substring(0, photos.lastIndexOf('|'))
              this.reportPhoto = photos.split('|')
              // console.log(this.reportPhoto);
            }

            if (res.data.siteList) {
              if (site.length == 1) {
                // console.log("没分区")
                this.reportingData.isShow = false
              } else {
                for (let i = 0; i < site.length; i++) {
                  for (let j = i + 1; j < site.length; j++) {
                    if (site[i].siteRelname != site[j].siteRelname) {
                      // console.log('分区了')
                      this.reportingData.isShow = true
                    } else {
                      // console.log("没分区")
                      this.reportingData.isShow = false
                    }
                  }
                }
              }
            }
            if (res.data.trptNo) {
              this.printdata.printcode = res.data.trptNo
            }
            if (res.data.standard) {
              let str = res.data.standard
              this.reportingData.standardbr = str.replace(/\n/g, '<br/>') //检测报告规范
            }
            if (res.data.equipment) {
              //设备
              // console.log(this.reportingData.equipment)
              let equip = this.reportingData.equipment.replaceAll('设备名称：', '')
              let equip1 = equip.replaceAll('型号：', '')
              let equip2 = equip1.replaceAll('设备编号：', '')
              let equip3 = equip2.replaceAll('出厂编号：', '')
              let strequip = equip3.split('|')
              // console.log(strequip)
              let equipmentArr = []
              for (let i = 0; i < strequip.length; i++) {
                let data = {}
                data.eqName = strequip[i].split('\n')[1]
                data.eqModel = strequip[i].split('\n')[2]
                data.eqNo = strequip[i].split('\n')[3]
                equipmentArr.push(data)
              }
              // console.log(equipmentArr)
              this.reportingData.equipmentArr = equipmentArr
            }
            if (res.data.weather == '请选择') {
              this.reportingData.weather = ''
            }
            if (res.data.soil == '请选择') {
              this.reportingData.soil = ''
            }
            this.ircode = res.data.ircode
            if (res.data.ircode) {
              this.imgimg()
            } else {
              this.ircode = 'd62bdcd41fa4becb23e201851378e28d.jpg'
              this.imgimg()
            }
          })
          .catch(error => {
            console.log(error.data)
          })
      }
    },
    //获取图片名称key等数据
    getjpgData() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/upurl?filetype=jpg'
      })
        .then(res => {
          console.log(res.data)
          this.fromData = res.data
          this.key = res.data.objpath + res.data.objname
          this.imgName = res.data.objname //图片名称
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    //默认显示图片
    imgimg() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + this.ircode
      })
        .then(res => {
          // console.log(res.data)
          this.dialogImageUrl = res.data.url
        })
        .catch(error => {
          console.log(error.response.data)
        })
    },
    // ----------------------------------w-----------------------------------------
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    //页面定位
    testclick(data) {
      console.log('目录：', data.label)
      var dpoint = data.label.replace(/\s*\(.*?\)\s*/g, '')
      if (dpoint) {
        document.getElementById(dpoint).scrollIntoView({
          behavior: 'instant'
        })
      }
    },
    //回调函数调用分区子组件修约方法
    useevenRound(v1, v2) {
      let result = this.$refs.subrgion[0].evenRound(v1, v2)
      this.$bus.$emit('changepressureEqualizingRingSpacing', result)
    },
    ReportTest() {
      // this.$axios({
      //   method: 'get',
      //   url: '/lps/test/testreport/content/' + this.$route.query.id
      // }).then(res => {
      //   if (res.data.siteList != null) {
      //     this.siteList = res.data.siteList
      //     this.pointNumber(res.data.siteList)
      //     // this.getExamineData()
      //   }
      // })
    },
    //遍历出分区数组中每个对象的长度，作为分区检测点的个数
    pointNumber(pointLists) {
      var pointList = JSON.parse(JSON.stringify(pointLists)) //深拷贝防止影响sitelist数组
      for (var i = 0; i < pointList.length; i++) {
        var bigsum = 0
        if (pointList[i].areaList.length > 0) {
          for (var j = 0; j < pointList[i].areaList.length; j++) {
            var sum = 0
            for (var m = 0; m < pointList[i].areaList[j].sourceDataList.length; m++) {
              if (pointList[i].areaList[j].sourceDataList.length > 0) {
                var changObj = pointList[i].areaList[j].sourceDataList[m]
                for (let item in changObj) {
                  // console.log('属性',changObj[item]);
                  if (item.indexOf('Standard') != -1 || changObj[item] == '请选择' || item.indexOf('Unit') != -1 || changObj[item] === '') {
                    delete changObj[item]
                  }
                }
                // console.log('changeObj:',changObj);
                var arr = Object.keys(changObj)
                // console.log('arr:',arr);
                // console.log('分区长度：', arr.length)
                sum += arr.length - 4
                pointList[i].areaList[j].count = sum
              } else {
                pointList[i].areaList[j].count = 0
              }
            }
            bigsum += pointList[i].areaList[j].count
            pointList[i].count = bigsum
          }
        } else {
          pointList[i].count = 0
        }
      }
      this.getsiteList(pointList)
    },
    //目录结构tree数据转换
    getsiteList(list) {
      var changeList = JSON.parse(JSON.stringify(list))
      changeList.forEach(element => {
        var obj = {
          label: element.siteName + (element.count > 0 ? `(${element.count})` : ''),
          children: element.areaList && element.areaList.length > 0 ? this.copyTree(element.areaList) : element.areaList
        }
        this.treedata.push(obj)
      })
      // console.log('改变后的tree:', this.treedata);
    },
    //递归转换数据格式中每层的格式
    copyTree(childrenList) {
      childrenList.forEach(item => {
        item.label = item.areaName + (item.count > 0 ? `(${item.count})` : '')
        item.children = item.childrenList
        if (item.childrenList && item.childrenList.length) {
          this.copyTree(item.children)
        }
      })
      return childrenList
    },
    seleteResult() {
      this.resultIf = true
      this.print()
    },
    seleteResultFull() {
      this.resultIf = false
      this.print()
    },
    //打印
    print() {
      let bodyEle = $('#page000')
      // console.log(bodyEle)
      // //动态添加页码 通过绝对定位
      // let hgh = 368.51;
      let hgh = 369.11
      let total = this.pagenum - 1
      // console.log(total)
      for (let i = 1; i < this.pagenum; i++) {
        let a = '第 ' + i + ' 页 共 ' + total + ' 页 '
        if (i >= 1 && i <= 4) {
          hgh--
          let foot = '<div style="width:200px;position:absolute;top:' + hgh * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 4 && i <= 6) {
          let foot = '<div style="width:200px;position:absolute;top:' + 365 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 6 && i <= 10) {
          let foot = '<div style="width:200px;position:absolute;top:' + 364.5 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 10 && i <= 24) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.8 * i + 'mm;padding-top:20mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 24 && i <= 38) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.7 * i + 'mm;padding-top:22mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 38 && i <= 50) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.7 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 50 && i <= 70) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.7 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 70 && i <= 140) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.6 * i + 'mm;padding-top:17mm;z-index:999">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 140 && i <= 190) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.58 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 190 && i <= 250) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.58 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 250 && i <= 280) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.58 * i + 'mm;padding-top:17mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 280 && i <= 320) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.57 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 320 && i <= 380) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.57 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 380 && i <= 420) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.56 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 420 && i <= 480) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.56 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 480 && i <= 540) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.56 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 540 && i <= 600) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.55 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 600 && i <= 680) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.55 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 680 && i <= 740) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.55 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        } else if (i > 740 && i <= 800) {
          let foot = '<div style="width:200px;position:absolute;top:' + 363.55 * i + 'mm;padding-top:20mm;z-index:999;">' + a + '<div>'
          bodyEle.append(foot)
        }
      }
      this.$nextTick(() => {
        //解决第一遍数据不回显问题
        this.printJson()
        this.showMemberInfo = true
        this.printObj.popTitle = this.printdata.username + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + '编号：' + this.printdata.printcode
      })
    },

    printJson() {
      // let test = document.getElementById('fbody').innerHTML
      // let page = this.$refs.cover.covers + this.$refs.homepage.homepageData + test
      // document.getElementById('printSection').innerHTML = page;
      // this.$print(this.$refs.table);

      if (this.resultIf == true) {
        this.$refs.cover1.getcover1()
        let page1 = this.$refs.cover1.covers1
        document.getElementById('noheaderBody').innerHTML = page1
        this.$refs.homepage1.getpage1()
        let test = document.getElementById('fbody').innerHTML
        let page = this.$refs.homepage1.homepageData1 + test
        document.getElementById('headerBody').innerHTML = page
      } else {
        this.$refs.cover.getcover()
        let page1 = this.$refs.cover.covers
        document.getElementById('noheaderBody').innerHTML = page1
        this.$refs.homepage.getpage()
        let test = document.getElementById('fbody').innerHTML
        let page = this.$refs.homepage.homepageData + test
        document.getElementById('headerBody').innerHTML = page
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.imgshowListener)
  }
}
</script>
<style scoped>
#show {
  display: none;
}

#fbodystyle {
  padding-bottom: 25px;
  padding-left: 15px;
  padding-top: 35px;
}

#btnList {
  height: 40px;
  width: 98.8%;
  padding: 10px;
  background-color: whitesmoke;
}

.left {
  float: left;
}

.printcss {
  height: 40px;
  line-height: 1px;
  float: left;
  border: 1px solid gainsboro;
  box-shadow: 1px 1px 4px gainsboro;
  /* border-bottom: 1px solid silver; */
  border-radius: 5px;
  text-align: center;
  margin-left: 30px;
}

/* 回到顶部 */
.top {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 50px;
  right: 100px;
}

/* ----------------------------------w------------------------------------ */
/* #main {
  overflow-x: hidden; */
/* width: 1800px;
} */

#origintotal {
  margin-left: 25%;
  width: 840px;
  border: 1px solid gainsboro;
  box-shadow: 1px 2px 13px grey;
  margin-top: 30px;
}

#center {
  width: 840px;
  margin: 0px auto;
}

#catalog {
  border: 1px solid gainsboro;
  width: 20%;
  height: 70%;
  overflow-y: auto;
  position: fixed;
  /* position: fixed;
    display:flex;
    justify-content:flex-start;
    flex-direction: column; */
  left: 10px;
}

#catatop {
  text-align: center;
  font-size: 22px;
  letter-spacing: 20px;
}

#catacenter {
  margin: 10px 100px;
  font-size: 16px;
}

.span1 {
  display: inline-block;
  width: 100px;
  text-align: left;
}

.span2 {
  position: absolute;
  left: 300px;
}

#attachcenter {
  margin: 10px 50px;
  font-size: 16px;
}

#attachbottom p {
  margin-left: 400px;
  text-decoration: underline;
}
</style>