/*
 * @Author: 白一桐
 * @Date: 2022-10-24 11:50:51
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-04-17 16:21:10
 */
// axios二次封装
import axios from 'axios'

const request = axios.create()

// 设置axios拦截器：请求拦截器
request.interceptors.request.use(config => {
  if (config.method === 'get') {
    config.data = true
  }
  if(config.url.startsWith('/lps')){
    config.headers.Authorization = sessionStorage.getItem('token')
    config.headers['content-type'] = 'application/json;charset=utf-8'
  }
  return config
}, err => {
  // 请求未成功发出，如：没有网络...
  return Promise.reject(err)
})
// 响应拦截器
axios.interceptors.response.use(response => {
  // 在接收响应做些什么，例如跳转到登录页
  return response
}, err => {
  // 对响应错误做点什么
  return Promise.reject(err)
})
export default request
