/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:34:44 
 * @Last Modified by:   王翎爆 
 * @Last Modified time: 2023-03-30 09:34:44 
 */
<template>
    <div>
        <Menu></Menu>
        <el-row style="overflow: hidden;">
            <el-col :span="24" :offset="1">
                <div class="grid-content bg-purple-dark" id="first">
                    <el-button @click="dialogFormVisibleSift = true">筛选</el-button>
                    <el-button @click="dialogFormVisibleAdd = true">添加设备</el-button>
                    <el-button @click="portableEquipment(1)">外携设备记录</el-button>
                    <el-button @click="portableEquipment(2)">设备使用记录</el-button>
                    <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title"
                        name="equipment.xls">
                        <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                        <el-button>导出Excel</el-button>
                    </download-excel>
                </div>
                <!-- 筛选 -->
                <el-dialog title="筛选" :visible.sync="dialogFormVisibleSift" style="width: 60%;">
                    <el-form :model="Siftform">
                        <el-form-item label="设备编号" label-width="120px">
                            <el-input v-model="Siftform.sid" autocomplete="off" placeholder="请输入想要搜索的设备编号(模糊)">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备名称" label-width="120px">
                            <el-input v-model="Siftform.sname" autocomplete="off" placeholder="请输入想要搜索的设备名称(模糊)">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="出厂编号" label-width="120px">
                            <el-input v-model="Siftform.facno" autocomplete="off" placeholder="请输入想要搜索的出厂编号(模糊)">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="型号" label-width="120px">
                            <el-input v-model="Siftform.stype" autocomplete="off" placeholder="请输入想要搜索的设备型号"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="deviceSift()">筛 选</el-button>
                        <el-button @click="dialogFormVisibleSift = false">取 消</el-button>
                    </div>
                </el-dialog>
                <!-- 外携仪器设备使用登记表 -->
                <el-dialog :title="portableTitle" :visible.sync="dialogFormVisibleRegister" style="width:50%;left:280px">
                    <div>
                        <ul>
                            <li v-for="item in portableData" :key="item.id" style="margin-bottom: 8px;">
                                <img src="@/assets/excel.png" style="vertical-align: bottom;" />&nbsp;
                                <a style="font-size: 16px" @click.prevent="portableDown(item.obsuri)" href="">{{
                                    item.filename }}</a>
                            </li>
                        </ul>
                    </div>
                    <!-- <el-table>
                        <el-table-column prop="rfile">
                            <i class="el-icon-document" style="font-size:55px;font-weight:500"></i>
                        </el-table-column>
                        <el-table-column prop="rdownload">
                            <a href="">下载</a>
                        </el-table-column>
                    </el-table> -->
                </el-dialog>
                <!-- 添加设备 -->
                <el-dialog :title="Addform.title" :visible.sync="dialogFormVisibleAdd" @close="closeDevice">
                    <el-form ref="dialogForm" :model="Addform" :rules="Addform.rules">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="序号" label-width="120px" prop="eno">
                                    <el-input v-model="Addform.eno" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="检定校准周期" label-width="120px">
                                    <el-input v-model="Addform.vccycle" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="设备编号" label-width="120px" prop="eqno">
                                    <el-input v-model="Addform.eqno" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="检定单位" label-width="120px">
                                    <el-input v-model="Addform.vforg" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="设备名称" label-width="120px" prop="eqname">
                                    <el-input v-model="Addform.eqname" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="用途" label-width="120px">
                                    <el-input v-model="Addform.purpose" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="型号" label-width="120px" prop="model">
                                    <el-input v-model="Addform.model" autocomplete="off"></el-input>
                                </el-form-item>

                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="使用部门" label-width="120px">
                                    <el-input v-model="Addform.userdept" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="出厂编号" label-width="120px">
                                    <el-input v-model="Addform.facno" autocomplete="off"></el-input>
                                </el-form-item>

                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="存放地点" label-width="120px">
                                    <el-input v-model="Addform.location" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="制造厂" label-width="120px">
                                    <el-input v-model="Addform.manufacturer" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="授权使用人" label-width="120px">
                                    <el-input v-model="Addform.auth" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="量程" label-width="120px">
                                    <el-input v-model="Addform.erange" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="专管人" label-width="120px">
                                    <el-input v-model="Addform.charge" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="精度" label-width="120px">
                                    <el-input v-model="Addform.accuracy" autocomplete="off"></el-input>
                                </el-form-item>

                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="单价" label-width="120px">
                                    <el-input v-model="Addform.price" autocomplete="off"></el-input>元
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="购买日期" label-width="120px">
                                    <el-date-picker v-model="Addform.buydate" value-format="yyyyMMdd" type="date"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="库存状态" label-width="120px">
                                    <el-select v-model="Addform.flag" style="width: 170px" placeholder="请选择设备状态">
                                        <el-option v-for="item in Addform.flags" :key="item.dictcode"
                                            :label="item.dictcodemeta" :value="item.dictcode"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="备注" label-width="120px">
                                    <el-input v-model="Addform.remarks" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="addEquipment()">确 定</el-button>
                        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
                    </div>
                </el-dialog>
                <!-- 出入库 -->
                <el-dialog title="出入库" :visible.sync="dialogFormVisibleInOut" @close="closeDevice"
                    style="width: 60%; left: 600px;">
                    <el-form :model="InOutform">
                        <el-form-item label="设备名称" label-width="120px">
                            <el-input v-model="InOutform.sname" autocomplete="off" readonly>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备编号" label-width="120px">
                            <el-input v-model="InOutform.snumber" autocomplete="off" readonly>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="出入库类型" label-width="120px">
                            <el-select v-model="InOutform.flag" style="width: 170px" placeholder="请选择设备状态">
                                <el-option v-for="item in Addform.flags" :key="item.dictcode" :label="item.dictcodemeta"
                                    :value="item.dictcode"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" label-width="120px">
                            <el-input v-model="InOutform.remarks" type="textarea" autocomplete="off" placeholder="备注">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="inOutBoundEdit()">确 定</el-button>
                        <el-button @click="dialogFormVisibleInOut = false">取 消</el-button>
                    </div>
                </el-dialog>
                <el-table :data="tableData" border style="width:95%;margin-top: 20px;">
                    <el-table-column prop="id" label="ID" width="50">
                    </el-table-column>
                    <el-table-column prop="eno" label="序号" width="50">
                    </el-table-column>
                    <el-table-column prop="eqno" label="仪器编号" width="100">
                    </el-table-column>
                    <el-table-column prop="eqname" label="设备名称">
                    </el-table-column>
                    <el-table-column prop="model" label="型号">
                    </el-table-column>
                    <el-table-column prop="facno" label="出厂编号">
                    </el-table-column>
                    <el-table-column prop="manufacturer" label="制造厂">
                    </el-table-column>
                    <el-table-column prop="erange" label="量程">
                    </el-table-column>
                    <el-table-column prop="accuracy" label="精度">
                    </el-table-column>
                    <el-table-column prop="buydate" label="购买日期">
                    </el-table-column>
                    <el-table-column prop="price" label="单价">
                    </el-table-column>
                    <el-table-column prop="vccycle" label="校准周期">
                    </el-table-column>
                    <el-table-column prop="vforg" label="检定单位">
                    </el-table-column>
                    <el-table-column prop="purpose" label="用途">
                    </el-table-column>
                    <el-table-column prop="userdept" label="使用部门">
                    </el-table-column>
                    <el-table-column prop="location" label="存放地点">
                    </el-table-column>
                    <el-table-column prop="auth" label="授权使用人" width="120">
                    </el-table-column>
                    <el-table-column prop="charge" label="专管人">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" width="100">
                    </el-table-column>
                    <el-table-column prop="flag_meta" label="状态" width="80">
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="113">
                        <template slot-scope="scope">
                            <el-button type="dange" @click="editDevice(scope.$index, scope.row)">编辑
                            </el-button> <br>
                            <el-button id="down" type="dange" @click="inOutBound(scope.row)">出入库</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页-->
                <el-pagination layout=" total,sizes,prev,pager,next,jumper" :total="total" @size-change='handleSizeChange'
                    @current-change='handleCurrentChange' :current-page='queryInfo.pagenum'
                    :page-sizes='[10, 20, 30, 40, 50]' :page-size='queryInfo.pagesize'>
                </el-pagination>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Menu from '@/components/Menu.vue';
export default {
    name: 'Equipment',
    data() {
        return {
            eid: '',
            tableData: [],
            dialogFormVisibleSift: false,
            Siftform: {
                sid: '',
                sname: '',
                stype: '',
                facno: ''
            },
            dialogFormVisibleAdd: false,
            Addform: {
                title: '添加设备',
                eno: '',
                eqno: '',
                eqname: '',
                model: '',
                facno: '',
                manufacturer: '',
                erange: '',
                accuracy: '',
                buydate: '',
                price: '',
                vccycle: '',
                vforg: '',
                purpose: '',
                userdept: '',
                location: '',
                auth: '',
                charge: '',
                remarks: '',
                flag: '',
                flags: null,
                rules: {
                    eno: [{ required: true, message: '序号不能为空', trigger: 'blur' }],
                    eqno: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
                    eqname: [{ required: true, message: '设备名称不能为空', trigger: 'blur' }],
                    model: [{ required: true, message: '设备类型不能为空', trigger: 'blur' }]
                }
            },
            dialogFormVisibleInOut: false,
            InOutform: {
                sname: '',
                snumber: '',
                flag: '',
                remarks: ''
            },
            dialogFormVisibleRegister: false,
            portableData: [],
            portableTitle: '',
            title: "德创公司表格",
            json_fields: {
                "ID": 'id',
                "序号": 'eno',
                "设备编号": 'eqno',
                "设备名称": 'eqname',
                "型号": 'model',
                "出厂编号": 'facno',
                "制造厂": 'manufacturer',
                "量程": 'erange',
                "精度": 'accuracy',
                "购买日期": 'buydate',
                "单价(元）": 'price',
                "检定校准周期": 'vccycle',
                "检定单位": 'vforg',
                "用途": 'purpose',
                "使用部门": 'userdept',
                "存放地点": 'location',
                "授权使用人": 'auth',
                "专管人": 'charge',
                "备注": 'remarks',
                "库存状态": 'flag_meta',
            },
            DetailsForm: [
                {
                    id: "",
                    eno: "",
                    eqno: "",
                    eqname: "",
                    model: "",
                    facno: "",
                    manufacturer: "",
                    erange: "",
                    accuracy: "",
                    buydate: "",
                    price: "",
                    vccycle: "",
                    vforg: "",
                    purpose: "",
                    userdept: "",
                    location: "",
                    auth: "",
                    charge: "",
                    remarks: "",
                    flag_meta: "",
                }
            ],
            total: 0,
            queryInfo: {
                pagenum: 1,
                pagesize: 20
            }
        }
    },
    components: { Menu },
    mounted() {
        this.initEquipment()
        this.initFlags()
    },
    methods: {
        initFlags() {
            this.$axios({
                method: 'get',
                url: '/lps/sys/dict',
                params: {
                    dicttype: 'eqflag'
                }
            }).then(res => {
                this.Addform.flags = res.data
            })
        },
        initEquipment() {
            this.$axios({
                method: 'get',
                url: '/lps/jobs/equipment',
                params: {
                    eqno: this.Siftform.sid,
                    eqname: this.Siftform.sname,
                    model: this.Siftform.stype,
                    current: this.queryInfo.pagenum,
                    size: this.queryInfo.pagesize
                }
            }).then(res => {
                if (res.data != null) {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                    this.DetailsForm = res.data.rows
                }
            }).catch(error => {
                console.log(error);
                this.$message.error(error.message)
            })
        },
        deviceSift() {
            this.dialogFormVisibleSift = false
            this.$axios({
                method: 'get',
                url: '/lps/jobs/equipment',
                params: {
                    eqno: this.Siftform.sid,
                    eqname: this.Siftform.sname,
                    model: this.Siftform.stype,
                    facno: this.Siftform.facno
                }
            }).then(res => {
                if (res.data != null) {
                    this.tableData = res.data.rows
                    this.total = res.data.total
                }
            })
        },
        handleSizeChange(size) {
            this.queryInfo.pagesize = size
            this.initEquipment()
        },
        handleCurrentChange(num) {
            this.queryInfo.pagenum = num
            this.initEquipment()
        },
        // deriveExcel() {
        //     console.log(11111111);
        //     this.$axios({
        //         method: 'get',
        //         url: '/lps/jobs/equipment',
        //         params: {
        //             current: this.queryInfo.pagenum,
        //             size: this.total
        //         }
        //     }).then(res => {
        //         console.log(res.data.rows);
        //         if (res.data != null) {
        //             this.DetailsForm = res.data.rows
        //             console.log('Excel:',this.DetailsForm);
        //         }
        //     })
        // },
        addEquipment() {
            this.$refs['dialogForm'].validate((valid) => {
                if (valid) {
                    if (this.Addform.title == '添加设备') {
                        this.$axios({
                            method: 'POST',
                            url: '/lps/jobs/equipment',
                            data: {
                                eno: this.Addform.eno,
                                eqno: this.Addform.eqno,
                                eqname: this.Addform.eqname,
                                model: this.Addform.model,
                                facno: this.Addform.facno,
                                manufacturer: this.Addform.manufacturer,
                                erange: this.Addform.erange,
                                accuracy: this.Addform.accuracy,
                                buydate: this.Addform.buydate,
                                price: this.Addform.price,
                                vccycle: this.Addform.vccycle,
                                vforg: this.Addform.vforg,
                                purpose: this.Addform.purpose,
                                userdept: this.Addform.userdept,
                                location: this.Addform.location,
                                auth: this.Addform.auth,
                                charge: this.Addform.charge,
                                remarks: this.Addform.remarks,
                                flag: this.Addform.flag
                            }
                        }).then(res => {
                            if (res.data.id != null) {
                                this.dialogFormVisibleAdd = false
                                this.$message.success('添加设备成功！')
                                this.initEquipment()
                            }
                        })
                    } else {
                        this.$axios({
                            method: 'put',
                            url: '/lps/jobs/equipment/' + this.eid,
                            data: {
                                eno: this.Addform.eno,
                                eqno: this.Addform.eqno,
                                eqname: this.Addform.eqname,
                                model: this.Addform.model,
                                facno: this.Addform.facno,
                                manufacturer: this.Addform.manufacturer,
                                erange: this.Addform.erange,
                                accuracy: this.Addform.accuracy,
                                buydate: this.Addform.buydate,
                                price: this.Addform.price,
                                vccycle: this.Addform.vccycle,
                                vforg: this.Addform.vforg,
                                purpose: this.Addform.purpose,
                                userdept: this.Addform.userdept,
                                location: this.Addform.location,
                                auth: this.Addform.auth,
                                charge: this.Addform.charge,
                                remarks: this.Addform.remarks,
                                flag: this.Addform.flag
                            }
                        }).then(res => {
                            if (res.data.total != null) {
                                this.dialogFormVisibleAdd = false
                                this.$message.success('修改设备信息成功！')
                                this.initEquipment()
                            }
                        })
                    }
                }
            })
        },
        portableEquipment(value) {
            this.dialogFormVisibleRegister = true
            let type
            if (value == 1) {
                type = '01'
                this.portableTitle = '外携设备使用记录'
            } else {
                type = '02'
                this.portableTitle = '设备使用记录'
            }
            this.$axios({
                method: 'get',
                url: '/lps/jobs/equipment/eqfiles',
                params: {
                    filetype: type,
                    current: this.queryInfo.pagenum,
                    size: this.queryInfo.pagesize
                }
            }).then(res => {
                if (res.data.rows != null) {
                    this.portableData = res.data.rows
                    console.log(res.data.rows);
                }
            })
        },
        portableDown(obsuri) {
            this.$axios({
                method: 'get',
                url: '/lps/hwcloud/obs/downurl',
                params: {
                    objname: obsuri
                }
            }).then(res => {
                if (res.data.url != null) {
                    window.location.href = res.data.url
                }
            })
        },
        editDevice(index, row) {
            this.Addform.title = '编辑设备'
            this.dialogFormVisibleAdd = true
            this.eid = row.id
            this.Addform.eno = row.eno
            this.Addform.eqno = row.eqno
            this.Addform.eqname = row.eqname
            this.Addform.model = row.model
            this.Addform.facno = row.facno
            this.Addform.manufacturer = row.manufacturer
            this.Addform.erange = row.erange
            this.Addform.accuracy = row.accuracy
            //this.$set(this.Addform, "buydate", row.buydate)q
            this.Addform.buydate = row.buydate
            this.Addform.price = row.price
            this.Addform.vccycle = row.vccycle
            this.Addform.vforg = row.vforg
            this.Addform.purpose = row.purpose
            this.Addform.userdept = row.userdept
            this.Addform.location = row.location
            this.Addform.auth = row.auth
            this.Addform.charge = row.charge
            this.Addform.remarks = row.remarks
            this.Addform.flag = row.flag
        },
        closeDevice() {
            this.Addform.title = '添加设备'
            this.Addform.eno = ''
            this.Addform.eqno = ''
            this.Addform.eqname = ''
            this.Addform.model = ''
            this.Addform.facno = ''
            this.Addform.manufacturer = ''
            this.Addform.erange = ''
            this.Addform.accuracy = ''
            this.Addform.buydate = ''
            this.Addform.price = ''
            this.Addform.vccycle = ''
            this.Addform.vforg = ''
            this.Addform.purpose = ''
            this.Addform.userdept = ''
            this.Addform.location = ''
            this.Addform.auth = ''
            this.Addform.charge = ''
            this.Addform.remarks = ''
            this.Addform.flag = ''
            this.InOutform.flag = ''
            this.InOutform.remarks = ''
        },
        inOutBound(row) {
            this.dialogFormVisibleInOut = true
            this.eid = row.id
            this.InOutform.sname = row.eqname
            this.InOutform.snumber = row.eqno
        },
        inOutBoundEdit() {
            this.$axios({
                method: 'put',
                url: '/lps/jobs/equipment/' + this.eid,
                data: {
                    flag: this.InOutform.flag,
                    remarks: this.InOutform.remarks
                }
            }).then(res => {
                if (res.data.total != null) {
                    this.dialogFormVisibleInOut = false
                    this.$message.success('出入库状态修改成功！')
                    this.initEquipment()
                }
            })
        }
    }
}
</script>
<style scoped>
.el-input {
    max-width: 300px;
}

#first {
    border-bottom: solid 1px #ced5db;
    margin-top: 10px;
    padding-bottom: 5px;
    display: inline-block;
    /* position: relative; */
}

#down {
    margin-top: 5px;
}

.export-excel-wrapper {
    display: inline-block;
    /* position: absolute; */
    margin-left: 10px;
}
</style>