<!--
 * @Author: 白一桐
 * @Date: 2023-01-14 13:42:47
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-09-21 17:03:27
 附件管理页面
-->
<template>
  <div id="box">
    <div id="tablePage">
      <form action="https://lps-hbdcjc-com.obs.cn-north-1.myhuaweicloud.com" method="post" enctype="multipart/form-data">
        <div style="display: none;">
          <p>Object key</p>
          <input type="text" name="key" v-model="key" style="width:400px" />
          <p>ACL</p>
          <input type="text" name="x-obs-acl" v-model="fromData.acl" style="width:400px" />
          <p>Content-Type</p>
          <input type="text" name="content-type" v-model="fromData.contenttype" style="width:400px" />
          <p>policy</p>
          <input type="text" name="policy" v-model="fromData.policy" style="width:800px" />
          <p>AccessKeyId</p>
          <input type="text" name="AccessKeyId" v-model="fromData.ak" style="width:400px" />
          <p>signature</p>
          <input type="text" name="signature" v-model="fromData.signature" style="width:400px" />
        </div>
        <div id="pages">
          <el-divider content-position="left">
            <slot name="br">
            </slot>
          </el-divider>
          <div style="margin: 10px;" class="no-print">
            <!-- <label for="upload-file"> 上传图片 </label> -->
            <input type="file" id="upload-file" @click="getjpgData" @change="upload($event)" />
          </div>
          <img :src="headerImage" class="show" alt="" srcset="" style="width: 70%;">
        </div>
      </form>
    </div>
  </div>
</template> 

<script>

export default {
  props: ['msgdata'],
  data() {
    return {
      headerImage: '',
      picValue: '',
      fromData: [], //上传图片时的key等数据
      key: '', //Object key
      formData2: {},
      imgName: '',

      objvalue: '',  //图片值
      id: '' //公司/用户id
    }
  },
  mounted() {
    this.getmsg()
  },
  methods: {
    getImg() {
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/downurl?objname=' + this.objvalue,
      }).then(res => {
        this.headerImage = res.data.url
      }).catch(error => {
        console.log(error.response.data)
      })
    },
    getmsg() { //判断是什么类型的数据来请求列表
      if (this.msgdata.owntype == "公司" && this.msgdata.objid == 0) {
        this.$axios({
          method: 'GET',
          url: '/lps/sys/others?objtype=证件&&objid=0&&owntype=公司&&ownid=' + this.msgdata.ownid,
        }).then(res => {
          console.log(res.data)
          for (let i = 0; i < res.data.length; i++) {
            this.objvalue = res.data[i].objvalue
            this.id = res.data[i].id
          }
          if (this.objvalue !== '') {
            this.getImg()
          }
        }).catch(error => {
          console.log(error.response.data)
        })
      } else if (this.msgdata.owntype == '公司' && this.msgdata.objid == 1) {
        this.$axios({
          method: 'GET',
          url: '/lps/sys/others?objtype=证件&&objid=1&&owntype=公司&&ownid=' + this.msgdata.ownid,
        }).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.objvalue = res.data[i].objvalue
            this.id = res.data[i].id
          }
          if (this.objvalue !== '') {
            this.getImg()
          }
        }).catch(error => {
          console.log(error.response.data)
        })
      } else if (this.msgdata.owntype == "用户") {
        this.$axios({
          method: 'GET',
          url: '/lps/sys/others?objtype=证件&&objid=0&&owntype=用户&&ownid=' + this.msgdata.ownid,
        }).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            this.objvalue = res.data[i].objvalue
            this.id = res.data[i].id
          }
          if (this.objvalue !== '') {
            this.getImg()
          }
          // this.getImg()
        }).catch(error => {
          console.log(error.response.data)
        })
      }
    },
    getjpgData() { //请求表单里需要的数据
      this.$axios({
        method: 'GET',
        url: '/lps/hwcloud/obs/upurl?filetype=jpg',
      }).then(res => {
        this.fromData = res.data
        this.fromData.acl = 'public-read'
        this.fromData.contenttype = 'text/plain'
        this.key = res.data.objpath + res.data.objname
        this.imgName = res.data.objname   //图片名称
        this.formData2 = {
          'key': res.data.objpath + res.data.objname,
          'x-obs-acl': 'public-read',
          'content-type': 'text/plain',
          'policy': res.data.policy,
          'AccessKeyId': res.data.ak,
          'signature': res.data.signature,
        }
      }).catch(error => {
        console.log(error.response.data)
      })
    },
    upload(e) { //上传
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.picValue = files[0];
      this.imgPreview(this.picValue); //判断是否要压缩
    },
    imgPreview(file) {
      let self = this;
      if (/^image/.test(file.type)) {
        // 创建一个reader
        let reader = new FileReader();
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file);
        // 读取成功后的回调
        reader.onloadend = function () {
          let result = this.result;
          let img = new Image();
          img.src = result;
          self.headerImage = this.result;
          self.postImg(this.result);
          // console.log(self.headerImage) 
        }
      }
    },
    dutoFile(dataurl, filename) { //把base64转换为file文件
      // console.log(dataurl)
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    postImg(data) { //需要请求的接口 
      //data 为图片的base64格式
      let upfile = this.dutoFile(data, 'file') //转换为file格式 
      this.formData2['file'] = upfile
      console.log(this.formData2)
      this.$axios({
        method: 'post',
        url: 'https://lps-hbdcjc-com.obs.cn-north-1.myhuaweicloud.com',
        data: {
          ...this.formData2
        },
        headers: {
          'Content-Type': 'multipart/form-data'//含有附件的表单提交的Content-Type
        },
        transformRequest: [data => {
          let formData = new FormData()
          for (let key in this.formData2) {
            formData.append(key, this.formData2[key])
          }
          return formData
        }],
      }).then(res => {
        // console.log(res)
        //根据不同类型上传不同的数据 并判断是否第一次上传
        if (this.msgdata.owntype == "公司" && this.msgdata.objid == 0) {
          if (this.objvalue == '') {
            console.log("first")
            this.$axios({
              method: 'POST',
              url: '/lps/sys/others',
              data: {
                "objtype": "证件",
                "objid": "0",  //营业执照
                "objvalue": this.imgName,
                "owntype": "公司",
                "ownid": this.msgdata.ownid
              }
            }).then(res => {
              this.getmsg()
              this.$message({
                message: '您已上传成功！',
                type: 'success'
              });
            }).catch(error => {
              console.log(error.response.data)
            })
          } else {
            console.log('put')
            this.$axios({
              method: 'PUT',
              url: '/lps/sys/others/' + this.id,
              data: {
                "objtype": "证件",
                "objid": "0",//营业执照
                "objvalue": this.imgName,
                "owntype": "公司",
                "ownid": this.msgdata.ownid
              }
            }).then(res => {
              this.getmsg()
              this.$message({
                message: '您已上传成功！',
                type: 'success'
              });
            }).catch(error => {
              console.log(error.response.data)
            })
          }

        } else if (this.msgdata.owntype == '公司' && this.msgdata.objid == 1) {
          if (this.objvalue == '') {
            this.$axios({
              method: 'POST',
              url: '/lps/sys/others',
              data: {
                "objtype": "证件",
                "objid": "1", //公司检测资质
                "objvalue": this.imgName,
                "owntype": "公司",
                "ownid": this.msgdata.ownid
              }
            }).then(res => {
              this.getmsg()
              this.$message({
                message: '您已上传成功！',
                type: 'success'
              });
            }).catch(error => {
              console.log(error.response.data)
            })
          } else {
            this.$axios({
              method: 'PUT',
              url: '/lps/sys/others/' + this.id,
              data: {
                "objtype": "证件",
                "objid": "1", //公司检测资质
                "objvalue": this.imgName,
                "owntype": "公司",
                "ownid": this.msgdata.ownid
              }
            }).then(res => {
              this.getmsg()
              this.$message({
                message: '您已上传成功！',
                type: 'success'
              });
            }).catch(error => {
              console.log(error.response.data)
            })
          }

        } else if (this.msgdata.owntype == "用户") {
          if (this.objvalue == '') {
            this.$axios({
              method: 'POST',
              url: '/lps/sys/others',
              data: {
                "objtype": "证件",
                "objid": "0", //用户资质
                "objvalue": this.imgName,
                "owntype": "用户",
                "ownid": this.msgdata.ownid
              }
            }).then(res => {
              this.getmsg()
              this.$message({
                message: '您已上传成功！',
                type: 'success'
              });
            }).catch(error => {
              console.log(error.response.data)
            })
          } else {
            this.$axios({
              method: 'PUT',
              url: '/lps/sys/others/' + this.id,
              data: {
                "objtype": "证件",
                "objid": "0", //用户资质
                "objvalue": this.imgName,
                "owntype": "用户",
                "ownid": this.msgdata.ownid
              }
            }).then(res => {
              this.getmsg()
              this.$message({
                message: '您已上传成功！',
                type: 'success'
              });
            }).catch(error => {
              console.log(error.response.data)
            })
          }
        }
      }).catch(err => { console.log(err) })
    },

  }
}
</script>
 
<style scoped>
/*  */
/* #box {
  display: flex;
} */

#tablePage {
  margin: 0 auto;
  padding-top: 15mm;
}


/* img {
  width: 180mm;
  max-height: 200mm;
  margin: 10mm;
} */
</style>
