<!--
 * @Author: 白一桐
 * @Date: 2022-10-27 11:38:07
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-10-13 09:31:33
-->
<template>
  <div>
    <Menu></Menu>
    <vue2-org-tree :data="data" :horizontal="true" @on-node-click="NodeClick" :renderContent="renderContent" v-if="isreload" />
    <!-- 编辑负责人 -->
    <el-dialog :visible.sync="editDPT" id="DPTiframe">
      <el-form>
        <div style="margin-bottom:20px">
          <label>负责人:</label>
        </div>
        <el-select v-model="defaultPeople" @change="change($event)" placeholder="请选择" style="width:100%">
          <el-option v-for="item in people" :key="item.userid" :label="item.relname" :value="item.userid">
          </el-option>
        </el-select>
      </el-form>
      <el-button id="DPTeditbtn" type="primary" @click="DPTaddDirector">添加</el-button>
    </el-dialog>

    <!-- 增加事务 -->
    <!-- 自定义多选 -->
    <el-form>
      <el-dialog :visible.sync="addSAF" id="SAFiframe">
        <div>

          <div class="list" v-for="(item, index) in arr" :key="index" :class="item.ischeck == true ? 'active' : ''" @click="sel(index, item)">
            {{ item.workname }}
          </div>
          <el-button id="SAFeditbtn" type="primary" @click="SAFconfirmEdit(data)">确认添加</el-button>
        </div>
      </el-dialog>
    </el-form>

    <!-- 编辑人员总页面 -->
    <el-form>
      <el-dialog :visible.sync="editUsers" id="userIframe">
        <div>
          <div v-for="(item, index) in users" :key="index" id="userList">
            <div>
              <i class="el-icon-circle-close" style="float: right;" v-show="editDeteleUsersBtn" @click="editDetele(item)"></i>
              <!-- <el-avatar :src="item.img"></el-avatar> -->
              <img src="../../assets/user2.png" alt="" width="35px">

            </div>
            <div style="display:inline-block">
              {{ item.relname }}
              <i class="el-icon-edit" v-show="editUsersBtn" @click="editUBtnIframe(item)"></i>
            </div>
          </div>
        </div>
        <div id="userbtn">
          <el-button type="info" icon="el-icon-user" circle @click="addOther"></el-button>
          <el-button type="primary" icon="el-icon-plus" circle @click="addUser"></el-button>
          <el-button type="primary" icon="el-icon-minus" circle @click="deteleUser"></el-button>
          <el-button type="primary" icon="el-icon-edit" circle @click="editUBtn"></el-button>
          <!-- <el-button type="success" icon="el-icon-check" circle @click="confirmUser"></el-button> -->
        </div>
      </el-dialog>
    </el-form>
    <!-- 新增人员 -->
    <el-dialog :visible.sync="addUserIframe" id="addUserIframe">
      <el-form>
        <div style="width: 100%;height: 30px;text-align: center;font-size: 16px;">添加新员工</div>
        <div prop="relname">
          <label><span style="color:red">*</span>姓名：</label>
          <el-input placeholder="请输入显示名称" v-model="useInformation.relname" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div prop="username">
          <label><span style="color:red">*</span>用户名：</label>
          <el-input placeholder="请输入用户名/账号" v-model="useInformation.name" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label><span style="color:red">*</span>密码：</label>
          <el-input placeholder="请输入密码" v-model="useInformation.password" show-password style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label>电话：</label>
          <el-input placeholder="请输入电话" v-model="useInformation.phone" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label>备注：</label>
          <el-input placeholder="请输入备注" v-model="useInformation.others" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <el-button id="submitAddUserbtn" type="primary" @click="submitAddUser" :plain="true">添加人员</el-button>
      </el-form>
    </el-dialog>

    <!-- 新增此部门以外的其他员工 -->
    <el-dialog :visible.sync="addOtherIframe" id="addOtherIframe">
      <div>
        <div style="width: 100%;height: 30px;text-align: center;font-size: 16px;">增加此部门以外的其他员工</div>
        <div class="otherPeoList" v-for="(item, index) in otherPeoArr" :key="index" :class="item.ischeck == true ? 'active' : ''" @click="otherPeosel(index, item)">
          <!-- <el-avatar src="../../assets/user1.png"></el-avatar> -->
          <img src="../../assets/user2.png" alt="" width="35px">
          <div>
            {{ item.relname }}
          </div>
        </div>
        <el-button id="selPeoDetermine" type="primary" @click="selPeoDetermine">确认添加</el-button>
      </div>
    </el-dialog>

    <!-- 编辑员工个人信息 -->
    <el-dialog :visible.sync="editUserIframe" id="addUserIframe">
      <el-form>
        <div style="width: 100%;height: 30px;text-align: center;font-size: 16px;">编辑员工个人信息</div>
        <div prop="relname">
          <label>姓名：</label>
          <el-input placeholder="请输入显示名称" v-model="personalInformation.relname" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div prop="username">
          <label>用户名：</label>
          <el-input placeholder="请输入用户名/账号" v-model="personalInformation.name" disabled style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label>密码：</label>
          <el-input placeholder="如不需要修改密码则可不填写此项" v-model="personalInformation.password" show-password style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label>电话：</label>
          <el-input placeholder="请输入电话" v-model="personalInformation.phone" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label>备注：</label>
          <el-input placeholder="请输入备注" v-model="personalInformation.others" style="margin-top:10px;margin-bottom: 10px;"></el-input>
        </div>
        <div>
          <label>状态：</label>
          <el-select placeholder="请选择" v-model="selDicttype" style="margin-top:10px;margin-bottom: 10px;width: 100%;">
            <el-option v-for="item in dicttypes" :key="item.dictcode" :label="item.dictcodemeta" :value="item.dictcode">
            </el-option>
          </el-select>
        </div>
        <el-button id="submitAddUserbtn" type="primary" @click="submitEditUser">确认编辑</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
  components: {
    Menu
  },
  data() {
    return {
      editDPT: false, //编辑负责人
      addSAF: false, //增加事务
      editUsers: false, //编辑人员
      editDeteleUsersBtn: false, //编辑人员中删除人员页面
      addUserIframe: false, //编辑人员中添加人员页面
      editUsersBtn: false, //编辑人员中编辑人员信息按钮
      isreload: true,
      addOtherIframe: false, //添加其他部门人员页面
      editUserIframe: false, //修改个人信息页面
      data: {}, //菜单树数据
      people: [], //编辑负责人时当前岗位的所有人员
      optPeople: {
        name: '',
        userid: '',
        deptid: ''
      }, //编辑负责人时已选人员的信息
      defaultPeople: '', //默认显示的负责人
      personalInformation: [], //修改时的个人信息
      otherPeoArr: [], //除本岗的其他人员
      selOtherPeoArr: [], //选择的除本岗外的其他人员
      arr: [], //事务
      selarr: [], //选择的事务
      users: [], //编辑人员总页面  人员列表
      personalData: '', //编辑人员  -----某个人的所有信息
      useInformation: {
        name: '', //用户名
        relname: '', //姓名
        password: '', //密码
        phone: '', //电话号
        others: '', //备注
        avatar: '', //头像
        deptid: '', //部门ID
        jobid: '', //岗位ID
        status: '00'
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        relname: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
      },
      dicttypes: [], //用户状态（离职、在职）
      selDicttype: '' //已选状态
      // value:''
    }
  },
  mounted() {
    this.dataRequest()
  },
  methods: {
    //刷新事件
    updateTree() {
      // console.log("刷新")
      // 卸载
      this.isreload = false
      // 建议加上 nextTick 微任务
      // 否则在同一事件内同时将tableShow设置false和true有可能导致组件渲染失败
      this.$nextTick(function () {
        // 加载
        this.isreload = true
      })
    },
    // 请求数据
    dataRequest() {
      this.$axios({
        method: 'GET',
        url: '/lps/sys/org/tree'
      })
        .then(res => {
          this.updateTree()
          // console.log(res.data)
          let resData = res.data
          const root = {}
          // 机构节点
          root.label = resData.orgname
          root.id = resData.id
          root.typeId = 'CPY'
          root.children = []
          // 部门节点
          let dep = resData.depts
          for (let i in dep) {
            const dept = {}
            dept.id = dep[i].id
            dept.label = dep[i].deptname
            dept.employees = dep[i].employees
            dept.typeId = 'DPT'
            if (dep[i].leader) {
              dept.leader = dep[i].leader.relname
            }
            dept.children = []
            // 岗位节点
            let jobs = dep[i].jobs
            for (let j in jobs) {
              const job = {}
              job.deptid = dep[i].id
              job.id = jobs[j].id
              job.label = jobs[j].jobname
              job.employees = jobs[j].employees
              job.typeId = 'SAF'
              job.children = []
              // 事务节点
              let works = jobs[j].works
              for (let w in works) {
                const work = {}
                work.id = works[w].id
                work.label = works[w].workname
                work.typeId = 'AFR'
                job.children.push(work)
              }
              dept.children.push(job)
            }
            root.children.push(dept)
          }
          // console.log(root)
          this.data = root
        })
        .catch(error => {
          console.log(error)
        })
    },
    //渲染数据
    renderContent(h, data) {
      //公司层级   添加删除部门按钮
      if (data.typeId == 'CPY') {
        return (
          <div>
            <el-popover placement="bottom-start" trigger="hover">
              <div v-show="CPYBasicSwich" class="CPYfloating" style="margin-left:20px">
                <el-button
                  type="primary"
                  onClick={() => {
                    this.CPYadds(data)
                  }}
                >
                  增加部门
                </el-button>
              </div>
              <div slot="reference">
                <div class="treeItem">
                  <span class="item_name">{data.label}</span>
                </div>
              </div>
            </el-popover>
          </div>
        )
      }
      //部门层级   添加删除编辑按钮
      else if (data.typeId == 'DPT') {
        //展示部门的加号减号按钮
        return (
          <div>
            <el-popover placement="bottom-start" trigger="hover" width="100">
              <div v-show="DPTBasicSwich" class="DPTfloating">
                <el-button
                  type="primary"
                  onClick={() => {
                    this.DPTadds(data)
                  }}
                  style="margin-left:20px;margin-bottom:5px"
                >
                  增 加 岗 位
                </el-button>
                <el-button
                  type="primary"
                  onClick={() => {
                    this.DPTedit(data)
                  }}
                  style="margin-left:20px;margin-bottom:5px"
                >
                  编辑负责人
                </el-button>
                <el-button
                  type="danger"
                  onClick={() => {
                    this.DPTdeletes(data)
                  }}
                  style="margin-left:20px;margin-bottom:5px"
                >
                  删 除 部 门
                </el-button>
              </div>
              <div slot="reference">
                <div class="treeItem">
                  <span>
                    {data.label} {data.employees}人{' '}
                  </span>
                </div>
                <div style="font-size:15px;line-height:20px;">{data.leader}</div>
              </div>
            </el-popover>
          </div>
        )
      }
      //岗位层级  添加删除编辑按钮
      else if (data.typeId == 'SAF') {
        //展示员工的加号减号按钮
        return (
          <div>
            <el-popover placement="bottom-start" trigger="hover" width="100">
              <div v-show="SAFBasicSwich" class="SAFfloating">
                <el-button
                  type="primary"
                  onClick={() => {
                    this.SAFadds(data)
                  }}
                  style="margin-left:20px;margin-bottom:5px"
                >
                  新增事务
                </el-button>
                <el-button
                  type="primary"
                  onClick={() => {
                    this.SAFuser(data)
                  }}
                  style="margin-left:20px;margin-bottom:5px"
                >
                  编辑人员
                </el-button>
                <el-button
                  type="danger"
                  onClick={() => {
                    this.SAFdeletes(data)
                  }}
                  style="margin-left:20px;margin-bottom:5px"
                >
                  删除岗位
                </el-button>
              </div>
              <div slot="reference">
                <div class="treeItem">
                  <span>
                    {data.label} {data.employees}人
                  </span>
                </div>
                <div style="font-size:15px;line-height:20px;"></div>
              </div>
            </el-popover>
          </div>
        )
      } else if (data.typeId == 'AFR') {
        return (
          <div>
            <el-popover placement="bottom-start" trigger="hover" width="100">
              <div v-show="SAFBasicSwich" class="SAFfloating">
                <el-button
                  type="danger"
                  onClick={() => {
                    this.AFRdetele(data)
                  }}
                  style="margin-left:20px"
                >
                  删除事务
                </el-button>
              </div>
              <div slot="reference">
                <div class="treeItem">
                  <span>{data.label}</span>
                </div>
              </div>
            </el-popover>
          </div>
        )
        // return (<span>{data.label}</span>)
      }
    },
    //点击事件  点击展示按钮
    NodeClick(e, data) {},
    // -----------------------------------公司层级---------------------------------------//
    //添加部门操作 点击弹出弹出层
    CPYadds(data) {
      console.log(data)
      this.$prompt('请输入部门', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          // console.log(value)
          if (value) {
            this.$axios({
              method: 'POST',
              url: '/lps/sys/dept',
              data: { deptname: value }
            })
              .then(res => {
                this.isreload = false
                console.log(res)
                this.$message({
                  type: 'success',
                  message: '您添加的部门是: ' + value
                })
                this.dataRequest()
              })
              .catch(error => {
                console.log(error)
                this.dataRequest()
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
    },
    // ---------------------------------- 部门层级 ----------------------------------------//
    // 添加岗位操作
    DPTadds(data) {
      console.log(data)
      this.$prompt('请输入岗位', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          console.log(value)
          if (value) {
            this.$axios({
              method: 'POST',
              url: '/lps/sys/job',
              data: {
                deptid: data.id,
                jobname: value
              }
            })
              .then(res => {
                console.log(res.data)
                this.$message({
                  type: 'success',
                  message: '您添加的岗位是: ' + value
                })
                this.dataRequest()
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
    },
    //删除部门操作
    DPTdeletes(data) {
      console.log(data)
      if (data.children && data.children.length > 0) {
        this.$message.info('当前部门存在子部门，请先删除子部门')
      } else {
        this.$confirm('确定要删除该数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$axios({
              method: 'DELETE',
              url: '/lps/sys/dept/' + data.id
            })
              .then(res => {
                console.log(res.data)
                console.log('删除成功')
                this.dataRequest()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(() => {})
      }
    },
    //点击进入编辑部门负责人页面
    DPTedit(data) {
      this.optPeople.deptid = data.id
      this.editDPT = true
      this.defaultPeople = '' //进入页面后默认选择的负责人置空
      this.$axios({
        method: 'GET',
        url: '/lps/sys/dept/touser?deptid=' + data.id
      })
        .then(res => {
          this.people = res.data
          if (res.data.length != 0) {
            for (let i in res.data) {
              this.optPeople.name = res.data[i].relname
              this.optPeople.userid = res.data[i].userid
              if (res.data[i].isleader) {
                this.defaultPeople = res.data[i].userid //重新渲染已选的默认负责人
              }
            }
          } else {
            this.defaultPeople = ''
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    change(event) {
      //获取选择的人的id
      this.optPeople.userid = event //获取option对应的value值
      console.log(event)
    },
    // 确认编辑负责人按钮
    DPTaddDirector() {
      let deptid = this.optPeople.deptid
      this.$axios({
        method: 'PUT',
        url: '/lps/sys/dept/leader/' + deptid,
        data: {
          userid: this.optPeople.userid
        }
      })
        .then(res => {
          console.log(res.data)
          this.editDPT = false
          this.dataRequest()
        })
        .catch(error => {
          console.log(error)
        })
    },

    // -----------------------------------岗位层级 --------------------------------------//
    //  点击进入新增事务页面
    SAFadds(data) {
      // console.log(data)
      if (this.selarr != '') {
        this.selarr = []
        // console.log(this.selarr)
      }
      this.addSAF = true
      //请求事务的接口
      this.$axios({
        method: 'GET',
        url: '/lps/sys/job/towork?jobid=' + data.id
      })
        .then(res => {
          // console.log(res.data)
          for (let i in res.data) {
            if (res.data[i].id) {
              res.data[i].ischeck = true
              res.data[i].jobid = data.id
            } else {
              res.data[i].ischeck = false
              res.data[i].jobid = data.id
            }
          }
          this.arr = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    //  点击增加事务  （变色）
    sel(index, item) {
      let arr = this.arr
      let selarr = this.selarr
      if (arr[index].ischeck == false) {
        arr[index].ischeck = true
        selarr.push(item)
      }
      this.arr = arr
      this.selarr = selarr
    },
    // 确认添加事务
    SAFconfirmEdit() {
      console.log(this.selarr)
      let uparr = []
      for (let i in this.selarr) {
        let a = {}
        a.jobid = this.selarr[i].jobid
        a.workid = this.selarr[i].workid
        uparr.push(a)
      }
      console.log(uparr)
      if (this.selarr.length != 0) {
        this.$axios({
          method: 'POST',
          url: '/lps/sys/job/towork',
          data: uparr
        })
          .then(res => {
            console.log(res)
            this.dataRequest()
            this.addSAF = false
            this.selarr = []
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    //===============编辑人员开始======================
    //最外层 编辑人员进入人员列表按钮
    SAFuser(data) {
      console.log(data)
      this.editUsers = true
      this.editUsersBtn = false
      this.editDeteleUsersBtn = false
      this.useInformation.deptid = data.deptid
      this.useInformation.jobid = data.id
      this.$axios({
        method: 'GET',
        url: '/lps/sys/user/tojob?jobid=' + data.id
      })
        .then(res => {
          // console.log(res.data)
          this.users = res.data
          // for (let i in this.users) {
          //   this.users[i].img = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          // }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // 添加其他部门员工
    addOther() {
      if (this.selOtherPeoArr != '') {
        this.selOtherPeoArr = []
        console.log(this.selOtherPeoArr)
      }
      this.addOtherIframe = true
      console.log(this.useInformation)
      console.log(this.useInformation.deptid)
      console.log(this.useInformation.jobid)
      let deptid = this.useInformation.deptid //部门id
      let notinjobid = this.useInformation.jobid //岗位id
      this.$axios({
        method: 'GET',
        url: '/lps/sys/dept/touser?deptid=' + deptid + '&&notinjobid=' + notinjobid
      })
        .then(res => {
          console.log(res.data)
          for (let i in res.data) {
            res.data[i].ischeck = false
          }
          this.otherPeoArr = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    //  点击添加其他部门员工  （变色）
    otherPeosel(index, item) {
      let otherPeoArr = this.otherPeoArr
      let selOtherPeoArr = this.selOtherPeoArr
      if (otherPeoArr[index].ischeck == false) {
        otherPeoArr[index].ischeck = true
        selOtherPeoArr.push(item)
      }
      this.otherPeoArr = otherPeoArr
      this.selOtherPeoArr = selOtherPeoArr
    },
    // 确定添加其他岗位的员工按钮
    selPeoDetermine() {
      console.log(this.selOtherPeoArr)
      // console.log(this.useInformation.jobid)
      let updataArr = []
      for (let i in this.selOtherPeoArr) {
        let updata = {}
        updata.userid = this.selOtherPeoArr[i].userid
        // console.log(this.selOtherPeoArr[i].userid)
        updata.jobid = this.useInformation.jobid
        updataArr.push(updata)
      }
      console.log(updataArr)
      this.$axios({
        method: 'POST',
        url: '/lps/sys/user/tojob',
        data: updataArr
      })
        .then(res => {
          console.log(res)
          this.addOtherIframe = false
          this.$axios({
            method: 'GET',
            url: '/lps/sys/user/tojob?jobid=' + this.useInformation.jobid
          })
            .then(res => {
              // console.log(res.data)
              this.users = res.data
              // for (let i in this.users) {
              //   this.users[i].img = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              // }
            })
            .catch(error => {
              console.log(error)
            })
          this.dataRequest()
        })
        .catch(error => {
          console.log(error)
        })
    },
    //进到添加页面的加号按钮
    addUser() {
      this.addUserIframe = true
    },
    //提交添加人员信息的按钮
    submitAddUser() {
      console.log(this.useInformation)
      if (this.useInformation.relname == '') {
        this.$message.error('姓名不能为空')
      } else if (this.useInformation.name == '') {
        this.$message.error('用户名不能为空')
      } else if (this.useInformation.password == '') {
        this.$message.error('密码不能为空')
      } else {
        this.$axios({
          method: 'POST',
          url: '/lps/sys/user',
          data: this.useInformation
        })
          .then(res => {
            console.log(res)
            console.log(this.useInformation.jobid)
            this.addUserIframe = false //关闭当前页面
            // this.editUsers = false
            //重新渲染在岗人员列表
            this.$axios({
              method: 'GET',
              url: '/lps/sys/user/tojob?jobid=' + this.useInformation.jobid
            })
              .then(res => {
                // console.log(res.data)
                this.users = res.data
                // for (let i in this.users) {
                //   this.users[i].img = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                // }
              })
              .catch(error => {
                console.log(error)
              })
            this.dataRequest()
            this.useInformation.name = ''
            this.useInformation.others = ''
            this.useInformation.password = ''
            this.useInformation.relname = ''
            this.useInformation.phone = ''
          })
          .catch(error => {
            // console.log("000000")
            // console.log(error.response.data.message)
            this.$message.error(error.response.data.message)
          })
      }
    },
    //减号按钮  展示删除按钮
    deteleUser() {
      this.editUsersBtn = false
      if (this.editDeteleUsersBtn == false) {
        this.editDeteleUsersBtn = true
      } else {
        this.editDeteleUsersBtn = false
      }
    },
    //删除按钮  删除人员
    editDetele(item) {
      console.log(item)
      this.$axios({
        method: 'DELETE',
        url: '/lps/sys/user/tojob/' + item.id
      })
        .then(res => {
          console.log(res.data)
          //重新渲染在岗人员列表
          this.$axios({
            method: 'GET',
            url: '/lps/sys/user/tojob?jobid=' + this.useInformation.jobid
          })
            .then(res => {
              // console.log(res.data)
              this.users = res.data
              // for (let i in this.users) {
              //   this.users[i].img = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              // }
            })
            .catch(error => {
              console.log(error)
            })
          this.dataRequest()
        })
        .catch(error => {
          console.log(error)
        })
    },
    //小笔按钮  展示编辑按钮
    editUBtn() {
      this.editDeteleUsersBtn = false
      if (this.editUsersBtn == false) {
        this.editUsersBtn = true
      } else {
        this.editUsersBtn = false
      }
    },
    //编辑人员信息按钮   对人员进行编辑
    editUBtnIframe(item) {
      console.log(item)
      this.personalData = item
      this.editUserIframe = true
      this.$axios({
        method: 'GET',
        url: '/lps/sys/user/' + item.userid
      })
        .then(res => {
          console.log(res)
          this.personalInformation = res.data
          this.selDicttype = res.data.status
        })
        .catch(error => {
          console.log(error)
        })

      this.$axios({
        method: 'GET',
        url: '/lps/sys/dict?dicttype=userstatus'
      })
        .then(res => {
          console.log('-----------')
          console.log(res.data)
          this.dicttypes = res.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    //确认编辑个人信息
    submitEditUser() {
      console.log(this.personalInformation)
      console.log(this.personalData)
      console.log(this.selDicttype)
      this.$axios({
        method: 'PUT',
        url: '/lps/sys/user/' + this.personalData.userid,
        data: {
          relname: this.personalInformation.relname,
          password: this.personalInformation.password,
          phone: this.personalInformation.phone,
          others: this.personalInformation.others,
          avatar: this.personalInformation.avatar,
          status: this.selDicttype
        }
      })
        .then(res => {
          console.log(res.data)
          this.editUserIframe = false
          //重新渲染在岗人员列表
          this.$axios({
            method: 'GET',
            url: '/lps/sys/user/tojob?jobid=' + this.useInformation.jobid
          })
            .then(res => {
              // console.log(res.data)
              this.users = res.data
              // for (let i in this.users) {
              //   this.users[i].img = "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              // }
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    //对勾按钮
    confirmUser() {
      this.editUsers = false
    },
    //===============编辑人员结束======================
    //删除岗位
    SAFdeletes(data) {
      // console.log(data)
      if (data.children && data.children.length > 0) {
        this.$message.info('当前部门存在子部门，请先删除子部门')
      } else {
        this.$confirm('确定要删除该数据吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // console.log(data.id)
            this.$axios({
              method: 'DELETE',
              url: '/lps/sys/job/' + data.id
            })
              .then(res => {
                console.log(res.data)
                console.log('删除成功')
                this.dataRequest()
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(() => {})
      }
    },
    // ---------------------------------事务层级--------------------------------------//
    //      删除事务
    AFRdetele(data) {
      console.log(data)
      this.$confirm('确定要删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log(data.id)
          this.$axios({
            method: 'DELETE',
            url: '/lps/sys/job/towork/' + data.id
          })
            .then(res => {
              console.log(res.data)
              console.log('删除成功')
              this.dataRequest()
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(() => {})
    }
  }
}
</script>
<style scoped>
/* 盒子css */
.CPYfloating,
.DPTfloating,
.SAFfloating {
  border-radius: 15px;
}

.org-tree-node-label-inner {
  /* color: rgb(255, 255, 255); */
  background-color: rgb(230, 233, 234);
  width: 180px;
  margin: 5px;
}

#DPTiframe,
#SAFiframe,
#addOtherIframe,
#userIframe,
#addUserIframe {
  width: 1500px;
  margin-top: 3%;
  margin-left: 20%;
}

#DPTeditbtn,
#SAFeditbtn,
#selPeoDetermine {
  margin-left: 320px;
  margin-top: 20px;
}

#userbtn {
  margin-left: 250px;
  margin-top: 20px;
}

#submitAddUserbtn {
  margin-left: 320px;
  margin-top: 20px;
}

.list {
  min-width: 100px;
  height: 40px;
  border: 1px solid rgb(144, 144, 144);
  line-height: 30px;
  display: inline-block;
  margin: 10px;
  text-align: center;
  font-size: 15px;
  padding-top: 5px;
  border-radius: 10px;
  /* padding: 5px; */
  /* padding-left: 5px; */
}

.list.active {
  background: rgb(67, 37, 37);
  color: #fff;
}

.otherPeoList {
  min-width: 85px;
  height: 80px;
  border: 1px solid rgba(221, 221, 221, 0.73);
  line-height: 30px;
  display: inline-block;
  margin: 5px;
  text-align: center;
  font-size: 15px;
  padding-top: 5px;
  border-radius: 10px;
}

.otherPeoList.active {
  background: rgb(67, 37, 37);
  color: #fff;
}

#userList {
  width: 90px;
  height: 70px;
  display: inline-block;
  margin: 10px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.elpopover {
  width: 10px !important;
}
</style>
