<!--
 * @Author: 白一桐
 * @Date: 2023-02-24 10:54:44
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-12-01 11:01:44
-->
<script>
import { Table } from 'element-ui'
export default {
  extends: Table,
  mounted() {
    this.$nextTick(() => {
      let thead = this.$el.querySelector('.el-table__header-wrapper thead')
      let theadNew = thead.cloneNode(true)
      this.$el.querySelector('.el-table__body-wrapper table').appendChild(theadNew)
    })
  }
}
</script>
<style   scoped>
.el-table >>> .el-table__header-wrapper table thead {
  display: none;
}

@media print {
  .el-table >>> .el-table__header-wrapper {
    display: none;
  }

  .el-table >>> .el-table__header-wrapper table thead {
    display: table-header-group;
  }
}
</style>