<!--
 * @Author: 白一桐
 * @Date: 2022-12-12 08:40:16
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-09-05 14:54:01
-->
<template>
  <div>
    <div id="homebody">
      <div id="marginBorder" :reportingData="reportingData">
        <div id="lineBox">
          <div class="lineLeft">委托单位名称</div>
          <div class="lineRight">
            <p>{{ reportingData.client }}</p>
          </div>
          <div class="comments" ref="client" contenteditable="true">
            {{ this.examinecontents.client }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">委托单位地址</div>
          <div class="lineRight">
            <!-- <slot name="clientAddress"></slot> -->
            <p>{{ reportingData.clientAddress }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="clientAddress">
            {{ this.examinecontents.clientAddress }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">受检单位名称</div>
          <div class="lineRight">
            <p>{{ reportingData.insunit }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="insunit">
            {{ this.examinecontents.insunit }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">受检单位地址</div>
          <div class="lineRight">
            <p>{{ reportingData.insunitAddress }}</p>

          </div>
          <div class="comments" contenteditable="true" ref="insunitAddress">
            {{ this.examinecontents.insunitAddress }}
          </div>
        </div>
        <div id="lineBox" v-if="supervisor">
          <div class="lineLeft">监理单位名称</div>
          <div class="lineRight" ref="supervisor">
            <p>{{ reportingData.supervisor }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="supervisor">
            {{ this.examinecontents.supervisor }}</div>
        </div>
        <div id="lineBox" v-if="supervisorAddress">
          <div class="lineLeft">监理单位地址</div>
          <div class="lineRight" ref="supervisorAddress">
            <p>{{ reportingData.supervisorAddress }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="supervisorAddress">
            {{ this.examinecontents.supervisorAddress }}
          </div>
        </div>
        <div id="lineBox" v-if="build">
          <div class="lineLeft">建设单位名称</div>
          <div class="lineRight" ref="build">
            <p v-if="reportingData.build">{{ reportingData.build }}</p>
            <p v-else> {{ "" }} </p>
          </div>
          <div class="comments" contenteditable="true" ref="build">
            {{ this.examinecontents.build }}
          </div>
        </div>
        <div id="lineBox" v-if="buildAddress">
          <div class="lineLeft">建设单位地址</div>
          <div class="lineRight" ref="buildAddress">
            <p v-if="reportingData.buildAddress">{{ reportingData.buildAddress }}</p>
            <p v-else> {{ "" }} </p>
          </div>
          <div class="comments" contenteditable="true" ref="buildAddress">
            {{ this.examinecontents.buildAddress }}</div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">场所名称</div>
          <div class="lineRight">
            <p>{{ reportingData.sites }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="sites">
            {{ this.examinecontents.sites }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">所在地址</div>
          <div class="lineRight">
            <p>{{ reportingData.sitesAddreess }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="sitesAddreess">
            {{ this.examinecontents.sitesAddreess }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft" v-if="reportingData.reportTemplate === '001'">检测类别</div>
          <div class="lineLeft" v-else>检测分类</div>
          <div class="lineRight">
            <p>{{ reportingData.testType }}</p>
          </div>
          <div class="comments" contenteditable="true" ref="testType">
            {{ this.examinecontents.testType }}</div>
        </div>
        <div id="lineBox">
          <div id="peoDiv">
            <div class="lineLeft"> 联系人</div>
            <div class="lineRight2">
              <p>{{ reportingData.contact }}</p>
            </div>
            <div class="lineRight3">联系电话 </div>
            <div class="lineRight2">
              <slot name="contactPhone"></slot>
              <p>{{ reportingData.contactPhone }}</p>
            </div>
            <div class="lineRight3">其他联系方式</div>
            <div class="lineRight2">
              <p>/</p>
            </div>
          </div>
          <div class="comments" contenteditable="true" ref="peo">
            {{ this.examinecontents.peo }}
          </div>
        </div>
        <div id="lineBox">
          <div id="peoDiv">
            <div class="lineLeft">检测日期</div>
            <!-- <div class="lineRight4">
              <p>{{ reportingData.workStart }}</p>
            </div> -->
            <div class="lineRight4" v-if="reportingData.workStart != this.reportingData.workEnd">
              {{ reportingData.workStart }}{{ " ～ " + this.reportingData.workEnd }}
            </div>
            <div class="lineRight4" v-else>
              {{ reportingData.workStart }}
            </div>
            <div class="lineRight3">下次检测 </div>
            <div class="lineRight4">
              <p>{{ reportingData.nextWork }}</p>
            </div>
          </div>
          <div class="comments" contenteditable="true" ref="dates">
            {{ this.examinecontents.dates }}
          </div>
        </div>
        <div id="lineBox">
          <div id="peoDiv">
            <div class="lineLeft">环境状况</div>
            <div class="lineRight">
              <div v-if="reportingData.temperature == 0 && reportingData.humidity == 0">
                <div style="width:50px;min-height:30px;float: left;">温度：</div>
                <div class="environmentRight">
                  <p>/</p>
                </div>
                <div class="environmentLeft">℃；相对湿度：</div>
                <div class="environmentRight">
                  <p>/</p>
                </div>
              </div>
              <div v-else>
                <div style="width:50px;min-height:30px;float: left;">温度：</div>
                <div class="environmentRight">
                  <p>{{ reportingData.temperature }}</p>
                </div>
                <div class="environmentLeft">℃；相对湿度：</div>
                <div class="environmentRight">
                  <p>{{ reportingData.humidity }}</p>
                </div>
              </div>
              <div class="environmentLeft">%RH; 天气状况:</div>
              <div class="environmentRight">
                <p>{{ reportingData.weather }}</p>
              </div>
              <div style="width:90px;min-height:30px;float: left;"> ; 土壤情况：</div>
              <div class="environmentRight">
                <p>{{ reportingData.soil }}</p>
              </div>
            </div>
          </div>
          <div class="comments" contenteditable="true" ref="environment">
            {{ this.examinecontents.environment }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">检测技术依据</div>
          <div class="lineRight5">
            <slot name="standard"></slot>
          </div>
          <div style=" width: 203px;height: 84px;float: right;padding: 5px; overflow :auto;font-size: 11px;"
               contenteditable="true" ref="standard">
            {{ this.examinecontents.standard }}
          </div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">检测仪器</div>
          <div class="lineRight">
            <div id="tables">
              <!-- table 表格 -->
              <el-table size="mini" :cell-style="{ textAlign: 'center' }"
                        :header-cell-style="{ background: '#f2f2f2', color: '#666666', textAlign: 'center' }"
                        :border="true"
                        :data="reportingData.equipmentArr" style="width: 100%;min-height: 100px;">
                <el-table-column label="仪器名称" prop="eqName"></el-table-column>
                <el-table-column label="仪器型号" prop="eqModel"></el-table-column>
                <el-table-column label="仪器编号" prop="eqNo"></el-table-column>
              </el-table>
              <!-- table end -->
            </div>
          </div>
          <div style=" width: 203px;height: 100px;float: right;padding: 5px; overflow :auto;font-size: 11px;"
               contenteditable="true" ref="equipment">{{ this.examinecontents.equipment }}</div>
        </div>
        <div id="lineBox">
          <div class="lineLeft">检测结论</div>
          <div class="lineRight6">
            <div class="conclusion">
              <slot name="verdict"></slot>
            </div>
            <div class="seal">河北德创检测服务有限公司（盖章）</div>
            <div class="date">
              <p>年 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;月&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 日</p>
            </div>
          </div>
          <div style=" width: 203px;height: 160px;float: right;padding: 5px; overflow :auto;font-size: 11px;"
               contenteditable="true" ref="verdict">
            {{ this.examinecontents.verdict }}
          </div>
        </div>
        <div id="lineBox1">
          <div class="lineLeft">备注</div>
          <div class="lineRight" v-if="reportingData.otherInfo">
            <p>{{ reportingData.otherInfo }}</p>
          </div>
          <div class="lineRight" v-else>
            <p>/</p>
          </div>
          <div class="comments" contenteditable="true" ref="otherInfo">
            {{ this.examinecontents.otherInfo }}</div>
        </div>
      </div>
      <div id="person">
        <div class="lineLeft">检测人：</div>
        <div class="personRight">
          <slot name=""></slot>
        </div>
        <div class="lineLeft">审核人：</div>
        <div class="personRight">
          <slot name=""></slot>
        </div>
        <div class="lineLeft">批准人：</div>
        <div class="personRight">
          <slot name=""></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['reportingData', "total", "examinecontents"],
  data() {
    return {
      supervisor: true,
      supervisorAddress: true,
      build: true,
      buildAddress: true,
      commentsData: {},  //批注信息
      examinemsg: [], //审核记录表
      examinobj: {},
    }
  },
  mounted() {
    this.supervisortable()
    this.supervisorAddressTable()
    this.buildTable()
    this.buildAddressTable()
  },
  methods: {
    // 根据数据来判断是否显示此行
    supervisortable() {
      var sContent = this.$refs.supervisor.innerText
      if (sContent == '') {
        this.supervisor = false
      } else {
        this.supervisor = true
      }
    },
    supervisorAddressTable() {
      var sContent2 = this.$refs.supervisorAddress.innerText
      if (sContent2 == '') {
        this.supervisorAddress = false
      } else {
        this.supervisorAddress = true
      }
    },
    buildTable() {
      var sContent3 = this.$refs.build.innerText
      if (sContent3 == '') {
        this.build = false
      } else {
        this.build = true
      }
    },
    buildAddressTable() {
      var sContent3 = this.$refs.buildAddress.innerText
      if (sContent3 == '') {
        this.buildAddress = false
      } else {
        this.buildAddress = true
      }
    },
    //要提交的批注的数据
    ass() {
      if (this.$refs.client.innerText != '') {
        this.commentsData.client = this.$refs.client.innerText
      }
      if (this.$refs.clientAddress.innerText != '') {
        this.commentsData.clientAddress = this.$refs.clientAddress.innerText
      }
      if (this.$refs.insunit.innerText != '') {
        this.commentsData.insunit = this.$refs.insunit.innerText
      }
      if (this.$refs.insunitAddress.innerText != '') {
        this.commentsData.insunitAddress = this.$refs.insunitAddress.innerText
      }
      if (this.$refs.supervisor) {
        this.commentsData.supervisor = this.$refs.supervisor.innerText
      }
      if (this.$refs.supervisorAddress) {
        this.commentsData.supervisorAddress = this.$refs.supervisorAddress.innerText
      }
      if (this.$refs.build) {
        this.commentsData.build = this.$refs.build.innerText
      }
      if (this.$refs.buildAddress) {
        this.commentsData.buildAddress = this.$refs.buildAddress.innerText
      }
      if (this.$refs.sites.innerText != '') {
        this.commentsData.sites = this.$refs.sites.innerText
      }
      if (this.$refs.sitesAddreess.innerText != '') {
        this.commentsData.sitesAddreess = this.$refs.sitesAddreess.innerText
      }
      if (this.$refs.testType.innerText != '') {
        this.commentsData.testType = this.$refs.testType.innerText
      }
      if (this.$refs.peo.innerText != '') {
        this.commentsData.peo = this.$refs.peo.innerText
      }
      if (this.$refs.dates.innerText != '') {
        this.commentsData.dates = this.$refs.dates.innerText
      }
      if (this.$refs.environment.innerText != '') {
        this.commentsData.environment = this.$refs.environment.innerText
      }
      if (this.$refs.standard.innerText != '') {
        this.commentsData.standard = this.$refs.standard.innerText
      }
      if (this.$refs.equipment.innerText != '') {
        this.commentsData.equipment = this.$refs.equipment.innerText
      }
      if (this.$refs.verdict.innerText != '') {
        this.commentsData.verdict = this.$refs.verdict.innerText
      }
      if (this.$refs.otherInfo.innerText != '') {
        this.commentsData.otherInfo = this.$refs.otherInfo.innerText
      }

      let arr = []
      console.log('返回的', this.examinecontents)
      console.log('要提交的', this.commentsData)
      if (this.examinecontents.client != this.commentsData.client) {
        this.examinobj['client'] = '委托单位名称:  ' + this.$refs.client.innerText
        let a = {}
        a.msg = this.examinobj['client']
        arr.push(a)
      } else {
        delete this.examinobj['client']
      }
      if (this.examinecontents.clientAddress != this.commentsData.clientAddress) {
        this.examinobj['clientAddress'] = '委托单位地址:  ' + this.$refs.clientAddress.innerText
        let a = {}
        a.msg = this.examinobj['clientAddress']
        arr.push(a)
      } else {
        delete this.examinobj['clientAddress']
      }

      if (this.examinecontents.insunit != this.commentsData.insunit) {
        this.examinobj['insunit'] = '受检单位名称:  ' + this.$refs.insunit.innerText
        let a = {}
        a.msg = this.examinobj['insunit']
        arr.push(a)
      } else {
        delete this.examinobj['insunit']
      }
      if (this.examinecontents.insunitAddress != this.commentsData.insunitAddress) {
        this.examinobj['insunitAddress'] = '受检单位地址:  ' + this.$refs.insunitAddress.innerText
        let a = {}
        a.msg = this.examinobj['insunitAddress']
        arr.push(a)
      } else {
        delete this.examinobj['insunitAddress']
      }
      if (this.examinecontents.sites != this.commentsData.sites) {
        this.examinobj['sites'] = '场所名称:  ' + this.$refs.sites.innerText
        let a = {}
        a.msg = this.examinobj['sites']
        arr.push(a)
      } else {
        delete this.examinobj['sites']
      }
      if (this.examinecontents.sitesAddreess != this.commentsData.sitesAddreess) {
        this.examinobj['sitesAddreess'] = '所在地址:  ' + this.$refs.sitesAddreess.innerText
        let a = {}
        a.msg = this.examinobj['sitesAddreess']
        arr.push(a)
      } else {
        delete this.examinobj['sitesAddreess']
      }

      if (this.examinecontents.testType != this.commentsData.testType) {
        this.examinobj['testType'] = '检测类别:  ' + this.$refs.testType.innerText
        let a = {}
        a.msg = this.examinobj['testType']
        arr.push(a)
      } else {
        delete this.examinobj['testType']
      }

      if (this.examinecontents.peo != this.commentsData.peo) {
        this.examinobj['peo'] = '联系人与联系方式:  ' + this.$refs.peo.innerText
        let a = {}
        a.msg = this.examinobj['peo']
        arr.push(a)
      } else {
        delete this.examinobj['peo']
      }
      if (this.examinecontents.dates != this.commentsData.dates) {
        this.examinobj['dates'] = '检测日期与下次检测:  ' + this.$refs.dates.innerText
        let a = {}
        a.msg = this.examinobj['dates']
        arr.push(a)
      } else {
        delete this.examinobj['dates']
      }

      if (this.examinecontents.environment != this.commentsData.environment) {
        this.examinobj['environment'] = '环境状况:  ' + this.$refs.environment.innerText
        let a = {}
        a.msg = this.examinobj['environment']
        arr.push(a)
      } else {
        delete this.examinobj['environment']
      }

      if (this.examinecontents.standard != this.commentsData.standard) {
        this.examinobj['standard'] = '检测技术依据:  ' + this.$refs.standard.innerText
        let a = {}
        a.msg = this.examinobj['standard']
        arr.push(a)
      } else {
        delete this.examinobj['standard']
      }

      if (this.examinecontents.equipment != this.commentsData.equipment) {
        this.examinobj['equipment'] = '检测仪器:  ' + this.$refs.equipment.innerText
        let a = {}
        a.msg = this.examinobj['equipment']
        arr.push(a)
      } else {
        delete this.examinobj['equipment']
      }

      if (this.examinecontents.verdict != this.commentsData.verdict) {
        this.examinobj['verdict'] = '检测结论:  ' + this.$refs.verdict.innerText
        let a = {}
        a.msg = this.examinobj['verdict']
        arr.push(a)
      } else {
        delete this.examinobj['verdict']
      }

      if (this.examinecontents.otherInfo != this.commentsData.otherInfo) {
        this.examinobj['otherInfo'] = '备注:  ' + this.$refs.otherInfo.innerText
        let a = {}
        a.msg = this.examinobj['otherInfo']
        arr.push(a)
      } else {
        delete this.examinobj['otherInfo']
      }
      console.log(arr)
      this.examinemsg = arr
      // console.log(this.examinobj)
      console.log(this.examinemsg)
    },
  },

}
</script>

<style scoped>
#homebody {
  margin-left: 25%;
  width: 1040px;
  min-height: 954px;
  border: 1px solid gainsboro;
  box-shadow: 1px 2px 13px grey;
  margin-top: 30px;
  margin-bottom: 20px;
}

#marginBorder {
  min-width: 930px;
  min-height: 832px;
  /* margin: 60px; */
  margin-top: 60px;
  margin-left: 60px;
  margin-right: 60px;
  border: 2px solid rgb(32, 32, 32);
}

#lineBox {
  margin: 0;
  padding: 0;
  min-height: 30px;
  width: 100%;
  border-bottom: 1px solid rgb(76, 76, 76);
  display: inline-block;
}

.lineLeft {
  margin: 0;
  padding: 0;
  width: 140px;
  min-height: 30px;
  float: left;
  padding: 5px;
  text-align: center;
}

.lineRight {
  width: 555px;
  min-height: 30px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  padding: 5px;
  float: left;
}

#peoDiv {
  width: 717px;
  display: inline-block;
}

/* --------------联系人部分-------------------- */
.lineRight2 {
  float: left;
  text-align: center;
  width: 120px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  line-height: 30px;

}

.lineRight3 {
  width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  text-align: center;
  line-height: 30px;
}

/* --------------检测日期部分-------------------- */
.lineRight4 {
  float: left;
  text-align: center;
  width: 231px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  line-height: 30px;

}

/* ------------------环境状况------------------------- */
.environmentLeft {
  width: 120px;
  min-height: 30px;
  float: left;
  /* text-align: center; */
}

.environmentRight {
  min-width: 30px;
  min-height: 30px;
  float: left;
}

/* --------------------检测技术依据-------------------------- */
.lineRight5 {
  width: 555px;
  min-height: 80px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  padding: 5px;
  display: inline-block;
}

/* -------------------------检测结论---------------------------- */
.lineRight6 {
  width: 555px;
  min-height: 150px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  padding: 5px;
  display: inline-block;
}

.conclusion {
  min-height: 90px;
  padding-left: 12px;
  text-indent: 2em;
  line-height: 2;
  /* background-color: rgb(245, 175, 85) */
}

.lineRight6 .seal {
  height: 30px;
  padding-top: 20px;
  padding-left: 220px;
}

.lineRight6 .date {
  padding-left: 300px;

}

/* --------------------------备注-------------------------- */
#lineBox1 {
  margin: 0;
  padding: 0;
  min-height: 30px;
  width: 100%;
  display: inline-block;
}

/* -----------------------人员--------------------------- */
#person {
  min-width: 930px;
  min-height: 20px;
  /* margin: 60px; */
  margin-bottom: 60px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 10px;
  display: inline-block;
  /* font-family: 'song'; */
  /* border: 2px solid rgb(32, 32, 32); */
}

.personRight {
  float: left;
  width: 100px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* -------------------------输入框-------------------------------- */
.comments {
  width: 203px;
  height: 30px;
  float: right;
  padding: 5px;
  overflow: auto;
  font-size: 11px;
}
</style>
