/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:39:08 
 * @Last Modified by: 王翎爆
 * @Last Modified time: 2023-04-12 15:21:06
 */
<template>
  <el-container>
    <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect"
             background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" router>
      <el-menu-item index="/index" class="dh">首页</el-menu-item>
      <el-menu-item index="/coordination" class="dh">协作</el-menu-item>
      <el-submenu index="1" class="dh" v-if="isSubmenu">
        <template slot="title">管理中心</template>
        <el-menu-item index="/attachmentManagement" class="gl" v-if="isreportManager"><i class="el-icon-s-fold"
             style="color: aliceblue"></i>附件管理
        </el-menu-item>
        <el-menu-item index="/equipment" class="gl"><i class="el-icon-set-up" style="color: aliceblue"></i>仪器设备管理
        </el-menu-item>
        <!-- <el-menu-item index="/process" class="gl"><i class="el-icon-share" style="color: aliceblue"></i>流程管理
        </el-menu-item> -->
        <el-menu-item index="/organizationalStructure" class="gl" v-if="isStructure"><i class="el-icon-share"
             style="color: aliceblue"></i>组织架构管理
        </el-menu-item>
        <el-menu-item index="/reportManagement" class="gl" v-if="isreportManager"><i class="el-icon-tickets"
             style="color: aliceblue"></i>检测报告管理
        </el-menu-item>

      </el-submenu>
      <dropdown></dropdown>
    </el-menu>
  </el-container>
</template>
<script>
import Dropdown from './Dropdown.vue';
export default {
  name: 'Menu',
  data() {
    return {
      isSubmenu: true,
      isStructure: true,
      isreportManager: true
    }
  },
  components: {
    Dropdown
  },
  mounted() {
    this.powerManage()
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    powerManage() {
      let work = this.$cookies.get('user').works
      if (work.length != 0) {
        var max = work[0].id
        for (let i = 0; i < work.length; i++) {
          if (max < work[i].id) {
            max = work[i].id
          }
        }
        if (max == 1) {
          this.isStructure = false
          this.isreportManager = false
        }
        if (max == 2 || max == 3) {
          this.isSubmenu = false
        }
        if (max == 4) {
          this.isSubmenu = true
          this.isStructure = false
        }
        if (max == 5) {
          this.isSubmenu = true
        }
      }
    }
  }
}
</script>
<style >
.el-container {
  width: 100%;
  /* min-width: 1920px; */
  height: 62px
}

.el-menu {
  width: 100%;
}

.dh {
  margin-left: 60px !important;
}

.el-menu-item {
  font-size: 18px !important;
  letter-spacing: 3px
}

.el-submenu__title {
  font-size: 18px !important;
  letter-spacing: 3px
}
</style>
