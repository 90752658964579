<!--
 * @Author: 白一桐
 * @Date: 2023-03-08 10:02:27
 * @LastEditors: 白一桐
 * @LastEditTime: 2023-07-14 09:32:50
-->
<template>
  <div id="parent">
    <div style="text-align: center;padding: 10px;">
      <h2>原始数据修改记录</h2>
    </div>
    <el-descriptions class="margin-top" :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          修改人
        </template>
        {{ editRecord.edituserrelname }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          修改日期
        </template>
        {{ editRecord.edittime }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-document"></i>
          报告编号
        </template>
        {{ editRecord.trptno }}
      </el-descriptions-item>
    </el-descriptions>
    <el-table :data="editRecordmsg" stripe style="width: 100%">
      <el-table-column prop="msg" label="修改信息:">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      taskid: '',
      editRecord: '',
      editRecordmsg: [],
    };
  },
  components: {
  },
  mounted() {
    this.getData()
    this.taskid = this.$route.query.taskid
  },
  methods: {
    getData() {
      // console.log(this.$route.query.taskid)
      this.$axios({
        method: 'GET',
        url: '/lps/test/testreport/content/his/' + this.$route.query.taskid,
      }).then(res => {
        console.log(res.data)
        this.editRecord = res.data
        // this.editRecordmsg = JSON.parse(res.data.edittext)
        let changeFormat = JSON.parse(res.data.edittext)
        console.log(changeFormat)
        for (var item in changeFormat) {
          console.log(item);
          const change = {}
          var items = Number(item) + 1
          change.msg = '\xa0' + '\xa0' + '\xa0' + '\xa0' + '\xa0' + '\xa0' + items + '.\xa0' + changeFormat[item].msg
          this.editRecordmsg.push(change)
        }
        console.log(this.editRecordmsg)
      }).catch(error => {
        console.log(error)
      })
    },
  },

};
</script>
<style  scoped>
#parent {
  width: 50%;
  /* min-height: 800px; */
  border: 1px solid gainsboro;
  box-shadow: 1px 1px 1px grey;
  margin: 30px auto;
}
</style>