/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:36:28 
 * @Last Modified by: 王翎爆
 * @Last Modified time: 2023-03-30 14:37:21
 */
<template>
    <div id="printImgbody">
        <div id="fimg">
            <div id="rimg">
                <el-image style="width: 80mm; height: 80mm" v-for="(src, index) in urlList" :key="index" :src="src"
                    :preview-src-list="urlList">
                </el-image>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    name: 'pintReportImg',
    props: ['photo'],
    data() {
        return {
            url: '',
            surl: '',
            objname: '',
            target: '',
            updata: {
                AccessKeyId: '',
                signature: '',
                policy: '',
                key: '',
            },
            urlList: []
        };
    },
    components: {
    },
    mounted() {
        this.initImagesSrc()
    },
    methods: {
        //传递图片是否加载完成
        // imgInit(){
        //     this.$bus.$emit('printimgstate','完成加载')
        // },
        initImagesSrc() {
            let imgsrcs = this.photo.split('|')
            for (let i in imgsrcs) {
                if (imgsrcs[i] != null && imgsrcs[i] != '') {
                    this.$axios({
                        method: 'get',
                        url: '/lps/hwcloud/obs/downurl',
                        params: {
                            objname: imgsrcs[i]
                        }
                    }).then(res => {
                        if (res.status == 200) {
                            this.urlList.push(res.data.url)
                        }
                    })
                }
            }
        }
    },
};
</script>
<style  scoped>
#fimg {
    width: 210mm;
    /* min-height: 106px;
    position: relative; */
    margin-left: 5mm;
}

#rimg {
    border: 1px solid rgb(32, 32, 32);
    width: 197mm;
    /* display: inline-block; */
    text-align: center;
    padding-left: 10px;
}

.el-image {
    margin-left: 15px;
    vertical-align: center;
}
</style>