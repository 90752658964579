/*
 * @Author: 王翎爆 
 * @Date: 2023-03-30 09:35:19 
 * @Last Modified by: 王翎爆
 * @Last Modified time: 2023-03-30 16:32:46
 */
<template>
  <div id="printSummarybody">
    <div id="fthree">
      <div id="rthree">
        <div style="margin: 5px 5px;">
          <h2>{{ SummaryName.siteName }}</h2>
        </div>
        <div id="rdetails">
          <div class="xscenter">
            <p>防雷类别：{{ SummaryName.protectionType }}</p>
          </div>
          <div class="xscenter">
            <p>判定依据：{{ SummaryName.according }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.buildingLength">
            <p>建筑物年预计雷击次数：</p>
            <el-table :data="lightningData" border style="width:189mm; height: 26mm; margin-bottom: 5px;">
              <el-table-column label="长度(m)" prop="" width="100mm"></el-table-column>
              <el-table-column label="宽度(m)" prop="" width="100mm"></el-table-column>
              <el-table-column label="高度(m)" prop="" width="110mm"></el-table-column>
              <el-table-column label="校正系数K" prop="" width="120mm"></el-table-column>
              <el-table-column label="年雷暴日(Td)" prop="" width="120mm"></el-table-column>
              <el-table-column label="年预计雷击次数(N)" prop="" width="160mm"></el-table-column>
            </el-table>
          </div>
          <div class="xscenter" v-if="SummaryName.isDangerSite">
            <p>是否爆炸火灾危险场所：{{ SummaryName.isDangerSite }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.isDangerSite == '是'">
            <p>场所内主要危险介质：{{ SummaryName.danger }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.earthingDeviceCategory">
            <p>接地装置类型：{{ SummaryName.earthingDeviceCategory}}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.electricalSystemGroundingType">
            <p>配电系统接地型式：{{ SummaryName.electricalSystemGroundingType}}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.dxt">
            <p>电源系统：{{ SummaryName.dxt }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.xl">
            <p>信号线路：{{ SummaryName.xl }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.xs">
            <p>接地形式：{{ SummaryName.xs }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.sx">
            <p>检测顺序：{{ SummaryName.sx }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.sm">
            <p>基准点说明：{{ SummaryName.sm }}</p>
          </div>
          <div class="xscenter" v-if="SummaryName.qsm">
            <p>其他说明：{{ SummaryName.qsm }}</p>
          </div>
          <div class="xscenter" v-if="downleadMeasure">
            <p>引下线防接触电压措施：{{ downleadMeasure }}</p>
          </div>
          <div class="xscenter" v-if="downConductor">
            <p>接地装置防跨步电压措施: {{ downConductor }}</p>
          </div>
          <div class="xscenter" v-if="downPressure">
            <p>防侧击雷装置: {{ downPressure }}</p>
          </div>
          <el-table size="mini" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ background: '#f2f2f2', color: '#666666', textAlign: 'center' }" :border="true" :data="equipmentArr" style="height: auto; width: 602px; margin-bottom: 5px;" v-if="equipmentIsShow" :row-style="{ height: '0' }">
            <el-table-column prop="eqName" label="仪器名称" width="200"></el-table-column>
            <el-table-column  label="仪器型号" width="200"></el-table-column>
            <el-table-column  label="仪器编号" width="200"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'printReportSummary',
  props: ['SummaryName'],
  data() {
    return {
      downleadMeasure: '',
      downConductor: '',
      downPressure: '',
      backesdorse: '',
      changeEqual: '',
      lightning: {},
      lightningData: [],
      equipmentArr: [],
      equipmentIsShow: false
    }
  },
  components: {},
  mounted() {
    this.downleadMeasureMeans()
    this.changeLightning()
    this.equipmentGroup()
  },
  methods: {
    //设备信息展示
    equipmentGroup() {
      if (this.SummaryName.siteEquipment) {
        // console.log(this.SummaryName.siteEquipment)
        let equip = this.SummaryName.siteEquipment.replaceAll('设备名称：', '')
        let equip1 = equip.replaceAll('型号：', '')
        let equip2 = equip1.replaceAll('设备编号：', '')
        let equip3 = equip2.replaceAll('出厂编号：', '')
        // console.log(equip3)
        let strequip = equip3.split('|')
        // console.log(strequip)
        let equipmentArr = []
        for (let i = 0; i < strequip.length; i++) {
          let data = {}
          // console.log(strequip[i].split("\n")[1])
          data.eqName = strequip[i].split('\n')[1]
          data.eqModel = strequip[i].split('\n')[2]
          data.eqNo = strequip[i].split('\n')[3]
          equipmentArr.push(data)
        }
        // console.log('设备', equipmentArr)
        this.equipmentArr = equipmentArr
        this.equipmentIsShow = true
      }
    },
    //未修约场所中雷击次数数据
    changeLightning() {
      if (this.SummaryName.buildingLength) {
        this.lightning.buildingLength = this.SummaryName.buildingLength
      }
      if (this.SummaryName.buildingWidth) {
        this.lightning.buildingWidth = this.SummaryName.buildingWidth
      }
      if (this.SummaryName.buildingHeight) {
        this.lightning.buildingHeight = this.SummaryName.buildingHeight
      }
      if (this.SummaryName.coefficient) {
        this.lightning.coefficient = this.SummaryName.coefficient
      }
      if (this.SummaryName.thunderstormDaysPerYear) {
        this.lightning.thunderstormDaysPerYear = this.SummaryName.thunderstormDaysPerYear
      }
      if (this.SummaryName.thunderstormStrokePerYear) {
        this.lightning.thunderstormStrokePerYear = this.SummaryName.thunderstormStrokePerYear
      }
      this.lightningData.push(this.lightning)
    },
    //引下线接地装置等项目数据展示
    downleadMeasureMeans() {
      for (let i = 0; i < this.SummaryName.areaList.length; i++) {
        for (let j = 0; j < this.SummaryName.areaList[i].sourceDataList.length; j++) {
          var downlead = this.SummaryName.areaList[i].sourceDataList[j]
          if (downlead.testProject == '引下线防接触电压措施') {
            if (downlead.numberOfDownConductor) {
              this.downleadMeasure += `自然引下线数量：${downlead.numberOfDownConductor}。`
            }
            if (downlead.surfaceResistivity) {
              this.downleadMeasure += `引下线3m内地表层电阻率：${downlead.surfaceResistivity}。`
            }
            if (downlead.groundMaterial) {
              this.downleadMeasure += `引下线3m内地表材质：${downlead.groundMaterial}。`
            }
            if (downlead.protectiveMeasures) {
              this.downleadMeasure += `外露引下线保护措施：${downlead.protectiveMeasures}。`
            }
            if (downlead.withOrWithoutWarning) {
              this.downleadMeasure += `有无护栏、警示牌：${downlead.withOrWithoutWarning}。`
            }
          }
          if (downlead.testProject == '接地装置防跨步电压措施') {
            if (downlead.numberOfDownConductor) {
              this.downConductor += `自然引下线数量：${downlead.numberOfDownConductor}。`
            }
            if (downlead.surfaceResistivity) {
              this.downConductor += `引下线3m内地表层电阻率：${downlead.surfaceResistivity}。`
            }
            if (downlead.groundMaterial) {
              this.downConductor += `引下线3m内地表材质：${downlead.groundMaterial}。`
            }
            if (downlead.pressureEqualizingMeasures) {
              this.downConductor += `地面均衡电位措施：${downlead.pressureEqualizingMeasures}。`
            }
            if (downlead.withOrWithoutWarning) {
              this.downConductor += `有无护栏、警示牌：${downlead.withOrWithoutWarning}。`
            }
          }
          if (downlead.testProject == '防侧击雷装置') {
            if (downlead.pressureEqualizingRingSpacing) {
              this.downPressure += `30m起均压环（水平接闪带）间距：${downlead.pressureEqualizingRingSpacing}m。`
            }
            if (downlead.externalWallMetal) {
              this.downPressure += `30m起外墙金属物与防雷装置连接：${downlead.externalWallMetal}。`
            }
            if (downlead.exteriorWallProtrusions) {
              this.downPressure += `45m起突出外墙物体保护措施：${downlead.exteriorWallProtrusions}。`
            }
            if (downlead.verticalMetal) {
              this.downPressure += `外墙内、外竖直敷设金属物顶端和底端与防雷装置等电位链接：${downlead.verticalMetal}。`
            }
            if (downlead.sideDefence60m) {
              this.downPressure += `高于60m的建筑物：其部占比20%并超过60m的部位：各表面上的尖物、墙角、边缘、设备及显著突出的物体：${downlead.sideDefence60m}。`
            }
            if (downlead.sideLayout60m) {
              this.downPressure += `高于60m的建筑物：其上部占比20%并超过60m的部位接闪器布置位置：${downlead.sideLayout60m}。`
            }
            if (downlead.sideMetal60m) {
              this.downPressure += `高于60m的建筑物：其上部占比20%并超过60m的部位外部金属物作为接闪器：${downlead.sideMetal60m}。`
            }
            if (downlead.sideSkeleton60m) {
              this.downPressure += `高于60m的建筑物：其上部占比20%并超过60m的部位混凝土内钢筋和建筑物金属框架作为接闪器：${downlead.sideSkeleton60m}。`
            }
            if (downlead.sideSkeleton60m) {
              this.downPressure += `高于60m的建筑物：其上部占比20%并超过60m的部位混凝土内钢筋和建筑物金属框架作为接闪器：${downlead.sideSkeleton60m}。`
            }
          }
        }
      }
    }
  }
}
</script>
<style  scoped>
#fthree {
  margin-top: 50px;
  height: auto;
  width: 210mm;
  margin: 0 5mm;
  margin-bottom: -1mm;
  font-family: 'song';
  /* page-break-after: always; */
}

#rthree {
  border: 1px solid rgb(32, 32, 32);
  width: 199.5mm;
  height: auto;
  display: inline-block;
}

#rdetails {
  padding-left: 30px;
}
.xscenter p {
  margin-bottom: 5px;
}
</style>