<!--
 * @Author: 白一桐
 * @Date: 2023-01-13 11:28:08
 * @LastEditors: 白一桐
 * @LastEditTime: 2024-05-31 16:01:05
-->
<template>
  <div id="printpage">
    <div id="homebody1" :reportingData="reportingData" style="page-break-after: always;">
      <div id="marginBorder1">
        <div id="homebox">
          <div class="homeleft">委托单位名称</div>
          <div class="homeright">
            <p>{{ reportingData.client }} </p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">委托单位地址</div>
          <div class="homeright" style="line-height:1.1;height: 35px;">
            <p>{{ reportingData.clientAddress }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">受检单位名称</div>
          <div class="homeright">
            <p>{{ reportingData.insunit }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">受检单位地址</div>
          <div class="homeright" style="line-height: 1.1;height: 35px;">
            <p>{{ reportingData.insunitAddress }}</p>
          </div>
        </div>
        <!-- <div id="homebox" v-if="supervisor">
            <div class="homeleft">监理单位名称</div>
            <div class="homeright" ref="supervisor">
            <p>{{ reportingData.supervisor }}</p>
            </div>
          </div>
          <div id="homebox" v-if="supervisorAddress">
            <div class="homeleft">监理单位地址</div>
            <div class="homeright" ref="supervisorAddress">
            <p>{{ reportingData.supervisorAddress }}</p>
            </div>
          </div>
          <div id="homebox" v-if="build">
            <div class="homeleft">建设单位名称</div>
            <div class="homeright" ref="build">
              <p v-if="reportingData.build">{{ reportingData.build }}</p>
            <p v-else> {{ "" }} </p>
            </div>
          </div>
          <div id="homebox" v-if="buildAddress">                                                                                                                                                                          </div> -->
        <div id="homebox">
          <div class="homeleft">场所名称</div>
          <div class="homeright">
            <p>{{ reportingData.sites }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">所在地址</div>
          <div class="homeright" style="line-height: 1.1;height: 35px;">
            <p>{{ reportingData.sitesAddreess }}</p>
          </div>
        </div>
        <div id="homebox">
          <div class="homeleft">检测类别</div>
          <div class="homeright">
            <p>{{ reportingData.testType }}</p>
          </div>
        </div>
        <div id="homebox">
          <div id="peoDiv">
            <div class="homeleft"> 联系人</div>
            <div class="lineRight2">
              <p>{{ reportingData.contact }}</p>
            </div>
            <div class="lineRight3">联系电话 </div>
            <div class="lineRight2">
              <p>{{ reportingData.contactPhone }}</p>
            </div>
            <div class="lineRight3">其他联系方式</div>
            <div class="Rline">
              <p>/</p>
            </div>
          </div>
        </div>
        <div id="homebox">
          <div id="peoDiv">
            <div class="homeleft">检测日期</div>
            <!-- <div class="lineRight4">
              <p>{{ reportingData.workStart }}</p>
            </div> -->
            <div class="lineRight4" v-if="reportingData.workStart != this.reportingData.workEnd">
              {{ reportingData.workStart }}{{ " ～ " + this.reportingData.workEnd }}
            </div>
            <div class="lineRight4" v-else>
              {{ reportingData.workStart }}
            </div>
            <div class="lineRight3">下次检测 </div>
            <div class="Rline">
              <p>{{ reportingData.nextWork }}</p>
            </div>
          </div>
        </div>
        <div id="homebox">
          <div id="peoDiv">
            <div class="homeleft">环境状况</div>
            <div class="homeright">
              <div v-if="reportingData.temperature == 0 && reportingData.humidity == 0">
                <div style="width:50px;min-height:30px;float: left;">温度：</div>
                <div class="environmentRight">
                  <p>/</p>
                </div>
                <div class="environmentLeft">℃；相对湿度：</div>
                <div class="environmentRight">
                  <p>/</p>
                </div>
              </div>
              <div v-else>
                <div style="width:50px;min-height:30px;float: left;">温度：</div>
                <div class="environmentRight">
                  <p>{{ reportingData.temperature }}</p>
                </div>
                <div class="environmentLeft">℃；相对湿度：</div>
                <div class="environmentRight">
                  <p>{{ reportingData.humidity }}</p>
                </div>
              </div>
              <div style="width:130px;height:30px;float: left;">%RH; 天气状况:</div>
              <div class="environmentRight">
                <p>{{ reportingData.weather }}</p>
              </div>
              <div style="width:90px;height:30px;float: left;">;土壤情况:</div>
              <div class="environmentRight">
                <p>{{ reportingData.soil }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.reportingData.isShow">
          <div id="homebox">
            <div style=" margin: 0;padding: 0;width: 130px; float: left;padding: 5px;text-align: center;">
              检测技术依据</div>
            <div
                 style=" width: 555px;height: 225px;padding: 5px;float: left;border-left: 1px solid rgb(76, 76, 76); line-height: 1.1; ">
              <slot name="standard"></slot>
            </div>
          </div>
          <div id="homebox">
            <div class="homeleft">检测仪器</div>
            <div class="homeright">
              <div style="height: 146px;">
                <p>详情见数据页</p>
              </div>
            </div>
          </div>
          <div id="homebox">
            <div class="homeleft">检测结论</div>
            <div class="homeright">
              <div class="conclusion">
                <slot name="verdict"></slot>
                <!-- <p>{{ reportingData.printVerdicts }}</p> -->
              </div>
              <div class="seal">河北德创检测服务有限公司（盖章）</div>
              <div class="date">
                <p>年 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;月&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 日</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div id="homebox">
            <div style=" margin: 0;padding: 0;width: 130px; float: left;padding: 5px;text-align: center;">
              检测技术依据</div>
            <div style=" width: 555px;height: 134px;padding: 5px;float: left;border-left: 1px solid rgb(76, 76, 76); ">
              <slot name="standard"></slot>
            </div>
          </div>
          <div id="homebox">
            <div class="homeleft">检测仪器</div>
            <div class="homeright">
              <div id="tables">
                <!-- table 表格 -->
                <el-table size="mini" :cell-style="{ textAlign: 'center', padding: '2px' }"
                          :header-cell-style="{ background: '#f2f2f2', color: '#666666', textAlign: 'center' }"
                          :row-style="{ height: '10px' }" :data="reportingData.equipmentArr" ref="multipleTable"
                          style="width: 550px;height: 267px;color: black;font-size:15px;">
                  <el-table-column label="仪器名称" prop="eqName" width="200"></el-table-column>
                  <el-table-column label="仪器型号" prop="eqModel" width="165"></el-table-column>
                  <el-table-column label="仪器编号" prop="eqNo" width="165"></el-table-column>
                </el-table>
                <!-- table end -->
              </div>
            </div>
          </div>
          <div id="homebox">
            <div class="homeleft">检测结论</div>
            <div class="homeright">
              <div style="height: 105px;text-indent: 2em;line-height:1.1;">
                <slot name="verdict"></slot>
              </div>
              <div class="seal">河北德创检测服务有限公司（盖章）</div>
              <div class="date">
                <p>年 &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;月&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 日</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style=" margin: 0;width: 130px;float: left;padding: 5px;text-align: center;">
            备注
          </div>
          <div v-if="reportingData.otherInfo"
               style=" width: 585px;border-left: 1px solid rgb(76, 76, 76);padding: 5px;float: left;height: 40px;line-height: 1;">
            <p>{{ reportingData.otherInfo }}</p>
          </div>
          <div v-else
               style=" width: 555px;border-left: 1px solid rgb(76, 76, 76);padding: 5px;float: left;height: 40px;line-height: 1;">
            <p>/</p>
          </div>
        </div>
      </div>
      <div style="padding-left: 50px;margin-bottom: 50px;">
        <div class="homeleft">检测人：</div>
        <div class="personRight">
          <slot name=""></slot>
        </div>
        <div class="homeleft">审核人：</div>
        <div class="personRight">
          <slot name=""></slot>
        </div>
        <div class="homeleft">批准人：</div>
        <div class="personRight">
          <slot name=""></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['reportingData', "total", "examinecontents"],
  data() {
    return {
      supervisor: true,
      supervisorAddress: true,
      build: true,
      buildAddress: true,
      commentsData: {},
      homepageData: '',
      // isShow: false,
    }
  },
  mounted() {
  },
  components: {
  },
  methods: {
    getpage() {
      this.homepageData = document.getElementById("printpage").innerHTML
    },
  },

}
</script>

<style scoped>
#homebody1 {
  width: 210mm;
  min-height: 297mm;
  /* padding-top: 50px; */
  margin: 0 auto;
}

#marginBorder1 {
  width: 200mm;
  min-height: 295mm;
  margin: 0 auto;
  border: 1.7px solid rgb(32, 32, 32);
  font-family: 'song';
  /* font-size: 20px; */
}


#homebox {
  margin: 0;
  padding: 0;
  min-height: 30px;
  width: 100%;
  border-bottom: 1px solid rgb(76, 76, 76);
  display: inline-block;
}

.homeleft {
  margin: 0;
  padding: 0;
  width: 130px;
  min-height: 30px;
  float: left;
  padding: 5px;
  text-align: center;
  /* line-height: 30px; */

}

.homeright {
  width: 555px;
  min-height: 30px;
  border-left: 1px solid rgb(76, 76, 76);
  padding: 5px;
  float: left;
  /* line-height: 30px; */
}

#peoDiv {
  width: 717px;
  display: inline-block;
}

/* --------------联系人部分-------------------- */
.lineRight2 {
  float: left;
  text-align: center;
  width: 120px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  line-height: 30px;

}

/* ------------------环境状况------------------------- */
.environmentLeft {
  width: 120px;
  min-height: 30px;
  float: left;
  /* text-align: center; */
}

.environmentRight {
  min-width: 30px;
  min-height: 30px;
  float: left;
}

.lineRight3 {
  width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
}

.lineRight4 {
  float: left;
  text-align: center;
  width: 231px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid rgb(76, 76, 76);
  border-right: 1px solid rgb(76, 76, 76);
  line-height: 30px;

}

.conclusion {
  height: 135px;
  text-indent: 2em;
}

.homeright .seal {
  height: 20px;
  padding-left: 240px;
  padding-bottom: 10px;
}

.homeright .date {
  padding-left: 300px;
  height: 15px;
  padding-bottom: 13px;
}

.Rline {
  float: left;
  text-align: center;
  width: 132px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid rgb(76, 76, 76);
  line-height: 30px;
}

.personRight {
  float: left;
  width: 100px;
  min-height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
